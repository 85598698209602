/* eslint-disable no-nested-ternary */
// @flow
import React, { useEffect } from 'react';
import ReactTable from 'react-table';
import styled from 'styled-components';
import * as moment from 'moment';
import isEmpty from 'lodash.isempty';
import { useTranslation } from 'react-i18next';
import { Card, PageHeader, Loader } from '../../components';
import { Back } from '../../icons';
import { getTransactionType } from '../../../utils/getTransactionType';
import {
  transactionStatus,
  transactionStatusColor
} from '../../../utils/transactionStatus';
import { formatIEPNumber } from '../../../utils/formatIEPNumber';
import NoRowsFound from '../../components/NoRowsTable';

const BackButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  // flex: 1;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const Text = styled.div`
  display: flex;
  flex: 1;
  margin: 7px 0;
`;
const Title = styled.h5`
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0 0 14px 0;
`;
const GreyText = styled(Text)`
  color: rgba(5, 34, 56, 0.56);
`;
const StatusLabel = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: 0.2px;
  padding: 2px 4px;
  border-radius: 2px;
  color: ${props =>
    props.status === 'green'
      ? '#00b326'
      : props.status === 'red'
      ? 'rgba(233, 20, 20, 0.87)'
      : '#fac832'};
  background: ${props =>
    props.status === 'green'
      ? 'rgba(0,179,38, 0.15)'
      : props.status === 'red'
      ? 'rgba(233, 20, 20, 0.15)'
      : 'rgba(250,200,50,0.15)'};
`;

const columns = t => [
  {
    Header: t('Ticket/Document Number'),
    accessor: 'documentNumber'
  },
  {
    Header: t('PNR/Airline reference'),
    accessor: 'pnrReference'
  },
  {
    Header: t('Issue Date'),
    accessor: 'issueDate'
  },
  {
    Header: t('Airline code'),
    accessor: 'airlineCode'
  },
  {
    Header: t('Amount'),
    accessor: 'amount'
  }
];

const Transaction = props => {
  const [t] = useTranslation('translation');
  const {
    getTransactionDetails,
    transaction: transactionDetails,
    match: {
      params: { transactionId }
    }
  } = props;
  const { gipResponseCode, gipResponseDescription } = transactionDetails || {};
  const responseDescription =
    [gipResponseCode, gipResponseDescription].filter(val => val).join(' - ') ||
    '-';

  useEffect(() => {
    getTransactionDetails(transactionId);
  }, [getTransactionDetails, transactionId]);

  return (
    <>
      {!transactionDetails || props.isLoading ? (
        <Loader />
      ) : (
        <div>
          <PageHeader>
            <BackButton onClick={() => props.history.goBack()}>
              <Back />
            </BackButton>
            {t('Transaction')} {transactionDetails.gipTransactionId || ''}
          </PageHeader>
          <Card>
            <Title>{t('Transaction Details')}</Title>
            <Row>
              <Column>
                <Row>
                  <GreyText>{t('Transaction Status')}</GreyText>
                  <Text>
                    <StatusLabel
                      status={transactionStatusColor(
                        transactionDetails.transactionStatus
                      )}
                    >
                      {transactionStatus(transactionDetails.transactionStatus)}
                    </StatusLabel>
                  </Text>
                </Row>
                <Row>
                  <GreyText>{t('Transaction Type')}</GreyText>
                  <Text>
                    {getTransactionType(transactionDetails.transactionType)}
                  </Text>
                </Row>
                <Row>
                  <GreyText>{t('Transaction date and time')}</GreyText>
                  <Text>
                    {moment
                      .utc(transactionDetails.transactionTime)
                      .local()
                      .format('DD/MM/YYYY HH:mm:ss')}
                  </Text>
                </Row>
                <Row>
                  <GreyText>{t('Amount')}</GreyText>
                  <Text>
                    {transactionDetails.amount || ''}{' '}
                    {transactionDetails.currencyCode || ''}
                  </Text>
                </Row>
                <Row>
                  <GreyText>{t('Wallet ID')}</GreyText>
                  <Text>{transactionDetails.walletId}</Text>
                </Row>
                <Row>
                  <GreyText>{t('Wallet name')}</GreyText>
                  <Text>{transactionDetails.walletName}</Text>
                </Row>

                {transactionDetails.transactionType === 1 ? (
                  <div>
                    <Row>
                      <GreyText>{t('Approval code')}</GreyText>
                      <Text>{transactionDetails.approvalCode}</Text>
                    </Row>
                    <Row>
                      <GreyText>{t('Sequence Number')}</GreyText>
                      <Text>{transactionDetails.sequenceNumber || '-'}</Text>
                    </Row>
                  </div>
                ) : (
                  <div>
                    <Row>
                      <GreyText>{t('Receiver Wallet ID')}</GreyText>
                      <Text>{transactionDetails.receiverWalletId || '-'}</Text>
                    </Row>
                    <Row>
                      <GreyText>{t('Receiver Wallet Name')}</GreyText>
                      <Text>
                        {transactionDetails.receiverWalletName || '-'}
                      </Text>
                    </Row>
                  </div>
                )}
              </Column>

              <Column>
                {transactionDetails.transactionType === 1 ? (
                  <div>
                    <Row>
                      <GreyText>{t('Agent code')}</GreyText>
                      <Text>{transactionDetails.agentNumericCode || '-'}</Text>
                    </Row>
                    <Row>
                      <GreyText>{t('Settlement Date')}</GreyText>
                      <Text>
                        {transactionDetails.settlementDate
                          ? moment
                              .utc(transactionDetails.settlementDate)
                              .local()
                              .format('DD/MM/YYYY HH:mm:ss')
                          : '-'}
                      </Text>
                    </Row>
                    <Row>
                      <GreyText>{t('Settlement Amount')}</GreyText>
                      <Text>
                        {transactionDetails.settlementAmount || '-'}{' '}
                        {transactionDetails.currencyCode || ''}
                      </Text>
                    </Row>
                    <Row>
                      <GreyText>{t('IEP number')}</GreyText>
                      <Text>
                        {transactionDetails.iepNumber &&
                          formatIEPNumber(transactionDetails.iepNumber)}
                      </Text>
                    </Row>
                    <Row>
                      <GreyText>{t('Transaction Code')}</GreyText>
                      <Text>{transactionDetails.transactionCode || '-'}</Text>
                    </Row>
                    <Row>
                      <GreyText>{t('Issue Date')}</GreyText>
                      <Text>
                        {transactionDetails.issueDate
                          ? moment
                              .utc(transactionDetails.issueDate)
                              .local()
                              .format('DD/MM/YYYY')
                          : '-'}
                      </Text>
                    </Row>
                  </div>
                ) : (
                  <div>
                    <Row>
                      <GreyText>{t('IEP Transaction ID')}</GreyText>
                      <Text>{transactionDetails.gipTransactionId || '-'}</Text>
                    </Row>
                    <Row>
                      <GreyText>{t('Bank Account Number')}</GreyText>
                      <Text>{transactionDetails.bankAccountNumber || '-'}</Text>
                    </Row>
                    <Row>
                      <GreyText>{t('Fee Amount')}</GreyText>
                      <Text>{transactionDetails.feeAmount || '-'}</Text>
                    </Row>
                  </div>
                )}

                <Row>
                  <GreyText>IEPN Type</GreyText>
                  <Text>
                    {transactionDetails.isMultiUseIEPN
                      ? 'Multi-use'
                      : 'Single-use'}
                  </Text>
                </Row>
                <Row>
                  <GreyText>Response Description</GreyText>
                  <Text>{responseDescription}</Text>
                </Row>
                <Row>
                  <GreyText>Note</GreyText>
                  <Text>{transactionDetails.note}</Text>
                </Row>
              </Column>
            </Row>
          </Card>
          {!isEmpty(transactionDetails.ticketDetails) && (
            <>
              <PageHeader>{t('Tickets Records')}</PageHeader>
              <Card>
                <ReactTable
                  PaginationComponent={() => <></>}
                  NoDataComponent={NoRowsFound}
                  data={transactionDetails.ticketDetails}
                  columns={columns(t)}
                  filterable={props.isFilterable}
                  sortable={false}
                  minRows={1}
                  className="-highlight transaction-details"
                />
              </Card>
            </>
          )}
        </div>
      )}
    </>
  );
};
export default Transaction;
