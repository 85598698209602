import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import get from 'lodash.get';
import { Card, Button, Loader, ListCheckboxes } from '../components';
import Modal from '../components/Modal';
import PageHeader from '../components/PageHeader';
import { Back } from '../icons';
import MainUserInformation from '../components/MainUserInformation';
import RejectUser from './RejectUser';

const Root = styled.div`
  width: 745px;
  margin: 0 auto;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
const BackButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
`;
const ButtonStyled = styled(Button)`
  margin: 20px 0;
`;
const Text = styled.div`
  flex: 3;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  margin: 4px 0;
`;
const Divider = styled.div`
  margin-top: 7px;
  margin-bottom: 16px;
  height: 1px;
  border-bottom: 1px solid rgba(5, 34, 56, 0.12);
`;
const GreyText = styled(Text)`
  flex: 1;
  color: rgba(5, 34, 56, 0.56);
`;
const GreyTextSmall = styled(GreyText)`
  flex: 2;
  font-size: 12px;
`;
const GreyTextCurrency = styled(GreyText)`
  flex: 3;
  font-size: 12px;
`;

const Label = styled.p`
  color: rgba(5, 34, 56, 0.87);
  font-size: 12px;
  margin-bottom: 5px;
  opacity: 0.56;
`;
const renderSelect = props => {
  const {
    input,
    meta: { touched, error },
    label,
    placeholder,
    options
  } = props;
  return (
    <div>
      <Label>{label}</Label>
      <Select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        isSearchable={false}
        placeholder={placeholder}
        error={!touched && error}
        options={options}
        onBlur={() => input.onBlur(input.value)}
      />
    </div>
  );
};

const roleEnum = [
  { id: 1, name: 'IEP Admin' },
  { id: 2, name: 'Master Wallet Manager' },
  { id: 3, name: 'Sub Wallet Manager' },
  { id: 4, name: 'Ticket Issuer' },
  { id: 5, name: 'Agency Read Only' },
  { id: 6, name: 'CS Agent' }
];

class User extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    userRoleId: this.props.currentRole,
    isSaving: false
  };

  componentDidMount() {
    this.props.getUserDetailsPending(this.props.match.params.userId);
    this.props.getUserDataForEditing(this.props.match.params.userId);
    this.props.getAccessibleRoles();
  }

  static getDerivedStateFromProps(props, state) {
    // Store prevId in state so we can compare when props change.
    // Clear out previously-loaded data (so we don't render stale stuff).
    const userRoleId = props.currentRole;

    if (userRoleId && userRoleId !== state.userRoleId) {
      return {
        userRoleId
      };
    }

    // No state update necessary
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.userRoleId !== this.state.userRoleId) {
      this.props.getUserDataForEditing(
        this.props.user.id,
        this.state.userRoleId
      );
    }
  }

  componentWillUnmount(): void {
    this.props.unsetCurrentUser();
  }

  handleAcceptUser = val => {
    const availableWallets = get(this.props, 'userData.wallets', []);
    const walletIds =
      val.userRole.value === 4 || val.userRole.value === 5
        ? availableWallets
            .filter(({ id }) => val.walletIds.some(i => i === id))
            .map(({ id }) => id)
        : [];

    const agencies = get(this.props, 'userData.agencies', []);
    const agencyIds =
      val.userRole.value === 3
        ? agencies
            .filter(({ agentCode }) => val.agencyIds.some(i => i === agentCode))
            .map(({ agencyId }) => agencyId)
        : [];
    const dataToAPI = {
      role: val.userRole.value,
      walletIds,
      agencyIds,
      isUserApproval: true
    };

    this.setState({ isSaving: true });
    this.props
      .approveUser(this.props.user.id, dataToAPI)
      .then(this.props.history.goBack)
      .catch(this.props.history.goBack);
  };

  render() {
    const { isSaving } = this.state;
    const { user } = this.props;
    const roleOptions = this.props.accessibleRoles.map(role => {
      const { name } = roleEnum.find(i => i.id === role);
      return { value: role, label: name };
    });

    return (
      <Root>
        {(isSaving || this.props.isLoading) && <Loader absolute />}
        {!this.props.isLoading && this.props.user && (
          <>
            <Row>
              <BackButton onClick={this.props.history.goBack}>
                <Back />
              </BackButton>
              <PageHeader>{user.name}</PageHeader>
            </Row>
            <Card>
              <MainUserInformation
                status={user.status}
                email={user.email}
                code={user.agentCode}
                //  wallets={this.props.user.wallets.length}
              />
              <Divider />
              <Row>
                <Text>
                  {this.props.t(
                    'Please choose assignment to accept this account'
                  )}
                </Text>
              </Row>
              <form onSubmit={this.props.handleSubmit(this.handleAcceptUser)}>
                <Field
                  component={renderSelect}
                  name="userRole"
                  options={roleOptions}
                  label={this.props.t('User role')}
                  placeholder={this.props.t('Choose user role')}
                />

                {(this.state.userRoleId === 3 ||
                  this.state.userRoleId === 4 ||
                  this.state.userRoleId === 5) && (
                  <Field
                    name="walletIds"
                    label={
                      <>
                        <GreyTextSmall>
                          {this.props.t('Assigned wallet')}
                        </GreyTextSmall>
                        <GreyTextCurrency>
                          {this.props.t('Currency')}
                        </GreyTextCurrency>
                      </>
                    }
                    component={ListCheckboxes}
                    isLoading={this.props.userData.isLoading}
                    selectIfOne
                    labelName="name"
                    valueName="id"
                    keyName="id"
                    additionalValueName="currency"
                    options={get(this.props, 'userData.wallets', [])}
                  />
                )}
                {user.canBeApproved && (
                  <ButtonStyled
                    primary
                    title={this.props.t('Accept user account')}
                    type="submit"
                    disabled={this.props.invalid}
                  />
                )}
              </form>
              {user.canBeRejected && (
                <div>
                  <Text>
                    {this.props.t(
                      'Or you can reject this account if any data is not correct'
                    )}
                  </Text>
                  <Button
                    destructive
                    title={this.props.t('Reject user account')}
                    onClick={() =>
                      this.props.openModal(
                        'rejectUser',
                        this.props.t('Reject User'),
                        {
                          email: user.email,
                          code: user.agentCode,
                          id: user.id
                        }
                      )
                    }
                  />
                </div>
              )}
            </Card>
            <Modal
              open={this.props.isModalOpen}
              title={this.props.modalTitle}
              closeModal={this.props.closeModal}
            >
              <RejectUser
                rejectUser={this.props.rejectUser}
                closeModal={this.props.closeModal}
                back={this.props.history.goBack}
              />
            </Modal>
          </>
        )}
      </Root>
    );
  }
}

export default withTranslation('translation')(User);
