/* eslint-disable no-unused-vars */
import {
  FETCH_USER_DETAIL_NOT_APPROVED,
  FETCH_USERS_NOT_APPROVED,
  UNSET_CURRENT_USER,
  SAVE_USER
} from '../../constants/actionTypes';

export const initState = {
  users: [],
  currentUser: null,
  isLoading: false,
  pageInfo: null
};

export default {
  [FETCH_USERS_NOT_APPROVED.REQUEST]: state => {
    return {
      ...state,
      isLoading: true
    };
  },
  [FETCH_USERS_NOT_APPROVED.SUCCESS]: (state, { payload }) => {
    const { pageInfo } = payload;
    return {
      ...state,
      users: payload.data,
      pageInfo,
      isLoading: false
    };
  },
  [FETCH_USERS_NOT_APPROVED.FAILURE]: (state, { code }) => {
    return { ...state, isLoading: false };
  },
  [FETCH_USER_DETAIL_NOT_APPROVED.REQUEST]: state => {
    return {
      ...state,
      isLoading: true
    };
  },
  [FETCH_USER_DETAIL_NOT_APPROVED.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      currentUser: payload.data,
      isLoading: false
    };
  },
  [UNSET_CURRENT_USER]: state => {
    return {
      ...state,
      currentUser: initState.currentUser
    };
  },
  [FETCH_USER_DETAIL_NOT_APPROVED.FAILURE]: (state, { code }) => {
    return { ...state, isLoading: false };
  },
  [SAVE_USER.REQUEST]: state => {
    return {
      ...state
    };
  },
  [SAVE_USER.SUCCESS]: (state, { payload }) => {
    return {
      ...state
    };
  },
  [SAVE_USER.FAILURE]: (state, { code }) => {
    return { ...state };
  }
};
