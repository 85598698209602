import styled from 'styled-components';
import { Calendar } from '../../icons';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 36px;
`;
export const RowSelect = styled(Row)`
  display: flex;
  flex-direction: row;
  flex: 2;
  justify-content: flex-end;
  align-items: center;
  > div {
    margin-left: 10px;
  }
`;
export const IataLabel = styled.div`
  color: rgba(5, 34, 56, 0.56);
  font-size: 13px;
`;
export const PageHeader = styled.h2`
  flex: 1;
  color: rgba(5, 34, 56, 0.87);
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 0.86px;
  margin: 0 0 14px 0;
`;
export const CalendarBlock = styled.div`
  position: relative;
`;

export const CalendarStyled = styled(Calendar)`
  position: absolute;
  top: 10px;
  right: 5px;
  path:not(:nth-child(1)) {
    fill: #004b87;
    stroke: #004b87;
  }
`;

export const ActionBlock = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-right: 30px;
  align-items: center;
`;

export const TableHeader = styled.div`
  text-transform: uppercase;
`;

export const ButtonBlock = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Toggler = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RadioLabel = styled.label`
  display: inline-block;
  background-color: ${props =>
    props.disabled ? 'rgba(249, 249, 251, 0.3)' : '#f9f9fb'};
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.75;
  cursor: pointer;
  color: ${props => (props.disabled ? 'rgba(191, 191, 191)' : 'inherit')};
`;

export const RadioPeriod = styled.input`
  opacity: 0;
  position: fixed;
  width: 0;
  &:checked + ${RadioLabel} {
    color: #fff;
    background: #004b87;
  }
`;

export const Total = styled.div`
  color: rgba(5, 34, 56, 0.56);
`;
export const TotalValue = styled.span`
  color: rgba(5, 34, 56, 0.87);
  font-weight: bold;
`;
export const ButtonUnderlined = styled.button`
  font-size: 14px;
  color: #004b87;
  border: none;
  outline: none;
  background: #fff;
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const RowEnd = styled(Row)`
  justify-content: flex-end;
`;

export const SubHeaderRoot = styled(Row)`
  border-top: 1px solid rgba(5, 34, 56, 0.07);
  border-bottom: 1px solid rgba(5, 34, 56, 0.07);
  padding: 18px 15px;
  margin: 0 -25px;
`;

export const iataStyles = {
  container: provided => ({
    ...provided,
    width: 130,
    height: 40
  })
};

export const walletStyles = {
  container: provided => ({
    ...provided,
    width: 170,
    height: 40
  })
};

export const periodStyles = {
  container: provided => ({
    ...provided,
    width: 180,
    height: 40
  }),
  indicatorsContainer: provided => ({
    ...provided,
    display: 'none'
  })
};
