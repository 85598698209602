// @flow
import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import * as R from 'ramda';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextInput, Button } from '../components';
import {
  getTransactions,
  setIsAdvancedSearch
} from '../../redux/actions/transactions';
import { closeModal } from '../../redux/actions/modals';
import { getAccessibleWallets } from '../../redux/actions/wallets';
import { setPage } from '../../redux/actions/commonActions';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
  flex: 1;
`;
const FieldBlock = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 15px;
`;
const Label = styled.p`
  color: rgba(5, 34, 56, 0.87);
  font-size: 12px;
  margin-bottom: 5px;
  opacity: 0.56;
`;
const ButtonProceed = styled(Button)`
  position: absolute;
  bottom: 24px;
  left: 140px;
`;
const InputGlobal = createGlobalStyle`
	.smaller-input{
		margin-right: 12px;
    }
    .react-datepicker-wrapper {
        // width: 100%;
    }
    .css-1okebmr-indicatorSeparator {
        background-color: none;
    }
    .calendar {
        border: ${props =>
          props.error
            ? '2px solid #f15c5c'
            : '1px solid rgba(5, 34, 56, 0.25)'};
        border-radius: 2px;
        width: 268px;
        padding: 8px 12px;
        font-size: 14px;
        line-height: 1.71;
        background: ${props => (props.disabled ? '#f7f7fe' : '#fff')}
        &:focus {
            border: 1px solid #004b87;
        }
    }
`;

const renderField = props => {
  const {
    input,
    type,
    meta: { touched, error },
    placeholder,
    label,
    className,
    maxlength
  } = props;
  return (
    <TextInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...input}
      type={type}
      error={!touched && error}
      label={label}
      placeholder={placeholder}
      helperText={error}
      className={className}
      maxlength={maxlength}
    />
  );
};

const renderSelect = props => {
  const {
    input,
    meta: { touched, error },
    label,
    placeholder,
    options,
    isClearable
  } = props;
  return (
    <FieldBlock>
      <Label>{label}</Label>
      <Select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        isSearchable={false}
        placeholder={placeholder}
        error={!touched && error}
        options={options}
        isClearable={isClearable}
        maxMenuHeight={130}
        onBlur={() => input.onBlur(input.value)}
      />
    </FieldBlock>
  );
};
const renderDatePicker = ({
  input,
  placeholder,
  label,
  className,
  maxDate,
  minDate,
  autoComplete
}) => {
  return (
    <FieldBlock>
      <Label>{label}</Label>
      <DatePicker
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        style={{ width: 200 }}
        dateFormat="dd/MM/yyyy"
        onBlur={
          input.value ? () => input.onBlur(new Date(input.value)) : () => {}
        }
        onChange={input.onChange}
        placeholderText={placeholder}
        selected={input.value ? new Date(input.value) : null}
        className={className}
        autoComplete={autoComplete}
        maxDate={maxDate}
        minDate={minDate}
      />
    </FieldBlock>
  );
};
// eslint-disable-next-line react/jsx-props-no-spreading
const TextField = props => <Field component={TextInput} {...props} />;
// eslint-disable-next-line react/jsx-props-no-spreading
const SelectField = props => <Field component={renderSelect} {...props} />;
// eslint-disable-next-line react/jsx-props-no-spreading
const DateField = props => <Field component={DatePicker} {...props} />;
const numbersOnly = value => {
  return value.replace(/[^\d]/g, '');
};

const decimalNumbers = (value, previousValue) => {
  if (value.match(/^\d*(\.\d{0,2})?$/)) {
    return value;
  }
  return previousValue;
};

const transactionStatuses = [
  { value: '0', label: 'All' },
  { value: '1', label: 'Approved' },
  { value: '2', label: 'Voided' },
  { value: '3', label: 'Settled' },
  { value: '4', label: 'Refunded' },
  { value: '5', label: 'Declined' },
  { value: '6', label: 'Failed' },
  { value: '7', label: 'Success' },
  { value: '8', label: 'Pending' }
];

const AdvancedSearch = props => {
  const [t] = useTranslation('translation');

  useEffect(() => {
    props.getAccessibleWallets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchTransaction = () => {
    props.setIsAdvancedSearch(true);
    props.getTransactions(1, props.pageSize);
    props.setPage(0);
    props.closeModal();
  };

  const walletsName = props.wallets.map(wallet => {
    return { value: wallet.id.toString(), label: wallet.name };
  });

  return (
    <Root>
      <InputGlobal />
      <form onSubmit={props.handleSubmit(searchTransaction)}>
        <SelectField
          name="transactionStatus"
          component={renderSelect}
          options={transactionStatuses}
          placeholder={t('Select transaction status')}
          label={t('Status')}
        />
        <Row>
          <DateField
            name="dateFrom"
            component={renderDatePicker}
            placeholder="__/__/__"
            autoComplete="off"
            className="smaller-input calendar"
            label={t('Date from')}
            maxDate={new Date()}
          />
          <DateField
            name="dateTo"
            component={renderDatePicker}
            placeholder="__/__/__"
            autoComplete="off"
            className="calendar"
            label={t('Date to')}
            maxDate={new Date()}
            minDate={props.startDate}
          />
        </Row>
        <Row>
          <TextField
            name="iepNumber"
            component={renderField}
            placeholder={t('Enter IEP number')}
            normalize={numbersOnly}
            className="smaller-input"
            label={t('IEP number')}
          />
          <TextField
            name="transactionId"
            component={renderField}
            placeholder={t('IEP Transaction ID')}
            label={t('IEP Transaction ID')}
          />
        </Row>
        <Row>
          <TextField
            name="airlineCode"
            component={renderField}
            placeholder={t('Enter airline code')}
            className="smaller-input"
            label={t('Airline code')}
          />
          <TextField
            name="pnrReference"
            component={renderField}
            placeholder={t('Enter PNR/Airline reference')}
            label={t('PNR/Airline reference')}
          />
        </Row>
        <Row>
          <Row>
            <TextField
              name="amountFrom"
              component={renderField}
              className="smaller-input"
              placeholder="0.00"
              normalize={decimalNumbers}
              label={t('Amount from')}
            />
            <TextField
              name="amountTo"
              component={renderField}
              className="smaller-input"
              placeholder="0.00"
              normalize={decimalNumbers}
              label={t('Amount to')}
            />
          </Row>
          <Row>
            <SelectField
              name="walletId"
              component={renderSelect}
              options={walletsName}
              isClearable
              // placeholder={'Enter account name'}
              label={t('Wallet')}
            />
          </Row>
        </Row>
        <Row>
          <TextField
            name="approvalCode"
            component={renderField}
            placeholder={t('Enter approval code')}
            label={t('Approval code')}
          />
        </Row>
        <Row>
          <TextField
            name="agentCode"
            component={renderField}
            normalize={numbersOnly}
            placeholder={t('Enter agent code')}
            className="smaller-input"
            label={t('Agent code')}
            maxlength={8}
          />
          {/* <TextField
            name="ticketNumber"
            component={renderField}
            placeholder="Enter ticket number"
            label="Ticket number"
          /> */}
        </Row>
        <ButtonProceed
          primary
          title={t('Search')}
          type="submit"
          disabled={props.pristine}
        />
      </form>
    </Root>
  );
};

const formCreator = reduxForm({
  form: 'advancedTransactionSearch',
  enableReinitialize: false,
  initialValues: {
    transactionStatus: { value: '0', label: 'All' }
  },
  destroyOnUnmount: false
});

const mapStateToProps = state => ({
  modalData: state.modals.modalData,
  wallets: state.wallets.accessibleWallets,
  startDate:
    (state.form.advancedTransactionSearch &&
      state.form.advancedTransactionSearch.values.dateFrom) ||
    null,
  pageSize: state.transactions.pageSize
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTransactions,
      closeModal,
      getAccessibleWallets,
      setIsAdvancedSearch,
      setPage
    },
    dispatch
  );

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);

export default R.compose(reduxConnector, formCreator)(AdvancedSearch);
