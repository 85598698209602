import { api } from '../../services/rest';
import { IS_DOCUMENT_LOADING } from '../../constants/actionTypes';

const downloadDocument = (path, fileName) => dispatch => {
  dispatch({ type: IS_DOCUMENT_LOADING.REQUEST });
  api
    .get(path, {
      responseType: 'arraybuffer'
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      dispatch({ type: IS_DOCUMENT_LOADING.SUCCESS });
    })
    .catch(err => {
      dispatch({ type: IS_DOCUMENT_LOADING.FAILURE });
      console.log(err);
    });
};

export default downloadDocument;
