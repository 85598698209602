import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as R from 'ramda';
import get from 'lodash.get';
import { reduxForm } from 'redux-form';
import RequestUser from './RequestUser';
import { openModal, closeModal } from '../../redux/actions/modals';
import {
  getUserDetailsPending,
  unsetCurrentUser,
  approveUser,
  rejectUser
} from '../../redux/actions/usersPending';
import {
  getAccessibleRoles,
  getUserDataForEditing
} from '../../redux/actions/usersApproved';

const roleEnam = [
  { id: 1, name: 'IEP Admin' },
  { id: 2, name: 'Master Wallet Manager' },
  { id: 3, name: 'Sub Wallet Manager' },
  { id: 4, name: 'Ticket Issuer' },
  { id: 5, name: 'Agency Read Only' },
  { id: 6, name: 'CS Agent' }
];

const mapStateToProps = state => {
  const userRole =
    get(state, 'form.requestUser.values.userRole.value') ||
    get(state, 'usersPending.currentUser.role');

  return {
    isModalOpen: state.modals.isModalOpen,
    modalTitle: state.modals.modalTitle,
    modalType: state.modals.modalType,
    modalData: state.modals.modalData,
    user: state.usersPending.currentUser,
    isLoading: state.usersPending.isLoading,
    accessibleRoles: state.usersApproved.accessibleRoles,
    userData: state.usersApproved.userData,
    initialValues: {
      agencyIds: get(state, 'usersPending.currentUser.agencies', []).map(
        ({ agentCode }) => agentCode
      ),
      walletIds: get(state, 'usersApproved.userData.wallets', [])
        .filter(({ isAssigned }) => isAssigned)
        .map(({ id }) => id),
      userRole: {
        value: userRole,
        label: userRole && roleEnam.find(i => i.id === userRole).name
      }
    },
    currentRole: userRole
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      getUserDetailsPending,
      getAccessibleRoles,
      unsetCurrentUser,
      getUserDataForEditing,
      // saveUser,
      rejectUser,
      approveUser
    },
    dispatch
  );
const validate = values => {
  const errors = {};

  if (!values.userRole) {
    errors.userRole = 'Incorrect';
  }

  if (values.userRole && values.userRole.value === 3 && values.agencyIds < 1) {
    errors.agencyIds = 'Field is required';
  }

  return errors;
};
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
const formCreator = reduxForm({
  form: 'requestUser',
  validate,
  enableReinitialize: true,
  destroyOnUnmount: true
});

export default R.compose(reduxConnector, formCreator)(RequestUser);
