import React from 'react';
import styled from 'styled-components';
import { UploadFile } from '../icons';
import baseTheme from '../../styles/theme';

const HiddenFileInput = styled.input.attrs({
  type: 'file'
})`
  display: none;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
    border: ${props =>
      props.error ? '2px solid #f15c5c' : '1px solid rgba(5, 34, 56, 0.25)'};
    border-radius: 2px;
    width: 100%;
    padding: 8px 12px;
    padding-left: ${props => (props.withIcon ? '32px' : '8px')};
    font-size: 14px;
    line-height: 1.71;
    background: ${props => (props.disabled ? '#f7f7fe' : '#fff')}
    &:focus {
        border: 1px solid #004b87;
    }
`;
const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
`;
const UploadButton = styled.div`
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  color: #004b87;
  position: absolute;
  right: 0;
  top: 6px;
`;
const Label = styled.p`
  color: ${baseTheme.color.font.grey};
  font-size: 12px;
  margin-bottom: 5px;
  opacity: 0.56;
`;
const HelpText = styled.p`
  color: ${props => (props.error ? '#f15c5c' : 'rgba(5, 34, 56, 0.32)')};
  font-size: 12px;
  margin-top: 5px;
`;
class FileInput extends React.Component {
  fileInputRef = React.createRef();

  customFileInputRef = React.createRef();

  // eslint-disable-next-line react/state-in-constructor
  // state = {
  //   selectedFile: ''
  // };

  chooseFile = () => {
    this.fileInputRef.current.click();
  };

  addFile = () => {
    const file = this.fileInputRef.current.files[0];
    // console.log(file);
    this.props.onChange(file);
    // this.setState({ selectedFile: file });
  };

  render() {
    return (
      <Column>
        <Label>{this.props.label}</Label>
        <InputContainer>
          <Input
            type="text"
            value={this.props.value.name}
            placeholder={this.props.placeholder}
            onClick={this.chooseFile}
          />
          <UploadButton onClick={this.chooseFile}>
            <UploadFile />
          </UploadButton>
        </InputContainer>
        <HelpText error={this.props.rawErrors}>
          {this.props.helpText || this.props.rawErrors}
        </HelpText>
        <HiddenFileInput
          type="file"
          ref={this.fileInputRef}
          onChange={this.addFile}
          accept="image/png, image/jpeg, image/jpg, application/pdf"
        />
      </Column>
    );
  }
}
export default FileInput;
