import React from 'react';
import styled from 'styled-components';

const PageHeaderComponent = styled.h2`
  color: rgba(5, 34, 56, 0.87);
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 0.86px;
  margin: 0 0 14px 0;
`;
const PageHeader = props => (
  <PageHeaderComponent>{props.children}</PageHeaderComponent>
);
export default PageHeader;
