import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NoMatch from '../components/NoMatch';
import TermsConditions from '../components/TermsConditions';
import AccountIsBeingCreated from '../components/AccountBeingCreated';
import WalletsList from '../components/Wallets';
import Wallet from '../components/Wallets/Wallet';
import TransactionsList from '../components/Transactions';
import Transaction from '../components/Transactions/Transaction';
import Settings from '../components/Settings';
import UserRouter from '../components/Users/UserRouter';
import User from '../components/User';
import RequestUser from '../components/RequestUser';
import AccessDenied from '../components/AccessDenied';
import ServiceUnavailable from '../components/ServiceUnavailable';
import RedirectPage from '../components/RedirectPage';
import InternalUsers from '../components/InternalUsers';
import Reports from '../components/Reports';
import ReportDetails from '../components/ReportDetails';
import Help from '../components/help/Help';

const RouterContainer = () => {
  const [t] = useTranslation('translation');
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/portal" />
      </Route>
      {/* Wallet routes */}
      <Route exact path="/portal">
        <Redirect to="/portal/wallets" />
      </Route>
      <Route exact path="/portal/wallets" component={WalletsList} />
      <Route exact path="/portal/wallets/:walletId" component={Wallet} />
      {/* Transactions routes */}
      <Route exact path="/portal/transactions" component={TransactionsList} />
      <Route
        exact
        path="/portal/transactions/:transactionId"
        component={Transaction}
      />
      {/* User routes */}
      <Route exact path="/portal/users" component={UserRouter} />
      <Route exact path="/portal/users/:userId" component={User} />
      <Route
        exact
        path="/portal/users/review/:userId"
        component={RequestUser}
      />
      {/* Settings routes */}
      <Route exact path="/portal/settings" component={Settings} />
      {/* Help routes */}
      <Route exact path="/portal/help" component={Help} />
      {/* Others routes */}
      <Route path="/503" component={ServiceUnavailable} />
      <Route exact path="/redirect-page" component={RedirectPage} />
      <Route exact path="/redirect-internal-page" component={RedirectPage} />
      <Route path="/termsAndConditions" component={TermsConditions} />
      <Route
        path="/userAccountBeingCreated"
        render={() => (
          <AccountIsBeingCreated header={t('User account is being created')} />
        )}
      />
      <Route
        path="/agencyAccountBeingCreated"
        render={() => (
          <AccountIsBeingCreated
            header={t('IATA EasyPay account being created')}
          />
        )}
      />
      <Route path="/internal-code" component={InternalUsers} />
      <Route
        exact
        path="/portal/reports/reportsDetails/:reportId"
        component={ReportDetails}
      />
      <Route path="/portal/reports" component={Reports} />
      <Route exact path="/portal/accessDenied" component={AccessDenied} />
      <Route component={NoMatch} />
    </Switch>
  );
};

export default RouterContainer;
