import React from 'react';

const Back = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      className={props.className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#004B87"
          d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.42L6.83 13H21z"
        />
      </g>
    </svg>
  );
};
export default Back;
