import React, { useRef } from 'react';
import ReactTable from 'react-table';
import { useDebouncedCallback } from 'use-debounce';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import TableFooter from '../../components/Table/TableFooter';
import { getUserRoleById } from '../../../utils/getUserRoleById';
import { restrictCharacters } from '../../../utils/restrictCharacters';
import NoRowsFound from '../../components/NoRowsTable';

const ButtonUnderlined = styled.button`
  color: #004b87;
  text-decoration: underline;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
`;
const AccessRequest = props => {
  const currentPageRef = useRef(null);
  const currentPageSizeRef = useRef(null);

  const [t] = useTranslation('translation');
  const [onFilter] = useDebouncedCallback((filterable, page, pageSize) => {
    props.getUsersPending(page + 1, pageSize, filterable);
  }, 1000);

  const ALLOWED_CHARS_REGEXP = /[0-9/]+/;

  const columns = [
    {
      Header: t('User name'),
      accessor: 'name',
      Filter: ({ filter, onChange }) => (
        <input
          type="text"
          placeholder={t('Search...')}
          value={filter ? filter.value : ''}
          onChange={event => onChange(event.target.value)}
        />
      )
    },
    {
      Header: t('Email'),
      accessor: 'email',
      Filter: ({ filter, onChange }) => (
        <input
          type="text"
          placeholder={t('Search...')}
          value={filter ? filter.value : ''}
          onChange={event => onChange(event.target.value)}
        />
      )
    },
    {
      Header: t('IATA Code'),
      accessor: 'agentCode',
      Filter: ({ filter, onChange }) => (
        <input
          type="text"
          placeholder={t('Search...')}
          maxLength={8}
          value={filter ? filter.value : ''}
          onKeyPress={event => restrictCharacters(event, ALLOWED_CHARS_REGEXP)}
          onChange={event => onChange(event.target.value)}
        />
      )
    },
    {
      Header: t('Requested role'),
      accessor: 'role',
      Cell: row => <div>{getUserRoleById(row.value)}</div>,
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : ''}
        >
          <option value="0">All</option>
          <option value="1">IEP Admin</option>
          <option value="2">Master Wallet Manager</option>
          <option value="3">Sub Wallet Manager</option>
          <option value="4">Ticket Issuer</option>
          <option value="5">Agency Read Only</option>
          {/* <option value="6">CS Agent</option>
                            <option value="7">Approver</option> */}
        </select>
      )
    },
    {
      Header: '',
      accessor: 'userId',
      filterable: false,
      Cell: row => {
        const { canBeEdited } = row.original;
        return canBeEdited ? (
          <ButtonUnderlined
            onClick={() =>
              props.history.push(`/portal/users/review/${row.value}`)
            }
          >
            {t('Review')}
          </ButtonUnderlined>
        ) : (
          <div />
        );
      }
    }
  ];

  return (
    <div>
      <ReactTable
        PaginationComponent={TableFooter}
        NoDataComponent={NoRowsFound}
        data={props.users}
        columns={columns}
        defaultPageSize={10}
        filterable
        manual
        minRows={1}
        className="-highlight"
        loading={props.isLoading}
        sortable={false}
        pageInfo={props.pageInfo}
        pages={props.pageInfo && props.pageInfo.totalPage}
        onFetchData={({ page, pageSize, filtered }) => {
          if (
            currentPageRef.current === null ||
            currentPageRef.current !== page ||
            currentPageSizeRef.current === null ||
            currentPageSizeRef.current !== pageSize
          ) {
            currentPageRef.current = page;
            currentPageSizeRef.current = pageSize;
            props.getUsersPending(page + 1, pageSize, filtered);
          } else {
            onFilter(filtered, page, pageSize);
          }
        }}
      />
    </div>
  );
};

export default AccessRequest;
