import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import User from './User';
import { openModal, closeModal } from '../../redux/actions/modals';
import {
  deleteUser,
  disableUser,
  enableUser,
  getUserDetailsApproved,
  getUserDataForEditing
} from '../../redux/actions/usersApproved';
import { saveUser } from '../../redux/actions/usersPending';

const mapStateToProps = state => ({
  isModalOpen: state.modals.isModalOpen,
  modalTitle: state.modals.modalTitle,
  modalType: state.modals.modalType,
  user: state.usersApproved.currentUser,
  isLoading: state.usersApproved.isLoading,
  userData: state.usersApproved.userData
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      deleteUser,
      disableUser,
      enableUser,
      getUserDetailsApproved,
      getUserDataForEditing,
      saveUser
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(User);
