import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { Card, Button, PageHeader, Loader, InlineLoader } from '../components';
import { Download } from '../icons';
import {
  ENGLISH_LOCALE,
  THAI_LOCALE,
  VIETNAMESE_LOCALE
} from '../../constants/LocalizationConstants';
import { getSearchTransactionsPath } from '../../utils/getSearchTransactionsPath';
import store from '../../redux/configureStore';
import { addAlert } from '../../redux/actions/alerts';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const GreyText = styled.p`
  color: rgba(5, 34, 56, 0.56);
`;
const HR = styled.hr`
  border-color: #e7e7f0;
  width: 100%;
  border-top: 0;
`;
const ButtonReject = styled(Button)`
  margin-left: 15px;
`;
const ButtonAgree = styled(Button)`
  margin-bottom: 30px;
  margin-top: 0;
  padding: 8px 30px;
`;
const TermsInfo = styled.div`
  line-height: 1.43;
  letter-spacing: 0.3px;
  margin-bottom: 16px;
`;
const ALink = styled.a`
  color: #004b87;
  text-decoration: underline;
`;
const InlineComponent = styled.div`
  display: inline-flex;
  margin-top: 0;
`;

const StyledSelect = styled(Select)`
  width: 330px;
  margin-right: 16px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const customSelectStyles = {
  indicatorSeparator: () => {},
  valueContainer: style => ({
    ...style,
    flex: 0.8
  }),
  container: style => ({
    ...style,
    border: '1px #004B87 solid'
  }),
  control: style => ({
    ...style,
    border: 'none',
    flexDirection: 'row-reverse',
    cursor: 'pointer'
  }),
  placeholder: style => ({
    ...style,
    fontSize: '14px',
    color: '#004B87',
    letterSpacing: '0.58px'
  }),
  indicatorsContainer: style => ({
    ...style,
    flex: 0.2,
    justifyContent: 'flex-end'
  }),
  singleValue: style => ({
    ...style,
    color: '#004B87'
  }),
  option: style => ({
    ...style,
    color: '#004B87',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  })
};

const DropdownIndicator = () => <Download />;
const Option = props => {
  return (
    <components.Option {...props}>
      {props.data.label}
      <Download />
    </components.Option>
  );
};

const TermsConditions = props => {
  const [t] = useTranslation('translation');

  const rejectTerms = () => {
    props.setReject();
    setTimeout(() => {
      window.close();
      window.close();
    }, 3000);
  };

  const acceptTerms = () => {
    props
      .setAccountCreatingStatus(true)
      .then(props.initApp)
      .then(userInfo => {
        const page = {
          1: '/termsAndConditions',
          2: '/agencyAccountBeingCreated',
          3: '/userAccountBeingCreated',
          4: '/portal'
        }[userInfo.userNavigationState];

        if (!page) {
          store.dispatch(
            addAlert({
              alertType: 'error',
              text:
                'Wrong User Navigation state. Please correct user navigation state on API.'
            })
          );
        }

        if (page === '/portal') {
          props.showMenu();
        }

        props.history.push(page);
      });
  };

  const { isTermsDownloaded, isLoading } = props;

  const langTermsMap = {
    en: [{ culture: ENGLISH_LOCALE.culture, name: ENGLISH_LOCALE.fullName }],
    th: [
      { culture: 'en-TH', name: ENGLISH_LOCALE.fullName },
      { culture: THAI_LOCALE.culture, name: THAI_LOCALE.fullName }
    ],
    ms: [{ culture: 'en-MY', name: ENGLISH_LOCALE.fullName }],
    vi: [
      { culture: 'en-VN', name: ENGLISH_LOCALE.fullName },
      { culture: VIETNAMESE_LOCALE.culture, name: VIETNAMESE_LOCALE.fullName }
    ],
    id: [{ culture: 'en-ID', name: ENGLISH_LOCALE.fullName }]
  };

  const options = langTermsMap[props.account.agentCulture.language].map(
    locale => ({
      label: t(locale.name),
      value: locale.culture
    })
  );

  const handleChange = newLocale => {
    props.setTermsConditionsLoaded();
    props.downloadDocument(
      `document/termsAndConditions?${getSearchTransactionsPath({
        culture: newLocale.value
      })}`,
      'Terms&Conditions.pdf'
    );
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <PageHeader>{t('IATA EasyPay terms and conditions')}</PageHeader>
          <Card>
            <Column>
              <Column>
                <TermsInfo>
                  <Trans i18nKey="Access terms and conditions" />
                </TermsInfo>
                <Row>
                  <StyledSelect
                    isSearchable={false}
                    styles={customSelectStyles}
                    components={{ DropdownIndicator, Option }}
                    options={options}
                    maxMenuHeight={130}
                    onChange={handleChange}
                    value={{
                      label: t('Download terms and conditions'),
                      value: null
                    }}
                  />
                  {props.isDocumentLoading && <InlineLoader size={24} />}
                </Row>
              </Column>
              <HR />
              <Column>
                <GreyText>{t('Confirm terms and conditions')}</GreyText>
                <InlineComponent>
                  <ButtonAgree
                    title={t('Agree')}
                    primary
                    disabled={!isTermsDownloaded}
                    onClick={acceptTerms}
                  />
                  <ButtonReject
                    title={t('Reject')}
                    secondary
                    disabled={!isTermsDownloaded}
                    onClick={rejectTerms}
                  />
                </InlineComponent>
                <GreyText>{t('Come back to confirm')}</GreyText>
                <ALink href="/">{t('Back to IATA customer portal')}</ALink>
              </Column>
            </Column>
          </Card>
        </div>
      )}
    </div>
  );
};

export default TermsConditions;
