import { OPEN_MODAL, CLOSE_MODAL } from '../../constants/actionTypes';

export const openModal = (modalType, modalTitle, data) => ({
  type: OPEN_MODAL,
  modalType,
  modalTitle,
  modalData: data
});

export const closeModal = () => ({
  type: CLOSE_MODAL
});
