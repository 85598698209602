import { ADD_ALERT, REMOVE_ALERT } from '../../constants/actionTypes';

export const initState = {
  alertList: []
};

export default {
  [ADD_ALERT]: (state, { payload }) => {
    return {
      ...state,
      alertList: [payload]
    };
  },
  // eslint-disable-next-line no-unused-vars
  [REMOVE_ALERT]: (state, { payload }) => {
    return {
      ...state,
      alertList: []
      // alertList: state.alertList.slice(0, -1)
    };
  }
};
