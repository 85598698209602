// @flow
import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ShowForPermission } from '../../ShowForPermission';

const Root = styled.div`
  background: #004b87;
  padding: 0 49px;
  display: flex;
  flex-direction: row;
  height: 44px;
`;
const activeClassName = 'active';

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  &.${activeClassName} {
    border-bottom: 3px solid #f9f9fb;
    opacity: 1;
  }
  background: #004b87;
  color: #fff;
  padding: 14px 13px;
  text-decoration: none;
  opacity: 0.56;
  box-size: border-box;
`;
const Menu = ({ isMenuShown }) => {
  const [t] = useTranslation('translation');

  return (
    <Root>
      {isMenuShown && (
        <>
          <ShowForPermission contextName="Wallet" action="Read">
            <StyledNavLink activeClassName="active" to="/portal/wallets">
              {t('Wallets')}
            </StyledNavLink>
          </ShowForPermission>
          <ShowForPermission contextName="Transaction" action="Read">
            <StyledNavLink activeClassName="active" to="/portal/transactions">
              {t('Transactions')}
            </StyledNavLink>
          </ShowForPermission>
          <ShowForPermission contextName="User" action="Read">
            <StyledNavLink activeClassName="active" to="/portal/reports">
              {t('Reports')}
            </StyledNavLink>
          </ShowForPermission>
          <ShowForPermission contextName="User" action="Read">
            <StyledNavLink activeClassName="active" to="/portal/users">
              {t('Users')}
            </StyledNavLink>
          </ShowForPermission>
        </>
      )}
    </Root>
  );
};
export default Menu;
