// @flow
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Button, FormattedAmount } from '../components';
import { currencyFormat } from '../../utils/currencyFormat';
import { topUp } from '../../redux/actions/topUp';
import { closeModal } from '../../redux/actions/modals';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
const GreyText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  color: rgba(5, 34, 56, 0.56);
  flex: 1;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
`;
const Text = styled.p`
  margin: 2px;
  flex: 3;
`;
const TextBlock = styled.div`
  margin-top: 8px;
  margin-bottom: 18px;
  line-height: 1.43;
`;
const ButtonProceed = styled(Button)`
  position: absolute;
  bottom: 24px;
  left: 140px;
`;

let TopUp = props => {
  const [t] = useTranslation('translation');
  const [accountType, setAccountType] = useState('business');

  const handleTopUpFormSubmit = val => {
    const { walletId } = props.modalData;
    const { amount } = val;
    const parsedAmount = parseFloat(amount);
    const item = parsedAmount.toFixed(2);
    props.topUp(walletId, { amount: item, accountType });
    props.closeModal();
  };

  return (
    <Root>
      <TextBlock>
        <Trans i18nKey="Enter top up amount to proceed. You’ll be redirected to external payment page" />
      </TextBlock>
      <Row>
        <GreyText>{t('Wallet name')}</GreyText>
        <Text>{props.modalData ? props.modalData.walletName : ''}</Text>
      </Row>
      <Row>
        <GreyText>{t('Current balance')}</GreyText>
        <Text>
          {props.modalData
            ? currencyFormat(props.modalData.availableBalance || 0)
            : ''}
          {props.modalData ? ` ${props.modalData.currencyCode || ''}` : ''}
        </Text>
      </Row>
      <form onSubmit={props.handleSubmit(handleTopUpFormSubmit)}>
        <FormattedAmount
          name="amount"
          placeholder={t('Enter top up amount')}
          label={`${t('Top up amount')} *`}
          maxlength="19"
          required
          decimalPoints={props.decimalPoints}
          currencyCode={props.modalData.currencyCode}
          id="withdrawalAmount"
        />
        {props.modalData.currencyCode === 'MYR' && (
          <FormControl component="fieldset" name="type" id="accountType">
            <FormLabel component="legend">Account</FormLabel>
            <RadioGroup
              row
              defaultValue="business"
              value={accountType}
              onChange={(event, val) => setAccountType(val)}
            >
              <FormControlLabel
                value="business"
                control={<Radio size="small" />}
                label="Business banking"
              />
              <FormControlLabel
                value="personal"
                control={<Radio size="small" />}
                label="Personal banking"
              />
            </RadioGroup>
          </FormControl>
        )}
        <ButtonProceed
          primary
          title={t('Proceed top up')}
          type="submit"
          disabled={props.invalid}
        />
      </form>
    </Root>
  );
};

TopUp = reduxForm({
  form: 'topUp'
})(TopUp);

const mapStateToProps = state => ({
  modalData: state.modals.modalData,
  decimalPoints: state.appState.account.decimalPoints
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      topUp,
      closeModal
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TopUp);
