import {
  FETCH_REPORTS_DETAILS,
  FETCH_REPORTS_DETAILS_TRANSACTIONS
} from '../../constants/actionTypes';
import { api } from '../../services/rest';
import { getSearchTransactionsPath } from '../../utils/getSearchTransactionsPath';

export const getReportsDetails = (id, params) => dispatch => {
  dispatch({ type: FETCH_REPORTS_DETAILS.REQUEST });

  api.get(`reports/${id}?${getSearchTransactionsPath(params)}`).then(
    response => {
      if (response.responseCode === '000') {
        return dispatch({
          type: FETCH_REPORTS_DETAILS.SUCCESS,
          payload: response
        });
      }
      return dispatch({
        type: FETCH_REPORTS_DETAILS.FAILURE,
        payload: response
      });
    },
    err => dispatch({ type: FETCH_REPORTS_DETAILS.FAILURE, payload: err })
  );
};

export const getReportsDetailsTransactions = (id, params) => dispatch => {
  dispatch({ type: FETCH_REPORTS_DETAILS_TRANSACTIONS.REQUEST });

  api
    .get(`reports/${id}/transactions?${getSearchTransactionsPath(params)}`)
    .then(
      response => {
        if (response.responseCode === '000') {
          return dispatch({
            type: FETCH_REPORTS_DETAILS_TRANSACTIONS.SUCCESS,
            payload: response
          });
        }
        return dispatch({
          type: FETCH_REPORTS_DETAILS_TRANSACTIONS.FAILURE,
          payload: response
        });
      },
      err =>
        dispatch({
          type: FETCH_REPORTS_DETAILS_TRANSACTIONS.FAILURE,
          payload: err
        })
    );
};
