export const ENGLISH_LOCALE = {
  language: 'en',
  culture: 'en-GB',
  displayLanguage: 'EN',
  fullName: 'English'
};

export const THAI_LOCALE = {
  language: 'th',
  culture: 'th-TH',
  displayLanguage: 'TH',
  fullName: 'Thai'
};

export const MALAYSIAN_LOCALE = {
  language: 'ms',
  culture: 'ms-MY',
  displayLanguage: 'MY',
  fullName: 'Malaysian'
};

export const VIETNAMESE_LOCALE = {
  language: 'vi',
  culture: 'vi-VN',
  displayLanguage: 'VN',
  fullName: 'Vietnamese'
};

export const INDONESIAN_LOCALE = {
  language: 'id',
  culture: 'id-ID',
  displayLanguage: 'ID',
  fullName: 'Indonesian'
};

export const LOCALES = [
  ENGLISH_LOCALE,
  THAI_LOCALE,
  // MALAYSIAN_LOCALE, Disabled, cause the majority of Malaysians speak also English
  VIETNAMESE_LOCALE,
  INDONESIAN_LOCALE
];

export const PREFERRED_LOCALE = 'PREFERRED_LOCALE';
