import React, { useMemo } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Divider from '@material-ui/core/Divider';
import { Field, FieldArray } from 'redux-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Loader,
  Switch,
  FormattedAmount,
  FormSelect
} from '../../components';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
  flex: 1;
`;
const ButtonProceed = styled(Button)`
  position: absolute;
  bottom: 24px;
  left: 140px;
`;
const InputGlobal = createGlobalStyle`
	.smaller-input{
		margin-right: 12px;
    }
    .react-datepicker-wrapper {
        // width: 100%;
    }
    .css-1okebmr-indicatorSeparator {
        background-color: none;
    }
    .calendar {
        border: ${props =>
          props.error
            ? '2px solid #f15c5c'
            : '1px solid rgba(5, 34, 56, 0.25)'};
        border-radius: 2px;
        width: 268px;
        padding: 8px 12px;
        font-size: 14px;
        line-height: 1.71;
        background: ${props => (props.disabled ? '#f7f7fe' : '#fff')}
        &:focus {
            border: 1px solid #004b87;
        }
    }
`;
const Label = styled.span`
  font-size: 18px;
  cursor: pointer;
`;
const BlockTitle = styled(Row)`
  margin-top: 10px;
  font-size: 18px;
`;
const BlockDescription = styled(Row)`
  font-size: 13px;
`;
const WalletName = styled.h4`
  width: 350px;
  padding-top: 13px;
  margin-right: 30px;
  word-break: break-word;
`;

const Wallets = ({ fields, decimalPoints }) => {
  const [t] = useTranslation('translation');

  return (
    <div>
      {fields.map((wallet, index) => {
        return (
          <Row key={wallet}>
            <WalletName>{fields.get(index).name}</WalletName>
            <Row>
              <FormattedAmount
                name={`${wallet}.min`}
                id={wallet.walletId}
                placeholder={t('Enter min')}
                label={t('Min')}
                decimalPoints={decimalPoints}
              />
            </Row>
          </Row>
        );
      })}
    </div>
  );
};

const AlertSettingsForm = props => {
  const {
    isLoading,
    pristine,
    handleSubmit,
    saveData,
    submitting,
    emails,
    isEmailsLoading,
    decimalPoints
  } = props;
  const [t] = useTranslation('translation');
  const options = useMemo(
    () =>
      emails.map(({ email }) => ({
        value: email,
        label: email
      })),
    [emails]
  );

  return (
    <Root>
      <InputGlobal />
      <form style={{ position: 'relative' }}>
        {(isLoading || isEmailsLoading) && <Loader absolute />}
        <Row>
          <Field
            name="isEnabled"
            component={Switch}
            label={<Label>{t('Enable notification')}</Label>}
          />
        </Row>
        <Row>
          <Field
            name="emails"
            id="emails"
            component={FormSelect}
            placeholder={t('Enter emails')}
            className="smaller-input"
            label={<Label>{t('Notify emails')} *</Label>}
            labelOpacity={1}
            helpText={t('Notification will send to these email addresses.')}
            helpTextColor="rgba(5, 34, 56, 0.87)"
            helpTextFontSize={13}
            options={options}
            isMulti
          />
        </Row>
        <BlockTitle>{t('IEP Account Balance')}</BlockTitle>
        <BlockDescription>
          {t(
            'Sum of all wallets blance to be monitored. Balance not within range will trigger notification.'
          )}
        </BlockDescription>
        <Row>
          <FormattedAmount
            name="iepAccountBalanceMin"
            id="iepAccountBalanceMin"
            placeholder={t('Enter min')}
            label={t('Min')}
            decimalPoints={decimalPoints}
          />
        </Row>

        <BlockTitle>{t('Wallet balance')}</BlockTitle>
        <BlockDescription>
          {t(
            'Individual wallet balance to be monitored. Each balance not within range will trigger notification'
          )}
        </BlockDescription>
        <Divider />
        <FieldArray
          name="wallets"
          component={Wallets}
          decimalPoints={decimalPoints}
        />
      </form>
      <ButtonProceed
        primary
        title={t('Save')}
        onClick={handleSubmit(saveData)}
        disabled={pristine || submitting}
      />
    </Root>
  );
};

export default AlertSettingsForm;
