import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Chart from './Chart';
import { getChartReports } from '../../../redux/actions/reports';

const mapStateToProps = state => ({
  chartReports: state.reports.chartReports,
  isLoading: state.reports.isLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getChartReports
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
