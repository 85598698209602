/* eslint-disable no-else-return */
import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { DropArrow, LastPage, Download } from '../../icons';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const RowPages = styled.div`
  display: flex;
  flex-direction: row;
`;
const Root = styled(Row)`
  border-top: 1px solid rgba(5, 34, 56, 0.12);
  padding: 15px;
`;
const PrevPage = styled(DropArrow)`
  background: transparent;
  color: #0080ff;
  transform: rotate(270deg);
`;
const NextPage = styled(PrevPage)`
  transform: rotate(90deg);
`;
const FirstPageIcon = styled(LastPage)`
  background: transparent;
  color: #0080ff;
`;
const LastPageIcon = styled(FirstPageIcon)`
  transform: rotate(180deg);
`;
const Label = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.3px;
  color: rgba(5, 34, 56, 0.56);
`;
const Select = styled.select`
  border-color: #fff;
  margin-right: 4px;
`;
const PageNumberBlock = styled(Row)`
  padding: 0 15px;
`;
const NumbersSpan = styled.div`
  color: rgba(5, 34, 56, 0.56);
  font-size: 12px;
  margin: 0 5px;
`;
const ExportLink = styled.a`
  font-size: 14px;
  color: #004b87;
  border: none;
  outline: none;
  background: #fff;
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;
// eslint-disable-next-line react/button-has-type
const defaultButton = props => <button {...props}>{props.children}</button>;

class TableFooter extends React.Component {
  constructor(props) {
    super();

    this.changePage = this.changePage.bind(this);

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages)
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages)
      });
    }

    this.changePage(nextProps.page + 1);
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      }
      if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  generateLengthDataText = t => {
    const { filtered, pageSize, sortedData, page, pageInfo } = this.props;
    let generatedText = '';
    if (sortedData && pageInfo && sortedData.length > 0) {
      const isFiltered = filtered.length > 0;
      const { totalRecord } = pageInfo;
      const recordsCountFrom = page * pageSize + 1;
      const maxRecordsCountPerPage = recordsCountFrom + pageSize - 1;
      const recordsCountTo =
        totalRecord < pageSize || maxRecordsCountPerPage > totalRecord
          ? totalRecord
          : maxRecordsCountPerPage;

      if (isFiltered) {
        generatedText = `${recordsCountFrom}-${recordsCountTo} ${t(
          'Of'
        )} ${totalRecord} ${t('filtered items')}`;
      } else {
        generatedText = (
          <PageNumberBlock>
            {recordsCountFrom}-{recordsCountTo}
            <NumbersSpan> {this.props.t('Of')} </NumbersSpan>
            {totalRecord}
            <NumbersSpan> {this.props.t('Items')}</NumbersSpan>
          </PageNumberBlock>
        );
      }
    } else generatedText = t('No Items');

    return generatedText;
  };

  changePages = e => {
    this.props.onPageSizeChange(Number(e.target.value));
  };

  changePage = page => {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    });

    this.props.onPageChange(page - 1);
  };

  render() {
    const {
      PageButtonComponent = defaultButton,
      showPageSizeOptions,
      pageSize,
      pageSizeOptions
    } = this.props;
    const { visiblePages } = this.state;
    const activePage = this.props.page + 1;

    return (
      <Root>
        <RowPages>
          <div>
            <PageButtonComponent
              onClick={() => {
                if (activePage === 1) return;
                this.changePage(1);
              }}
              className="table-page-item"
              disabled={activePage === 1}
            >
              <FirstPageIcon />
            </PageButtonComponent>
          </div>
          <div className="Table__prevPageWrapper">
            <PageButtonComponent
              onClick={() => {
                if (activePage === 1) return;
                this.changePage(activePage - 1);
              }}
              className="table-page-item"
              disabled={activePage === 1}
            >
              <PrevPage />
            </PageButtonComponent>
          </div>
          <div className="Table__visiblePagesWrapper">
            {visiblePages.map((page, index, array) => {
              return (
                <PageButtonComponent
                  key={`...${page}`}
                  style={{
                    backgroundColor: '#fff',
                    border: 'none',
                    outline: 'none',
                    cursor: 'pointer',
                    color: `${
                      activePage === page ? '#0080ff' : 'rgba(5, 34, 56, 0.87)'
                    }`
                  }}
                  onClick={() => this.changePage(page)}
                >
                  {array[index - 1] + 2 < page ? `...${page}` : page}
                </PageButtonComponent>
              );
            })}
          </div>
          <div className="Table__nextPageWrapper">
            <PageButtonComponent
              onClick={() => {
                if (activePage === this.props.pages) return;
                this.changePage(activePage + 1);
              }}
              className="table-page-item"
              disabled={activePage === this.props.pages || !this.props.pages}
            >
              <NextPage />
            </PageButtonComponent>
          </div>
          <div>
            <PageButtonComponent
              onClick={() => {
                if (activePage === this.props.pages) return;
                this.changePage(this.props.pages);
              }}
              className="table-page-item"
              disabled={activePage === this.props.pages || !this.props.pages}
            >
              <LastPageIcon />
            </PageButtonComponent>
          </div>
        </RowPages>

        <Row>
          {showPageSizeOptions && (
            <span className="select-wrap -pageSizeOptions">
              <Select onChange={this.changePages} value={pageSize}>
                {pageSizeOptions.map((option, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
              <Label style={{ marginRight: 5 }}>
                {this.props.t('Items per page')}
              </Label>
            </span>
          )}
          <Row>{this.generateLengthDataText(this.props.t)}</Row>
          {this.props.exportUrl && (
            <ExportLink
              href={this.props.exportUrl}
              disabled
              target="_blank"
              rel="noopener noreferrer"
            >
              <Download /> {this.props.t('Export')}
            </ExportLink>
          )}
        </Row>
      </Root>
    );
  }
}

export default withTranslation('translation')(TableFooter);
