import React from 'react';
import ReactTable from 'react-table';
import { useTranslation } from 'react-i18next';
import TableFooter from '../../components/Table/TableFooter';
import {
  SET_REPORTS_DETAILS_PAGE,
  SET_REPORTS_DETAILS_PAGE_SIZE
} from '../../../constants/actionTypes';
import SubHeaderTable from '../../Reports/components/SubHeaderTable';
import SubHeaderTotal from '../../Reports/components/SubHeaderTotal';
import NoRowsFound from '../../components/NoRowsTable';

const Refund = props => {
  const [t] = useTranslation('translation');
  return (
    <div>
      <SubHeaderTable>
        <SubHeaderTotal
          totalName={t('Total Refund')}
          totalAmount={props.totalAmount}
        />
      </SubHeaderTable>
      <ReactTable
        PaginationComponent={val => (
          <TableFooter {...props} {...val} exportUrl={props.exportUrl} />
        )}
        NoDataComponent={() => <NoRowsFound style={{ top: '38%' }} />}
        data={props.data}
        columns={props.columns}
        defaultPageSize={10}
        pageSize={props.pageSize}
        filterable={props.isFilterable}
        sortable={false}
        page={props.page}
        loading={props.loading}
        manual
        pageInfo={props.pageInfo}
        onPageSizeChange={pageSize =>
          props.setPageSize(pageSize, SET_REPORTS_DETAILS_PAGE_SIZE)
        }
        onPageChange={pageIndex => {
          props.setPage(pageIndex, SET_REPORTS_DETAILS_PAGE);
        }}
        pages={props.data && props.pageInfo && props.pageInfo.totalPage}
        minRows={1}
        className="-highlight"
      />
    </div>
  );
};

export default Refund;
