// @flow
import React from 'react';
import styled from 'styled-components';
import { compose } from 'ramda';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { Button, FormattedAmount } from '../../components';
import { withdraw } from '../../../redux/actions/wallets';
import { closeModal } from '../../../redux/actions/modals';
import { currencyFormat } from '../../../utils/currencyFormat';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
const GreyText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  color: rgba(5, 34, 56, 0.56);
  flex: 2;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
`;
const Text = styled.p`
  margin: 2px;
  flex: 3;
`;
const TextBlock = styled.div`
  margin-top: 8px;
  margin-bottom: 18px;
  line-height: 1.43;
`;
const ButtonProceed = styled(Button)`
  position: absolute;
  bottom: 24px;
  left: 140px;
`;
const NotificationRed = styled.div`
  color: red;
  font-size: 11px;
  display: flex;
  flex: 1;
  align-self: center;
  margin-top: 30px;
`;

const validate = values => {
  const errors = {};
  if (
    !values.amount ||
    values.amount.trim().length === 0 ||
    Number.isNaN(Number(values.amount))
  ) {
    errors.amount = 'Incorrect';
  }
  return errors;
};

const Withdraw = props => {
  const [t] = useTranslation('translation');
  const handleTopUpFormSubmit = val => {
    const { walletId } = props.modalData;
    const data = {
      amount: parseFloat(val.amount)
    };
    props.withdraw(walletId, data);
    props.closeModal();
  };
  const totalAmount =
    parseFloat(props.withdrawAmount) - props.modalData.numericWithdrawalFee;

  return (
    <Root>
      <TextBlock>
        {t('Withdraw amount will be transferred in 5 working days')}{' '}
        {props.modalData.currencyCode}
      </TextBlock>
      <Row>
        <GreyText>Withdrawal bank account</GreyText>
        <Text>
          {props.modalData ? props.modalData.bankAccount.number : '-'}
        </Text>
      </Row>
      <Row>
        <GreyText>{t('Balance')}</GreyText>
        <Text>
          {props.modalData ? props.modalData.availableBalance : '-'}{' '}
          {props.modalData.currencyCode}
        </Text>
      </Row>
      <form onSubmit={props.handleSubmit(handleTopUpFormSubmit)}>
        <Row>
          <FormattedAmount
            name="amount"
            required
            placeholder={t('Enter withdraw amount')}
            label={`${t('Withdraw amount')} *`}
            maxlength="20"
            decimalPoints={props.decimalPoints}
            currencyCode={props.modalData.currencyCode}
            id="withdrawalAmount"
          />
          <NotificationRed>
            * {t('Withdrawal fee')}: {props.modalData.withdrawalFee}{' '}
            {props.modalData.currencyCode}
          </NotificationRed>
        </Row>
        <p>
          {t('Total amount to be paid to bank account')}:{' '}
          {props.withdrawAmount
            ? currencyFormat(totalAmount, props.decimalPoints)
            : 0}{' '}
          {props.modalData.currencyCode}
        </p>
        <ButtonProceed
          primary
          title={t('Request withdrawal')}
          type="submit"
          disabled={props.invalid}
        />
      </form>
    </Root>
  );
};

const mapStateToProps = state => ({
  modalData: state.modals.modalData,
  decimalPoints: state.appState.account.decimalPoints,
  withdrawAmount:
    (state.form.withdraw &&
      state.form.withdraw.values &&
      state.form.withdraw.values.amount) ||
    0
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      withdraw,
      closeModal
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'withdraw',
    validate
  })
)(Withdraw);
