import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { hideMenu as hideMenuAction } from '../redux/actions/appState';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;
const Header = styled.h2`
  font-size: 30px;
`;
const GreyText = styled.div`
  text-align: center;
  font-size: 18px;
  color: #ccc;
  border-top: 1px solid #ccc;
  > p {
    margin: 10px 0;
  }
`;

const AccessDenied = ({ hideMenu }) => {
  const [t] = useTranslation('');

  useEffect(() => {
    hideMenu();
  }, [hideMenu]);

  return (
    <Root>
      <Header>{t('Access denied')}</Header>
      <GreyText>
        <p>{t('You do not have permission to access this page')} </p>
        <p>{t('Please contact your Administrator(s) to request access')}</p>
      </GreyText>
    </Root>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      hideMenu: hideMenuAction
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AccessDenied);
