/* eslint-disable no-nested-ternary */
// @flow
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import '../../styles/react-table.css';
import { PageHeader } from '../components';
import Modal, { ConfirmModal } from '../components/Modal';
import { Back } from '../icons';
import RegisterBank from './BankAccounts/RegisterBank';
import DeleteBankAccount from './DeleteBankAccount';
import { ShowForPermission } from '../ShowForPermission';
import BankAccounts from './BankAccounts';
import Agencies from './Agencies';
import { Row } from './Agencies/Agencies';
import AlertSettingsForm from './AlertSettingsForm';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const BackButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
`;

const Settings = props => {
  const [t] = useTranslation('translation');
  return (
    <Root>
      <>
        <Row>
          <PageHeader>
            <BackButton onClick={() => props.history.goBack()}>
              <Back />
            </BackButton>
            {t('Settings')}
          </PageHeader>
        </Row>
        <ShowForPermission contextName="BankAccount" action="Read">
          <BankAccounts />
        </ShowForPermission>

        <ShowForPermission contextName="Agency" action="Read">
          <Agencies />
        </ShowForPermission>
        <Modal
          open={props.isModalOpen}
          title={props.modalTitle}
          closeModal={props.closeModal}
        >
          {props.modalType === 'deleteBank' ? (
            <DeleteBankAccount
              closeModal={props.closeModal}
              changeBankAccountStatus={props.deleteBankAccount}
            />
          ) : props.modalType === 'confirmModal' ? (
            <ConfirmModal {...props.modalData} />
          ) : props.modalType === 'alertSettings' ? (
            <AlertSettingsForm />
          ) : (
            <RegisterBank
              submitMethod={props.addBankAccount}
              closeModal={props.closeModal}
            />
          )}
        </Modal>
      </>
    </Root>
  );
};

export default Settings;
