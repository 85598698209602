import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SubHeaderTable from './SubHeaderTable';
import TableFooter from '../../components/Table/TableFooter';
import {
  FREQUENCY_DAILY,
  FREQUENCY_MONTHLY,
  FREQUENCY_WEEKLY,
  PERIODIOCITY
} from '../ReportsConstants';
import {
  formatWeeklyDailyPeriod,
  formatMonthlyPeriod
} from '../../../utils/formatReportsPeriod';
import { Loader } from '../../components';
import {
  SET_REPORTS_TABLE_PAGE_SIZE,
  SET_REPORTS_TABLE_PAGE
} from '../../../constants/actionTypes';
import {
  TabsGlobal,
  TabBlock,
  a11yProps,
  statusKindMap
} from '../../ReportDetails/ReportDetailsRouter';
import SubHeaderTotal from './SubHeaderTotal';
import { getSearchTransactionsPath } from '../../../utils/getSearchTransactionsPath';
import NoRowsFound from '../../components/NoRowsTable';
import { useQueryParams } from '../../../HOOKS';
import { getActiveTabIndex } from '../../../utils';

const columns = t => [
  {
    Header: t('Transactions period'),
    accessor: 'transactionsPeriod'
  },
  {
    Header: t('IATA Code'),
    accessor: 'iataCode'
  },
  {
    Header: t('Wallet'),
    accessor: 'walletName'
  },
  {
    Header: t('Transactions'),
    accessor: 'transactions',
    Cell: row => <div>{row.value}</div>
  },
  {
    Header: t('Total amount'),
    accessor: 'totalAmount',
    Cell: row => <div>{row.value}</div>
  },
  {
    Header: t('Currency'),
    accessor: 'currency'
  }
];

const formatTransactionPeriod = (report, period) => {
  switch (period) {
    case FREQUENCY_MONTHLY: {
      return {
        ...report,
        transactionsPeriod: formatMonthlyPeriod(report.startDate)
      };
    }
    case FREQUENCY_WEEKLY:
    case FREQUENCY_DAILY: {
      return {
        ...report,
        transactionsPeriod: formatWeeklyDailyPeriod(report)
      };
    }
    default: {
      return null;
    }
  }
};
export const getFormattedReports = (reports = [], period) =>
  reports.map(report => formatTransactionPeriod(report, period));
const tabLabels = ['Settlement', 'Refund'];

const Table = props => {
  const [t] = useTranslation('translation');
  const [setCombinedParams, queryParams] = useQueryParams({
    tabName: tabLabels[0]
  });
  const [value, setValue] = useState(
    getActiveTabIndex(tabLabels, queryParams.tabName)
  );

  useEffect(() => {
    props.getReports({
      AgencyId: props.iataCode.id,
      WalletId: props.walletId,
      ...props.customRange,
      Periodicity: PERIODIOCITY[props.period],
      PageSize: props.pageSize,
      PageNo: props.page + 1,
      StatusKind: statusKindMap[value]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.iataCode.id,
    props.walletId,
    props.period,
    props.customRange,
    props.page,
    props.pageSize,
    value
  ]);

  const handleChange = (event, tabIndex) => {
    setValue(tabIndex);
    setCombinedParams({ tabName: tabLabels[tabIndex] });
    props.setPageSize(10, SET_REPORTS_TABLE_PAGE_SIZE);
    props.setPage(0, SET_REPORTS_TABLE_PAGE);
  };
  const navigateToReport = row => {
    props.history.push(
      `/portal/reports/reportsDetails/${row.id}?period=${
        PERIODIOCITY[props.period]
      }&statusKind=${statusKindMap[value]}`
    );
  };

  return props.isLoading || !props.reports ? (
    <Loader />
  ) : (
    <div>
      <SubHeaderTable>
        <SubHeaderTotal
          totalName={t('Total period transactions')}
          totalAmount={props.pagedTotal.totalTransactions}
        />
        <SubHeaderTotal
          totalName={t('Total period amount')}
          totalAmount={`${props.pagedTotal.totalAmount} ${props.pagedTotal.currency}`}
        />
      </SubHeaderTable>
      <TabsGlobal />
      <TabBlock style={{ margin: '20px 0', justifyContent: 'flex-end' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="reportDetailsTab"
        >
          {tabLabels.map((label, index) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Tab key={label} label={label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </TabBlock>
      <ReactTable
        PaginationComponent={val => (
          <TableFooter
            {...props}
            {...val}
            exportUrl={`/v1/reports/excel?${getSearchTransactionsPath({
              AgencyId: props.iataCode.id,
              WalletId: props.walletId,
              ...props.customRange,
              Periodicity: PERIODIOCITY[props.period],
              StatusKind: statusKindMap[value]
            })}`}
          />
        )}
        NoDataComponent={() => <NoRowsFound style={{ top: '38%' }} />}
        data={getFormattedReports(props.reports, props.period)}
        columns={columns(t)}
        defaultPageSize={10}
        pageSize={props.pageSize}
        filterable={props.isFilterable}
        sortable={false}
        page={props.page}
        loading={props.isLoading}
        manual
        pageInfo={props.pageInfo}
        getTdProps={(state, rowInfo) => ({
          onClick: rowInfo ? () => navigateToReport(rowInfo.original) : null
        })}
        pages={props.reports && props.pageInfo && props.pageInfo.totalPage}
        onPageSizeChange={pageSize =>
          props.setPageSize(pageSize, SET_REPORTS_TABLE_PAGE_SIZE)
        }
        onPageChange={pageIndex => {
          props.setPage(pageIndex, SET_REPORTS_TABLE_PAGE);
        }}
        minRows={1}
        className="-highlight transactions"
      />
    </div>
  );
};

export default withRouter(Table);
