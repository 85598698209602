import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeLocale } from '../../../redux/actions/appState';
import TopNav from './TopNav';
import { loginInternalSelector } from '../../../redux/reducers/loginInternal';
import { logoutInternalRequest as logoutInternalRequestAction } from '../../../redux/actions/loginInternal';

const mapStateToProps = state => ({
  iataCode: loginInternalSelector(state).iataCode,
  selectedLocale: state.appState.locale,
  account: state.appState.account
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeLocale,
      logoutInternalRequest: logoutInternalRequestAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
