import {
  INIT_APP,
  ENABLE_COOKIE,
  TERMS_CONDITIONS_DOWNLOADED,
  SET_ACCOUNT_CREATING_STATUS,
  SET_ACCOUNT_CREATING_STATUS_CREATED,
  HIDE_MENU,
  SHOW_MENU,
  CHANGE_LOCALE
} from '../../constants/actionTypes';
import {
  PREFERRED_LOCALE,
  ENGLISH_LOCALE
} from '../../constants/LocalizationConstants';

export const initState = {
  isMenuShown: false,
  account: {},
  isCookieEnabled: false,
  termsConditionsDownloaded: false,
  accountCreatingStatus: 'notCreated',
  errors: null,
  isLoading: false,
  locale: ENGLISH_LOCALE
};

const setLocale = agentLocale => {
  const preferredLanguage = JSON.parse(localStorage.getItem(PREFERRED_LOCALE));
  if (
    !preferredLanguage ||
    (preferredLanguage.language !== agentLocale.language &&
      preferredLanguage.language !== ENGLISH_LOCALE.language)
  ) {
    localStorage.setItem(PREFERRED_LOCALE, JSON.stringify(agentLocale));
    return agentLocale;
  }
  return preferredLanguage || ENGLISH_LOCALE;
};

export default {
  [INIT_APP.REQUEST]: state => {
    return {
      ...state
    };
  },
  [INIT_APP.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      account: payload.data,
      errors: null,
      isLoading: false,
      locale: setLocale(payload.data.agentCulture)
    };
  },
  [INIT_APP.FAILURE]: (state, { code }) => {
    return {
      ...state,
      errors: code
    };
  },
  [ENABLE_COOKIE]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
      isCookieEnabled: true,
      errors: null
    };
  },
  [TERMS_CONDITIONS_DOWNLOADED]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
      termsConditionsDownloaded: true,
      errors: null
    };
  },
  [SET_ACCOUNT_CREATING_STATUS.REQUEST]: state => {
    return {
      ...state,
      errors: null,
      isLoading: true
    };
  },
  [SET_ACCOUNT_CREATING_STATUS.SUCCESS]: state => {
    return {
      ...state,
      accountCreatingStatus: 'pending',
      errors: null,
      isLoading: false
    };
  },
  [SET_ACCOUNT_CREATING_STATUS.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      errors: payload.message,
      isLoading: false
    };
  },
  [SET_ACCOUNT_CREATING_STATUS_CREATED]: state => {
    return {
      ...state,
      accountCreatingStatus: 'created',
      errors: null
    };
  },
  [HIDE_MENU]: state => {
    return {
      ...state,
      isMenuShown: false
    };
  },
  [SHOW_MENU]: state => {
    return {
      ...state,
      isMenuShown: true
    };
  },
  [CHANGE_LOCALE]: (state, { locale }) => {
    return {
      ...state,
      locale
    };
  }
};
