import React from 'react';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const SwitchBlock = styled.div`
  & .MuiFormControlLabel-labelPlacementStart {
    margin-left: 0;
  }
`;

const SwitchInput = props => {
  const {
    label,
    input: { onChange, name, value }
  } = props;

  return (
    <SwitchBlock>
      <FormControlLabel
        control={
          <Switch
            checked={!!value}
            name={name}
            onChange={event => onChange(event.target.checked)}
            color="primary"
          />
        }
        label={label}
        labelPlacement="start"
      />
    </SwitchBlock>
  );
};
export default SwitchInput;
