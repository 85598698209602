import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getReportsDetails } from '../../redux/actions/reportsDetails';
import ReportDetails from './ReportsDetails';

const mapStateToProps = state => ({
  reportsDetails: state.reportsDetails.reportsDetails
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getReportsDetails
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetails);
