import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import wrapper from './AlertSettingsFormWrapper';
import { closeModal } from '../../../redux/actions/modals';
import { addAlert } from '../../../redux/actions/alerts';

const validate = values => {
  const errors = {};
  const { emails } = values;

  if (!emails || !Array.isArray(emails) || emails.length === 0) {
    errors.emails = `Field is required.`;
  }

  return errors;
};

const formCreator = reduxForm({
  form: 'alertSettings',
  validate,
  enableReinitialize: true,
  destroyOnUnmount: true
});

const mapStateToProps = ({ modals, appState }) => ({
  ...modals,
  decimalPoints: appState.account.decimalPoints
});
const mapDispatchToProps = {
  closeModal,
  addAlert
};

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);

export default compose(reduxConnector, wrapper, formCreator);
