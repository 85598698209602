// @flow
import React from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { compose } from 'ramda';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Select from 'react-select';
import get from 'lodash.get';
import { withTranslation } from 'react-i18next';
import { Button, Loader, ListCheckboxes } from '../components';
import { closeModal } from '../../redux/actions/modals';
import {
  getAccessibleRoles,
  getUserDetailsApproved,
  getUserDataForEditing
} from '../../redux/actions/usersApproved';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
`;

const TextBlock = styled.div`
  margin-top: 8px;
  margin-bottom: 18px;
  line-height: 1.43;
`;
const ButtonProceed = styled(Button)`
  position: absolute;
  bottom: 24px;
  left: 140px;
`;
const Text = styled.p`
  margin: 2px;
  flex: 4;
`;
const GreyText = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  color: rgba(5, 34, 56, 0.56);
  margin-right: 20px;
`;
const StyledForm = styled.form`
  min-height: ${({ isMenuOpened, minHeight }) =>
    isMenuOpened ? `${minHeight}px` : 'auto'};
`;
const Label = styled.p`
  color: rgba(5, 34, 56, 0.87);
  font-size: 12px;
  margin-bottom: 5px;
  opacity: 0.56;
`;
const GreyTextSmall = styled(Text)`
  flex: 2;
  color: rgba(5, 34, 56, 0.56);
  font-size: 12px;
`;
const GreyTextCurrency = styled(GreyTextSmall)`
  flex: 3;
`;

const renderSelect = props => {
  const {
    input,
    meta: { touched, error },
    label,
    placeholder,
    setIsMenuOpened,
    options
  } = props;

  return (
    <div>
      <Label>{label}</Label>
      <Select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        isSearchable={false}
        placeholder={placeholder}
        error={!touched && error}
        options={options}
        onBlur={() => {
          input.onBlur(input.value);
        }}
        onMenuOpen={() => setIsMenuOpened(true)}
        onMenuClose={() => setIsMenuOpened(false)}
      />
    </div>
  );
};
const validate = values => {
  const errors = {};

  if (!values.userRole) {
    errors.userRole = 'Incorrect';
  }

  if (values.userRole && values.userRole.value === 3 && values.agencyIds < 1) {
    errors.agencyIds = 'Field is required';
  }

  return errors;
};

// eslint-disable-next-line react/jsx-props-no-spreading
const SelectField = props => <Field component={renderSelect} {...props} />;

const roleEnam = [
  { id: 1, name: 'IEP Admin' },
  { id: 2, name: 'Master Wallet Manager' },
  { id: 3, name: 'Sub Wallet Manager' },
  { id: 4, name: 'Ticket Issuer' },
  { id: 5, name: 'Agency Read Only' },
  { id: 6, name: 'CS Agent' }
];

const allowedEditWalletRoles = [3, 4, 5];

class EditUser extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    userRoleId: this.props.currentRole,
    isSaving: false
  };

  componentDidMount() {
    this.props.getUserDetailsApproved(this.props.user.id);
    this.props.getUserDataForEditing(this.props.user.id);
    this.props.getAccessibleRoles();
  }

  static getDerivedStateFromProps(props, state) {
    // Store prevId in state so we can compare when props change.
    // Clear out previously-loaded data (so we don't render stale stuff).
    const userRoleId = props.currentRole;

    if (userRoleId && userRoleId !== state.userRoleId) {
      return {
        userRoleId
      };
    }

    // No state update necessary
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.userRoleId !== this.state.userRoleId) {
      this.props.getUserDataForEditing(
        this.props.user.id,
        this.state.userRoleId
      );
    }
  }

  handleEditUserFormSubmit = val => {
    const availableWallets = get(this.props, 'userData.wallets', []);
    const walletIds =
      val.userRole.value === 3 ||
      val.userRole.value === 4 ||
      val.userRole.value === 5
        ? availableWallets
            .filter(({ id }) => val.walletIds.some(i => i === id))
            .map(({ id }) => id)
        : [];

    // const agencies = get(this.props, 'userData.agencies', []);
    const agencyIds = [];
    const dataToAPI = {
      role: val.userRole.value,
      walletIds,
      agencyIds,
      isUserApproval: false
    };

    this.setState({ isSaving: true });
    this.props
      .saveUser(this.props.user.id, dataToAPI, false)
      .then(this.props.closeModal)
      .catch(this.props.closeModal);
  };

  setIsMenuOpened = isMenuOpened => {
    this.setState({ isMenuOpened });
  };

  render() {
    const roleOptions = this.props.accessibleRoles.map(role => {
      const { name } = roleEnam.find(i => i.id === role);
      return { value: role, label: name };
    });

    console.warn(this.props);
    return (
      <Root>
        {this.state.isSaving && <Loader absolute />}
        <TextBlock>
          {this.props.t(
            'You can edit only user role and assigned wallets/agencies'
          )}
        </TextBlock>
        <Row>
          <GreyText>{this.props.t('User')}</GreyText>
          <Text>{this.props.user.name}</Text>
        </Row>
        <Row>
          <GreyText>{this.props.t('Email')}</GreyText>
          <Text>{this.props.user.email}</Text>
        </Row>
        <Row>
          <GreyText>{this.props.t('IATA Code')}</GreyText>
          <Text>{this.props.user.agentCode}</Text>
        </Row>
        <StyledForm
          isMenuOpened={this.state.isMenuOpened}
          minHeight={roleOptions.length * 50}
          onSubmit={this.props.handleSubmit(this.handleEditUserFormSubmit)}
        >
          <SelectField
            name="userRole"
            options={roleOptions}
            label={this.props.t('User role')}
            placeholder="Select user role"
            setIsMenuOpened={this.setIsMenuOpened}
          />
          {allowedEditWalletRoles.includes(this.state.userRoleId) && (
            <Field
              name="walletIds"
              label={
                <>
                  <GreyTextSmall>
                    {this.props.t('Assigned wallet')}
                  </GreyTextSmall>
                  <GreyTextCurrency>
                    {this.props.t('Currency')}
                  </GreyTextCurrency>
                </>
              }
              component={ListCheckboxes}
              isLoading={this.props.userData.isLoading}
              selectIfOne
              labelName="name"
              valueName="id"
              keyName="id"
              additionalValueName="currency"
              options={get(this.props, 'userData.wallets', [])}
            />
          )}

          {/*
          TODO: investigate if we need this field
          {this.state.userRoleId === 3 && (
            <Field
              name="agencyIds"
              id="agencyIds"
              label={
                <GreyTextSmall>
                  {this.props.t('List of IATA codes')}
                </GreyTextSmall>
              }
              component={ListCheckboxes}
              isLoading={this.props.userData.isLoading}
              selectIfOne
              labelName="agentCode"
              valueName="agentCode"
              keyName="agencyId"
              options={get(this.props, 'userData.agencies', [])}
            />
          )} */}
          <ButtonProceed
            primary
            title={this.props.t('Save changes')}
            type="submit"
            disabled={this.props.invalid}
          />
        </StyledForm>
      </Root>
    );
  }
}

const mapStateToProps = state => {
  const userRole =
    get(state, 'form.editUser.values.userRole.value') ||
    get(state, 'usersApproved.currentUser.role');

  return {
    modalData: state.modals.modalData,
    accessibleRoles: state.usersApproved.accessibleRoles,
    accessibleAgencies: state.agencies.accessibleAgencies,
    user: state.usersApproved.currentUser,
    currentRole: userRole,
    userData: state.usersApproved.userData,
    initialValues: {
      agencyIds: get(state, 'usersApproved.currentUser.agencies', []).map(
        ({ agentCode }) => agentCode
      ),
      walletIds: get(state, 'usersApproved.userData.wallets', [])
        .filter(({ isAssigned }) => isAssigned)
        .map(({ id }) => id),
      userRole: {
        value: userRole,
        label: roleEnam.find(i => i.id === userRole).name
      }
    }
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeModal,
      getAccessibleRoles,
      getUserDetailsApproved,
      getUserDataForEditing
    },
    dispatch
  );

const formCreator = reduxForm(
  {
    form: 'editUser',
    validate,
    enableReinitialize: true,
    destroyOnUnmount: true
  },
  mapStateToProps
);

export default compose(
  withTranslation('translation'),
  connect(mapStateToProps, mapDispatchToProps),
  formCreator
)(EditUser);
