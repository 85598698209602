import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AccessRequest from './AccessRequest';
import { getUsersPending } from '../../../redux/actions/usersPending';

const mapStateToProps = state => ({
  users: state.usersPending.users,
  pageInfo: state.usersPending.pageInfo,
  isLoading: state.usersPending.isLoading
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUsersPending
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(AccessRequest);
