/* eslint-disable react/no-unescaped-entities */
// @flow
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '../components';

const ButtonBlock = styled.div`
  position: absolute;
  bottom: 25px;
  left: 140px;
`;
const Root = styled.div`
  margin-top: 5px;
`;
const Text = styled.p`
  margin: 5px 0;
`;

const DeleteBankAccount = props => {
  const [t] = useTranslation('translation');

  const removeBank = () => {
    props.changeBankAccountStatus(props.modalData.id);
    props.closeModal();
  };
  return (
    <>
      <Root>
        <Text>
          {t('Are you sure you want to delete Bank account', {
            number: props.modalData.number
          })}
        </Text>
      </Root>
      <ButtonBlock>
        <Button destructive title={t('Yes, delete')} onClick={removeBank} />
      </ButtonBlock>
    </>
  );
};

const mapStateToProps = state => ({
  modalData: state.modals.modalData
});

export default connect(mapStateToProps, null)(DeleteBankAccount);
