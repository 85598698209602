import React from 'react';

const Alert = ({ className, viewBox }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      className={className}
      viewBox={viewBox}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h32v32H0z" />
        <path
          fill="#052238"
          fillOpacity=".87"
          fillRule="nonzero"
          d="M30.736 26.159L17.574 3.906A1.831 1.831 0 0 0 16 3c-.644 0-1.242.344-1.574.906L1.264 26.16a1.905 1.905 0 0 0-.026 1.889c.326.588.937.952 1.6.952h26.324c.663 0 1.274-.364 1.6-.952a1.905 1.905 0 0 0-.026-1.89zM15.51 11c.807 0 1.49.47 1.49 1.305 0 2.547-.29 6.207-.29 8.753 0 .664-.703.942-1.2.942-.662 0-1.22-.278-1.22-.942 0-2.546-.29-6.206-.29-8.753 0-.834.662-1.305 1.51-1.305zm0 15c-.863 0-1.51-.701-1.51-1.5 0-.818.647-1.5 1.51-1.5.804 0 1.49.682 1.49 1.5 0 .799-.686 1.5-1.49 1.5z"
        />
      </g>
    </svg>
  );
};
export default Alert;
