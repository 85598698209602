/* eslint-disable no-nested-ternary */
// @flow
import React, { useRef } from 'react';
import ReactTable from 'react-table';
import * as moment from 'moment';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import TableFooter from '../../components/Table/TableFooter';
import {
  transactionStatus,
  transactionStatusColor
} from '../../../utils/transactionStatus';
import { getTransactionType } from '../../../utils/getTransactionType';
import { Calendar } from '../../icons';
import NoRowsFound from '../../components/NoRowsTable';

const StatusLabel = styled.div`
  display: inline-flex;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: 0.2px;
  padding: 2px 4px;
  border-radius: 2px;
  color: ${props =>
    props.status === 'green'
      ? '#00b326'
      : props.status === 'red'
      ? 'rgba(233, 20, 20, 0.87)'
      : '#fac832'};
  background: ${props =>
    props.status === 'green'
      ? 'rgba(0,179,38, 0.15)'
      : props.status === 'red'
      ? 'rgba(233, 20, 20, 0.15)'
      : 'rgba(250,200,50,0.15)'};
`;
const Amount = styled.div`
  text-align: right;
  color: ${props => (props.debit ? '#00b326' : 'rgba(233, 20, 20, 0.87)')};
`;
const IconBlock = styled.div`
  position: absolute;
  margin-top: 7px;
  margin-left: 5px;
`;
const DatePickerBlock = styled.div`
  display: flex;
  flex-direction: row;
`;

const TransactionTable = props => {
  const currentPageRef = useRef(null);
  const currentPageSizeRef = useRef(null);

  const [t] = useTranslation('translation');
  const [onFilter] = useDebouncedCallback((filterable, page, pageSize) => {
    props.getWalletTransactions(props.id, page + 1, pageSize, filterable);
  }, 1000);

  const navigateToTransaction = id => {
    props.history.push(`/portal/transactions/${id}`);
  };

  const columns = [
    {
      Header: t('Date'),
      accessor: 'date',
      Filter: ({ filter, onChange }) => {
        return (
          <DatePickerBlock>
            <DatePicker
              isClearable
              clearButtonTitle={t('Clear')}
              placeholderText={t('Search...')}
              maxDate={new Date()}
              selected={filter ? new Date(filter.value) : null}
              onChange={date => {
                onChange(date ? moment(date).format('YYYY-MM-DD') : '');
              }}
            />
            <IconBlock>
              <Calendar />
            </IconBlock>
          </DatePickerBlock>
        );
      },
      Cell: row => (
        <div>
          {moment
            .utc(row.value)
            .local()
            .format('DD/MM/YYYY HH:mm:ss')}
        </div>
      )
    },
    {
      Header: t('User'),
      accessor: 'userName',
      Filter: ({ filter, onChange }) => (
        <input
          type="text"
          placeholder={t('Search...')}
          value={filter ? filter.value : ''}
          onChange={event => onChange(event.target.value)}
        />
      )
    },
    {
      Header: t('Type'),
      accessor: 'type',
      Cell: row => <div>{getTransactionType(row.value)}</div>,
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : ''}
        >
          <option value="">All</option>
          <option value="3">Transfer</option>
          <option value="1">Payment</option>
          <option value="2">Top Up</option>
          <option value="4">Withdraw</option>
        </select>
      )
    },
    {
      Header: t('Amount'),
      accessor: 'amount',
      Cell: ({ value, original: { debit, currency } }) => (
        <Amount debit={debit}>
          {debit ? '' : '-'}
          {value} {currency}
        </Amount>
      ),
      Filter: ({ filter, onChange }) => (
        <input
          type="text"
          placeholder={t('Search...')}
          value={filter ? filter.value : ''}
          onChange={event => onChange(event.target.value)}
        />
      )
    },
    {
      Header: t('Status'),
      accessor: 'status',
      Cell: row => (
        <StatusLabel status={transactionStatusColor(row.value)}>
          {transactionStatus(row.value)}
        </StatusLabel>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : ''}
        >
          <option value="">{t('All statuses')}</option>
          <option value="1">Approved</option>
          <option value="2">Voided</option>
          <option value="3">Settled</option>
          <option value="4">Refunded</option>
          <option value="5">Declined</option>
          <option value="6">Failed</option>
          <option value="7">Success</option>
          <option value="8">Pending</option>
        </select>
      )
    }
  ];

  return (
    <ReactTable
      PaginationComponent={TableFooter}
      NoDataComponent={NoRowsFound}
      data={props.currentWalletTransactions || []}
      columns={columns}
      defaultPageSize={10}
      loading={props.isTransactionsLoading}
      filterable
      sortable={false}
      pageInfo={props.transactionPageInfo}
      getTdProps={(state, rowInfo) => ({
        onClick: rowInfo
          ? () => navigateToTransaction(rowInfo.original.transactionId)
          : null
      })}
      manual
      pages={props.transactionPageInfo && props.transactionPageInfo.totalPage}
      onFetchData={({ page, pageSize, filtered }) => {
        if (
          currentPageRef.current === null ||
          currentPageRef.current !== page ||
          currentPageSizeRef.current === null ||
          currentPageSizeRef.current !== pageSize
        ) {
          currentPageRef.current = page;
          currentPageSizeRef.current = pageSize;
          props.getWalletTransactions(props.id, page + 1, pageSize, filtered);
        } else {
          onFilter(filtered, page, pageSize);
        }
      }}
      minRows={1}
      className="-highlight"
    />
  );
};

export default TransactionTable;
