// eslint-disable-next-line consistent-return
export const transactionStatus = status => {
  return {
    1: 'Approved',
    2: 'Voided',
    3: 'Settled',
    4: 'Refunded',
    5: 'Declined',
    6: 'Failed',
    7: 'Success',
    8: 'Pending'
  }[status];
};

export const transactionStatusColor = status => {
  switch (status) {
    case 8:
      return 'blue';
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 7:
      return 'green';
    default:
      return 'red';
  }
};
