import { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { parseQueryParams, stringifyQueryParamsWithOmit } from '../utils';

export const useQueryParams = defaultParams => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { search } = useLocation();
  const queryParams = parseQueryParams(search);
  const [stateQueryParams, setStateQueryParams] = useState(
    defaultParams ? { ...defaultParams, ...queryParams } : queryParams
  );
  const setCombinedParams = useCallback(
    params => {
      const parsedQueryParams = parseQueryParams(search);
      const newQueryParams = {
        ...parsedQueryParams,
        ...params
      };

      history.replace(`${url}?${stringifyQueryParamsWithOmit(newQueryParams)}`);
      setStateQueryParams(newQueryParams);
    },
    [history, search, url]
  );
  const setQueryParams = useCallback(
    params => {
      history.replace(`${url}?${stringifyQueryParamsWithOmit(params)}`);
      setStateQueryParams(params);
    },
    [history, url]
  );

  useEffect(() => {
    if (defaultParams) {
      history.replace(
        `${url}?${stringifyQueryParamsWithOmit(stateQueryParams)}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [setCombinedParams, stateQueryParams, setQueryParams];
};
