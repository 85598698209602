// @flow
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { closeWallet } from '../../../redux/actions/wallets';
import { closeModal } from '../../../redux/actions/modals';
import { Button } from '../../components';

const ButtonBlock = styled.div`
  position: absolute;
  bottom: 25px;
  left: 140px;
`;
const Root = styled.div`
  margin-top: 5px;
`;
const Text = styled.p`
  margin: 5px 0;
`;
const CloseWallet = props => {
  const [t] = useTranslation('translation');

  const close = () => {
    const { walletId } = props.modalData;
    props.closeWallet(walletId, { walletStatusId: 2 });
    props.closeModal();
  };

  return (
    <>
      <Root>
        <Text>{t('Are you sure you want to close this wallet account')}</Text>
        <Text>
          {t(
            'Closed account will be visible under IEP portal but you cannot use it anymore'
          )}
        </Text>
      </Root>
      <ButtonBlock>
        <Button destructive title={t('Yes, close account')} onClick={close} />
      </ButtonBlock>
    </>
  );
};
const mapStateToProps = state => ({
  modalData: state.modals.modalData
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeModal,
      closeWallet
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(CloseWallet);
