import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserList from './UserList';
import {
  getUsersApproved,
  getUserStatuses
} from '../../../redux/actions/usersApproved';

const mapStateToProps = state => ({
  users: state.usersApproved.usersList,
  isLoading: state.usersApproved.isLoading,
  userStatuses: state.usersApproved.userStatuses,
  pageInfo: state.usersApproved.pageInfo
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUsersApproved,
      getUserStatuses
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(UserList);
