import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled, { createGlobalStyle } from 'styled-components';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import UserList from './UserList';
import AccessRequest from './AccessRequest';
import { Card } from '../components';
import { useQueryParams } from '../../HOOKS';
import { getActiveTabIndex } from '../../utils';

const CardNoPadding = styled(Card)`
  padding: 24px 0 0 0;
`;
const TabBlock = styled.div`
  display: flex;
  margin-left: 24px;
`;
const TabsGlobal = createGlobalStyle`
.MuiTab-root {
    min-height: 20px!important;
    min-width: 50px!important;
}
.MuiTab-textColorInherit{
    background: #f9f9fb;
    color: rgba(5, 34, 56, 0.56);
    text-transform: uppercase;
    font-size: 12px;
    padding: 8px 16px;
    border-radius: 2px;
    font-weight: 500!important;
    opacity: 1!important;
}
.MuiTab-textColorInherit.Mui-selected{
    background-color: #004b87!important;
    color: #fff;
}
.MuiTab-root {
    background-color: #f9f9fb!important;
    text-transform: none!important;
    font-size: 14px!important;
}
.MuiTypography-root {
    > div{
        padding: 0;
    }
}
.MuiTabs-indicator {
    display: none;
}
`;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
const tabLabels = ['User List', 'Access Requests'];

const UserRouter = props => {
  // eslint-disable-next-line no-unused-vars
  const [_, queryParams, replaceParams] = useQueryParams({
    tabName: tabLabels[0]
  });
  const [value, setValue] = React.useState(
    getActiveTabIndex(tabLabels, queryParams.tabName)
  );
  const [t] = useTranslation('translation');

  const handleChange = (event, tabIndex) => {
    setValue(tabIndex);
    replaceParams({ tabName: tabLabels[tabIndex] });
  };
  return (
    <CardNoPadding>
      <TabsGlobal />
      <TabBlock>
        <Tabs value={value} onChange={handleChange} aria-label="users">
          {tabLabels.map((label, index) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Tab key={label} label={t(label)} {...a11yProps(index)} />
          ))}
        </Tabs>
      </TabBlock>
      <TabPanel value={value} index={0}>
        <UserList {...props} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AccessRequest {...props} />
      </TabPanel>
    </CardNoPadding>
  );
};
export default UserRouter;
