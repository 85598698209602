import React, { useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Switch, Route, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TabsGlobal, TabBlock, a11yProps } from './components/Tabs';
import {
  ActionBlock,
  TableHeader,
  ButtonBlock,
  RadioLabel,
  RadioPeriod
} from './components/style';
import {
  FREQUENCY_MONTHLY,
  FREQUENCY_DAILY,
  FREQUENCY_WEEKLY,
  PERIODIOCITY
} from './ReportsConstants';
import { Loader } from '../components';
import { formatWeeklyDailyPeriod } from '../../utils/formatReportsPeriod';
import ChartContainer from './components/ChartContainer';
import TableContainer from './components/TableContainer';
import { parseQueryParams, stringifyQueryParamsWithOmit } from '../../utils';

const PeriodButton = ({ id, value, checked, onChange, label, disabled }) => (
  <div>
    <RadioPeriod
      type="radio"
      id={id}
      name="period"
      value={value}
      checked={checked && !disabled}
      onChange={onChange}
      disabled={disabled}
    />
    <RadioLabel disabled={disabled} htmlFor={id}>
      {label}
    </RadioLabel>
  </div>
);

const reportTabs = pathname => (pathname.includes('table') ? 0 : 1);

const ReportRouter = ({
  iataCode,
  walletId,
  customRange,
  availablePeriods,
  history,
  lastPeriod
}) => {
  const { periodicity } = parseQueryParams(history.location.search);
  const selectedPeriod = Object.keys(PERIODIOCITY).find(
    key => PERIODIOCITY[key] === Number(periodicity)
  );
  const [t] = useTranslation('translation');
  const [value, setValue] = useState(reportTabs(history.location.pathname));
  const [period, setPeriod] = useState(
    availablePeriods.includes(selectedPeriod)
      ? selectedPeriod
      : availablePeriods[0]
  );

  useEffect(() => {
    const queryParams = parseQueryParams(history.location.search);
    const newQueryParams = {
      agencyId: iataCode.id,
      walletId,
      lastPeriod,
      periodicity: PERIODIOCITY[period]
    };

    history.push(
      `${
        value === 1 ? '/portal/reports/chart' : '/portal/reports/table'
      }?${stringifyQueryParamsWithOmit({ ...queryParams, ...newQueryParams })}`
    );
  }, [lastPeriod, history, value, iataCode.id, walletId, customRange, period]);

  const handlePeriodChange = ({ target }) => {
    setPeriod(target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const routeProps = {
    iataCode,
    walletId,
    customRange,
    period
  };

  return !period ? (
    <Loader />
  ) : (
    <>
      <TabsGlobal />
      <TabBlock style={{ marginBottom: '20px' }}>
        <ActionBlock>
          <TableHeader>
            {t('Transaction History')} {formatWeeklyDailyPeriod(customRange)}
          </TableHeader>
          <ButtonBlock>
            <PeriodButton
              id="1"
              value={FREQUENCY_DAILY}
              onChange={handlePeriodChange}
              checked={period === FREQUENCY_DAILY}
              label={t('Daily')}
              disabled={!availablePeriods.includes(FREQUENCY_DAILY)}
            />
            <PeriodButton
              id="2"
              value={FREQUENCY_WEEKLY}
              onChange={handlePeriodChange}
              checked={period === FREQUENCY_WEEKLY}
              label={t('Weekly')}
              disabled={!availablePeriods.includes(FREQUENCY_WEEKLY)}
            />
            <PeriodButton
              id="3"
              value={FREQUENCY_MONTHLY}
              onChange={handlePeriodChange}
              checked={period === FREQUENCY_MONTHLY}
              label={t('Monthly')}
              disabled={!availablePeriods.includes(FREQUENCY_MONTHLY)}
            />
          </ButtonBlock>
        </ActionBlock>
        <Tabs value={value} onChange={handleChange} aria-label="agencyTabs">
          <Tab label={t('Table view')} {...a11yProps(0)} />
          <Tab label={t('Chart view')} {...a11yProps(1)} />
        </Tabs>
      </TabBlock>
      {customRange.startDate && customRange.endDate && (
        <Switch>
          <Route
            exact
            path="/portal/reports/chart"
            render={() => <ChartContainer {...routeProps} />}
          />
          <Route
            exact
            path="/portal/reports/table"
            render={() => <TableContainer {...routeProps} />}
          />
        </Switch>
      )}
    </>
  );
};

export default withRouter(ReportRouter);
