import React from 'react';

const DropArrow = props => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M16 16V0H0v16z" />
        <path
          fill="#052238"
          fillOpacity=".56"
          d="M13.657 10L8 4.343 2.343 10z"
        />
      </g>
    </svg>
  );
};
export default DropArrow;
