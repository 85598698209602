import moment from 'moment';

export const FREQUENCY_MONTHLY = 'months';
export const FREQUENCY_WEEKLY = 'weeks';
export const FREQUENCY_DAILY = 'days';

export const DURATION_LAST_YEAR = 'last_year';
export const DURATION_LAST_QUARTER = 'last_quarter';
export const DURATION_LAST_MONTH = 'last_month';
export const DURATION_LAST_WEEK = 'last_week';
export const DURATION_CUSTOM_VALUE = 'custom';

export const DEFAULT_PERIOD = t => ({
  value: DURATION_LAST_YEAR,
  label: t('Last Year'),
  key: 'Last Year'
});
export const CUSTOM_DURATION = t => ({
  value: DURATION_CUSTOM_VALUE,
  label: t('Set Custom Range'),
  key: 'Set Custom Range'
});

export const PERIODS = t => [
  { value: DURATION_LAST_WEEK, label: t('Last Week'), key: 'Last Week' },
  { value: DURATION_LAST_MONTH, label: t('Last Month'), key: 'Last Month' },
  {
    value: DURATION_LAST_QUARTER,
    label: t('Last Quarter'),
    key: 'Last Quarter'
  },
  DEFAULT_PERIOD(t),
  CUSTOM_DURATION(t)
];

export const PERIODIOCITY = {
  [FREQUENCY_DAILY]: 1,
  [FREQUENCY_WEEKLY]: 2,
  [FREQUENCY_MONTHLY]: 3
};

export const AVAILABLE_PERIODS = {
  [DURATION_LAST_YEAR]: [FREQUENCY_MONTHLY, FREQUENCY_WEEKLY],
  [DURATION_LAST_QUARTER]: [FREQUENCY_MONTHLY, FREQUENCY_WEEKLY],
  [DURATION_LAST_MONTH]: [FREQUENCY_WEEKLY, FREQUENCY_DAILY],
  [DURATION_LAST_WEEK]: [FREQUENCY_DAILY],
  [DURATION_CUSTOM_VALUE]: [
    FREQUENCY_MONTHLY,
    FREQUENCY_WEEKLY,
    FREQUENCY_DAILY
  ]
};

export const DEFAULT_WALLET = t => ({
  label: t('All wallets'),
  id: null,
  key: 'All wallets'
});

export const TRANSACTION_COUNT = 'Transaction count';
export const SETTLEMENT_AMOUNT = 'Settlement amount';
export const REFUND_AMOUNT = 'Refund amount';

export const formatTooltipLabel = (type, { payload }, t) => {
  switch (type) {
    case SETTLEMENT_AMOUNT:
      return [payload.labelTotalAmount, t(SETTLEMENT_AMOUNT)];
    case REFUND_AMOUNT:
      return [payload.labelTotalRefund, t(REFUND_AMOUNT)];
    default:
      return [payload.transactionCount, t(TRANSACTION_COUNT)];
  }
};

export const buildChartObject = timestamp => ({
  transactionCount: 0,
  totalAmount: 0,
  totalRefund: 0,
  labelTotalAmount: 0,
  labelTotalRefund: 0,
  time: timestamp
});

export const getCorrectDateFormat = period => {
  switch (period) {
    case FREQUENCY_WEEKLY:
      return 'DD MMM';
    case FREQUENCY_DAILY:
      return 'DD';
    default:
      return 'MMM';
  }
};

export const getFakeMiddleOfPeriod = (nextDate, period) => {
  switch (period) {
    case FREQUENCY_MONTHLY: {
      const newDate = moment(nextDate).subtract(1, FREQUENCY_MONTHLY);
      const middleDaysInMonth = Math.round(moment(newDate).daysInMonth() / 2);
      return newDate.add(middleDaysInMonth, FREQUENCY_DAILY);
    }
    case FREQUENCY_WEEKLY: {
      return nextDate
        .startOf('week')
        .subtract(1, FREQUENCY_WEEKLY)
        .add(4, FREQUENCY_DAILY);
    }
    case FREQUENCY_DAILY: {
      return nextDate.subtract(1, FREQUENCY_DAILY).add(12, 'hours');
    }
    default:
      return {};
  }
};

export const formatChartLabel = (unixTime, period) => {
  const formatLabel =
    period === FREQUENCY_WEEKLY
      ? moment(unixTime)
          .startOf('week')
          .add(1, 'days')
      : moment(unixTime);
  return formatLabel.format(getCorrectDateFormat(period));
};
