import React from 'react';

const Calendar = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      className={props.className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h18v18H0z" />
        <path
          fill="#979797"
          stroke="#979797"
          d="M14.75 15.5H3.25V6.25h11.5v9.25zM12.5 2v1.5h-7V2H5v1.5H3.75c-.556 0-1 .444-1 1V15a1 1 0 0 0 1 1h10.5a1 1 0 0 0 1-1V4.5a1 1 0 0 0-1-1H13V2h-.5zm-.25 8.25H9.5V13h2.75v-2.75z"
        />
      </g>
    </svg>
  );
};
export default Calendar;
