import {
  FETCH_REPORTS,
  FETCH_REPORTS_CHART,
  FETCH_REPORTS_AGENCIES
} from '../../constants/actionTypes';
import { api } from '../../services/rest';
import { getSearchTransactionsPath } from '../../utils/getSearchTransactionsPath';

export const getChartReports = paramsObject => dispatch => {
  dispatch({ type: FETCH_REPORTS_CHART.REQUEST });

  api.get(`reports/chart?${getSearchTransactionsPath(paramsObject)}`).then(
    response => {
      if (response.responseCode === '000') {
        return dispatch({
          type: FETCH_REPORTS_CHART.SUCCESS,
          payload: response
        });
      }
      return dispatch({
        type: FETCH_REPORTS_CHART.FAILURE,
        payload: response
      });
    },
    err => dispatch({ type: FETCH_REPORTS_CHART.FAILURE, payload: err })
  );
};

export const getReports = paramsObject => dispatch => {
  dispatch({ type: FETCH_REPORTS.REQUEST });

  api.get(`reports?${getSearchTransactionsPath(paramsObject)}`).then(
    response => {
      if (response.responseCode === '000') {
        return dispatch({
          type: FETCH_REPORTS.SUCCESS,
          payload: response
        });
      }
      return dispatch({
        type: FETCH_REPORTS.FAILURE,
        payload: response
      });
    },
    err => dispatch({ type: FETCH_REPORTS_CHART.FAILURE, payload: err })
  );
};

export const getReportsAgencies = params => dispatch => {
  dispatch({ type: FETCH_REPORTS_AGENCIES.REQUEST });

  api.get(`reports/dropdowns?${getSearchTransactionsPath(params)}`).then(
    response => {
      if (response.responseCode === '000') {
        return dispatch({
          type: FETCH_REPORTS_AGENCIES.SUCCESS,
          payload: response
        });
      }
      return dispatch({
        type: FETCH_REPORTS_AGENCIES.FAILURE,
        payload: response
      });
    },
    err => dispatch({ type: FETCH_REPORTS_CHART.FAILURE, payload: err })
  );
};
