import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Table from './Table';
import { setPageSize, setPage } from '../../../redux/actions/commonActions';
import { getReports } from '../../../redux/actions/reports';

const mapStateToProps = state => ({
  reports: state.reports.reports,
  isLoading: state.reports.isLoading,
  pageSize: state.reports.pageSize,
  pageInfo: state.reports.pageInfo,
  page: state.reports.page,
  pagedTotal: state.reports.pagedTotal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getReports,
      setPageSize,
      setPage
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Table);
