import styled from 'styled-components';
import baseTheme from '../../../styles/theme';

export const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const StyledInput = styled.input`
    border: ${({ error, touched }) =>
      error && touched
        ? '2px solid #f15c5c'
        : '1px solid rgba(5, 34, 56, 0.25)'};
    border-radius: 2px;
    min-width:0;
    width: 100%;
    padding: 8px 12px;
    padding-left: ${props => (props.withIcon ? '32px' : '8px')};
    font-size: 14px;
    line-height: 1.71;
    color: ${baseTheme.color.font.default};
    background: ${props => (props.disabled ? '#f7f7fe' : '#fff')}
    &:focus {
      border: 2px solid #004b87;
    }
`;
export const Label = styled.label`
  color: ${baseTheme.color.font.grey};
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 5px;
  opacity: ${({ opacity }) => opacity || 0.56};
  cursor: ${({ htmlFor }) => (htmlFor ? 'pointer' : 'default')};
`;
export const InputContent = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;
export const HelpText = styled.p`
  color: ${({ error, color }) =>
    error ? '#f15c5c' : color || 'rgba(5, 34, 56, 0.32)'};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '12px')};
  margin-top: 5px;
`;
export const Icon = styled.div`
  position: absolute;
  height: 100%;
  align-items: center;
  display: flex;
  left: 8px;
`;
