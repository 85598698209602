/* eslint-disable consistent-return */
import {
  GET_ACCESSIBLE_AGENCIES,
  GET_AGENCIES,
  GET_INTERNAL_AGENCIES
} from '../../constants/actionTypes';
import { api } from '../../services/rest';
import { stringifyQueryParamsWithOmit } from '../../utils';
import { INTERNAL_TOKEN_KEY } from '../../components/RedirectPage';

export const getAccessibleAgencies = iepStatus => dispatch => {
  dispatch({ type: GET_ACCESSIBLE_AGENCIES.REQUEST });

  return api
    .get(`agencies/agencyCodes?${stringifyQueryParamsWithOmit({ iepStatus })}`)
    .then(
      response => {
        if (response.responseCode === '000') {
          return dispatch({
            type: GET_ACCESSIBLE_AGENCIES.SUCCESS,
            payload: response
          });
        }
        dispatch({ type: GET_ACCESSIBLE_AGENCIES.FAILURE, response });
      },
      err => dispatch({ type: GET_ACCESSIBLE_AGENCIES.FAILURE, err })
    );
};

export const getAgencies = () => dispatch => {
  dispatch({ type: GET_AGENCIES.REQUEST });

  return api.get(`agencies`).then(
    response => {
      if (response.responseCode === '000') {
        return dispatch({ type: GET_AGENCIES.SUCCESS, payload: response });
      }
      dispatch({ type: GET_AGENCIES.FAILURE, response });
    },
    err => dispatch({ type: GET_AGENCIES.FAILURE, err })
  );
};

export const getInternalAgencies = query => dispatch => {
  dispatch({ type: GET_INTERNAL_AGENCIES.REQUEST });
  const token = localStorage.getItem(INTERNAL_TOKEN_KEY);

  return api
    .get(`internalAgencies?codeFragment=${query.trim()}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(
      response => {
        if (response.responseCode === '000') {
          dispatch({
            type: GET_INTERNAL_AGENCIES.SUCCESS,
            payload: response
          });

          return Promise.resolve(response);
        }
        dispatch({ type: GET_INTERNAL_AGENCIES.FAILURE, response });
      },
      err => dispatch({ type: GET_INTERNAL_AGENCIES.FAILURE, err })
    );
};
