/* eslint-disable consistent-return */
import {
  FETCH_USERS_APPROVED,
  FETCH_USER_DETAIL_APPROVED,
  DELETE_USER,
  GET_ACCESSIBLE_ROLES,
  GET_USER_DATA_FOR_EDITING,
  DISABLE_USER,
  GET_USER_STATUSES,
  ENABLE_USER
} from '../../constants/actionTypes';
import { api } from '../../services/rest';
import { getGetParams } from '../../utils/getGetParams';

export const getUsersApproved = (
  currentPage,
  pageSize,
  filtered
) => dispatch => {
  dispatch({ type: FETCH_USERS_APPROVED.REQUEST });
  const filteredQuery = getGetParams(filtered);

  return api
    .get(`users?PageNo=${currentPage}&PageSize=${pageSize}&${filteredQuery}`)
    .then(
      response => {
        if (response.responseCode === '000') {
          return dispatch({
            type: FETCH_USERS_APPROVED.SUCCESS,
            data: response
          });
        }
        dispatch({ type: FETCH_USERS_APPROVED.FAILURE, response });
      },
      err => dispatch({ type: FETCH_USERS_APPROVED.FAILURE, err })
    );
};
export const getUserDetailsApproved = id => dispatch => {
  dispatch({ type: FETCH_USER_DETAIL_APPROVED.REQUEST });

  return api.get(`users/${id}`).then(
    response => {
      if (response.responseCode === '000') {
        return dispatch({
          type: FETCH_USER_DETAIL_APPROVED.SUCCESS,
          data: response
        });
      }
      dispatch({
        type: FETCH_USER_DETAIL_APPROVED.FAILURE,
        payload: response
      });
    },
    err => dispatch({ type: FETCH_USER_DETAIL_APPROVED.FAILURE, payload: err })
  );
};

export const deleteUser = id => dispatch => {
  dispatch({ type: DELETE_USER.REQUEST });

  return api.put(`users/${id}`, { userStatus: 7 }).then(
    response => {
      if (response.responseCode === '000') {
        dispatch({ type: DELETE_USER.SUCCESS, payload: response });
        dispatch(getUserDetailsApproved(id));
      } else {
        dispatch({ type: DELETE_USER.FAILURE, payload: response });
      }
    },
    err => dispatch({ type: DELETE_USER.FAILURE, payload: err })
  );
};

export const disableUser = id => dispatch => {
  dispatch({ type: DISABLE_USER.REQUEST });
  return api.put(`users/${id}`, { userStatus: 6 }).then(
    response => {
      if (response.responseCode === '000') {
        dispatch({ type: DISABLE_USER.SUCCESS, payload: response });
        dispatch(getUserDetailsApproved(id));
      } else {
        dispatch({ type: DISABLE_USER.FAILURE, payload: response });
      }
    },
    err => dispatch({ type: DISABLE_USER.FAILURE, payload: err })
  );
};

export const enableUser = id => dispatch => {
  dispatch({ type: ENABLE_USER.REQUEST });
  return api.put(`users/${id}`, { userStatus: 5 }).then(
    response => {
      if (response.responseCode === '000') {
        dispatch({ type: ENABLE_USER.SUCCESS, payload: response });
        dispatch(getUserDetailsApproved(id));
      } else {
        dispatch({ type: ENABLE_USER.FAILURE, payload: response });
      }
    },
    err => dispatch({ type: ENABLE_USER.FAILURE, payload: err })
  );
};

export const getAccessibleRoles = () => dispatch => {
  dispatch({ type: GET_ACCESSIBLE_ROLES.REQUEST });

  return api.get(`loggedUser/accessibleRoles`).then(
    response => {
      if (response.responseCode === '000') {
        return dispatch({
          type: GET_ACCESSIBLE_ROLES.SUCCESS,
          payload: response
        });
      }
      dispatch({ type: GET_ACCESSIBLE_ROLES.FAILURE, payload: response });
    },
    err => dispatch({ type: GET_ACCESSIBLE_ROLES.FAILURE, payload: err })
  );
};

// ------- USER data
export const getUserDataForEditing = (id, role) => dispatch => {
  dispatch({ type: GET_USER_DATA_FOR_EDITING.REQUEST });

  return api
    .get(
      role
        ? `users/${id}/dataForEditing?selectedUserRole=${role}`
        : `users/${id}/dataForEditing`
    )
    .then(
      response => {
        if (response.responseCode === '000') {
          return dispatch({
            type: GET_USER_DATA_FOR_EDITING.SUCCESS,
            payload: response
          });
        }
        dispatch({
          type: GET_USER_DATA_FOR_EDITING.FAILURE,
          payload: response
        });
      },
      err => dispatch({ type: GET_USER_DATA_FOR_EDITING.FAILURE, payload: err })
    );
};

export const getUserStatuses = () => dispatch => {
  dispatch({ type: GET_USER_STATUSES.REQUEST });

  return api.get('users/editableUsersStatuses').then(
    response => {
      if (response.responseCode === '000') {
        return dispatch({ type: GET_USER_STATUSES.SUCCESS, payload: response });
      }
      dispatch({ type: GET_USER_STATUSES.FAILURE, payload: response });
    },
    err => dispatch({ type: GET_USER_STATUSES.FAILURE, payload: err })
  );
};
