import React from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { Alert, Cancel } from '../icons';
import { Button } from '.';

const Root = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fac832;
  padding: 8px;
  color: rgba(5, 34, 56, 0.87);
  font-size: 12px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 5;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const Flex3 = styled(Row)`
  flex: 3;
  margin-left: 30px;
`;
const Flex1 = styled(Row)`
  flex: 1;
`;
const CookieText = styled.div`
  margin-left: 16px;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.3px;
`;
const CloseCookie = styled(Cancel)``;
const CloseCookieButton = styled.button`
  outline: none;
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  cursor: pointer;
`;
const CookieBanner = ({ enableCookie }) => {
  const [t] = useTranslation('translation');

  return (
    <Root>
      <Row>
        <Flex3>
          <Alert />
          <CookieText>
            <Trans i18nKey="This website uses cookies" />
          </CookieText>
        </Flex3>
        <Flex1>
          <Button title={t('Accept cookies')} primary onClick={enableCookie} />
        </Flex1>
        <Flex3>
          <CloseCookieButton onClick={enableCookie}>
            <CloseCookie />
          </CloseCookieButton>
        </Flex3>
      </Row>
    </Root>
  );
};
export default CookieBanner;
