import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import get from 'lodash.get';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import isEmpty from 'lodash.isempty';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { Card, Loader } from '../components';
import {
  Row,
  RowSelect,
  PageHeader,
  IataLabel,
  CalendarBlock,
  CalendarStyled,
  iataStyles,
  walletStyles,
  periodStyles
} from './components/style';
import baseTheme from '../../styles/theme';

import {
  PERIODS,
  DURATION_LAST_YEAR,
  AVAILABLE_PERIODS,
  FREQUENCY_DAILY,
  FREQUENCY_WEEKLY,
  DEFAULT_WALLET,
  DEFAULT_PERIOD,
  DURATION_CUSTOM_VALUE,
  CUSTOM_DURATION
} from './ReportsConstants';
import ReportRouter from './ReportRouter';
import getStartEndDate from '../../utils/getStartEndDate';

const DatePickerWrap = styled.div`
  position: absolute;
  right: 0;
  top: 37px;
  z-index: 1;
  width: 100%;
`;

const StyledInput = styled.input`
  border: 1px solid rgba(5, 34, 56, 0.25);
  border-radius: 2px;
  min-width: 0;
  width: 158px;
  padding: 4px 12px;
  border-radius: 2px;
  padding-left: 8px;
  font-size: 14px;
  line-height: 1.71;
  color: ${baseTheme.color.font.default};
  background: #fff;
  &:focus {
    border: 1px solid #004b87;
  }
`;

const getMinDates = (period, startDate) => {
  switch (period) {
    case FREQUENCY_WEEKLY:
      return moment(startDate)
        .subtract(1, 'years')
        .toDate();
    case FREQUENCY_DAILY:
      return moment(startDate)
        .subtract(2, 'months')
        .toDate();
    default:
      return moment(startDate)
        .subtract(5, 'years')
        .toDate();
  }
};

const Reports = ({ getReportsAgencies, agencies, isLoading, history }) => {
  const [t] = useTranslation('translation');
  const [iataCode, setIataCode] = useState(null);
  const [wallet, setWallet] = useState(DEFAULT_WALLET(t));
  const [datepickerVisible, setDatepickerVisible] = useState(false);
  const [customRange, setCustomRange] = useState(
    getStartEndDate(DURATION_LAST_YEAR)
  );
  const [lastPeriod, setLastPeriod] = useState(DEFAULT_PERIOD(t));
  const [minDate, setMinDate] = useState(
    getMinDates(AVAILABLE_PERIODS[lastPeriod.value][0], customRange.startDate)
  );
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);

  const { agencyId, walletId, lastPeriod: lastPeriodParam } = queryString.parse(
    history.location.search
  );

  useEffect(() => {
    if (isEmpty(agencies) || iataCode) {
      getReportsAgencies({ walletId, agencyId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMinDate(
      getMinDates(AVAILABLE_PERIODS[lastPeriod.value][0], customRange.startDate)
    );
    if (lastPeriod.value !== DURATION_CUSTOM_VALUE) {
      setCustomRange(getStartEndDate(lastPeriod.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastPeriod]);

  useEffect(() => {
    if (agencies) {
      const selectedIataCode = agencyId
        ? agencies.find(agency => agencyId === agency.id)
        : agencies.find(agency => agency.isHeadAgency) || agencies[0];
      const selectedWallet = get(selectedIataCode, 'wallets', []).find(
        ({ id }) => walletId === id
      );

      if (selectedIataCode) {
        setIataCode({
          value: selectedIataCode.agencyCode,
          label: selectedIataCode.agencyCode,
          id: selectedIataCode.id,
          wallets: selectedIataCode.wallets
        });
        setWallet(
          selectedWallet
            ? { label: selectedWallet.name, value: selectedWallet.id }
            : DEFAULT_WALLET(t)
        );
      }

      setLastPeriod(
        lastPeriodParam && lastPeriodParam !== DURATION_CUSTOM_VALUE
          ? PERIODS(t).find(period => period.value === lastPeriodParam)
          : DEFAULT_PERIOD(t)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencies]);

  useEffect(() => {
    if (
      customStartDate &&
      customEndDate &&
      customRange.startDate !== customStartDate &&
      customRange.endDate !== customEndDate
    ) {
      setLastPeriod(CUSTOM_DURATION(t));
      setCustomRange({ startDate: customStartDate, endDate: customEndDate });
      setDatepickerVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customStartDate, customEndDate]);

  const onRangeChange = newLastPeriod => {
    if (newLastPeriod.value === DURATION_CUSTOM_VALUE) {
      setDatepickerVisible(true);
    } else {
      setCustomRange(getStartEndDate(newLastPeriod.value));
      setLastPeriod(newLastPeriod);
    }
  };

  const onCustomRangeFromChange = date => {
    setCustomStartDate(date);
  };

  const onCustomRangeToChange = date => {
    setCustomEndDate(date);
  };

  return (
    <>
      {isLoading && !iataCode && <Loader />}
      {iataCode && (
        <div>
          <Row>
            <PageHeader>{t('Reports')}</PageHeader>
            <RowSelect>
              <IataLabel>{t('IATA Code')}</IataLabel>
              <Select
                isSearchable={false}
                placeholder="IATA"
                options={agencies.map(agency => ({
                  value: agency.agencyCode,
                  label: agency.agencyCode,
                  id: agency.id,
                  wallets: agency.wallets
                }))}
                maxMenuHeight={130}
                styles={iataStyles}
                onChange={newIataCode => {
                  setIataCode(newIataCode);
                  setWallet(DEFAULT_WALLET(t));
                }}
                value={iataCode}
              />
              <Select
                isSearchable={false}
                options={iataCode.wallets
                  .map(({ id, name }) => ({
                    value: id,
                    label: name
                  }))
                  .concat(DEFAULT_WALLET(t))
                  .reverse()}
                maxMenuHeight={130}
                styles={walletStyles}
                value={
                  wallet.key
                    ? { value: wallet.value, label: t(wallet.key) }
                    : wallet
                }
                onChange={newWallet => setWallet(newWallet)}
              />
              <CalendarBlock>
                <Select
                  isSearchable={false}
                  options={PERIODS(t)}
                  styles={periodStyles}
                  onChange={onRangeChange}
                  onMenuOpen={() => setDatepickerVisible(false)}
                  value={{ value: lastPeriod.value, label: t(lastPeriod.key) }}
                />
                <CalendarStyled />
                {datepickerVisible && (
                  <DatePickerWrap>
                    <DatePicker
                      popperPlacement="bottom-end"
                      placeholderText={t('Select from')}
                      dateFormat="dd/MM/yyyy"
                      customInput={<StyledInput />}
                      maxDate={new Date()}
                      selected={
                        customStartDate ? new Date(customStartDate) : null
                      }
                      onChange={onCustomRangeFromChange}
                      startDate={customStartDate}
                      endDate={customEndDate}
                      minDate={minDate}
                    />
                    <DatePicker
                      popperPlacement="bottom-end"
                      placeholderText={t('Select to')}
                      dateFormat="dd/MM/yyyy"
                      customInput={<StyledInput />}
                      maxDate={new Date()}
                      selectsEnd
                      selected={customEndDate ? new Date(customEndDate) : null}
                      onChange={onCustomRangeToChange}
                      startDate={customStartDate}
                      endDate={customEndDate}
                      minDate={customStartDate}
                    />
                  </DatePickerWrap>
                )}
              </CalendarBlock>
            </RowSelect>
          </Row>
          <Card>
            <ReportRouter
              key={lastPeriod.value}
              lastPeriod={lastPeriod.value}
              iataCode={iataCode}
              walletId={wallet.value}
              customRange={customRange}
              availablePeriods={AVAILABLE_PERIODS[lastPeriod.value]}
            />
          </Card>
        </div>
      )}
    </>
  );
};

export default Reports;
