/* eslint-disable consistent-return */
import {
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTION_DETAILS,
  FETCH_TRANSACTION_EXPORT_FILE,
  SET_IS_ADVANCED_SEARCH
} from '../../constants/actionTypes';
import { api } from '../../services/rest';
import { getSearchTransactionsPath } from '../../utils/getSearchTransactionsPath';
import { getGetParams } from '../../utils/getGetParams';

export const getTransactionsRequest = () => ({
  type: FETCH_TRANSACTIONS.REQUEST
});

export const getTransactionsSuccess = data => ({
  type: FETCH_TRANSACTIONS.SUCCESS,
  data
});

export const getTransactionsFailure = error => ({
  type: FETCH_TRANSACTIONS.FAILURE,
  error
});

export const setIsAdvancedSearch = isAdvancedSearch => ({
  type: SET_IS_ADVANCED_SEARCH,
  isAdvancedSearch
});

export const getTransactions = (currentPage, pageSize, filtered) => (
  dispatch,
  getState
) => {
  const state = getState();
  dispatch(getTransactionsRequest());
  let filteredQuery;
  if (
    state.transactions.isAdvancedSearch &&
    state.form.advancedTransactionSearch
  ) {
    filteredQuery = getSearchTransactionsPath(
      state.form.advancedTransactionSearch.values
    );
  } else {
    filteredQuery = getGetParams(filtered);
  }

  return api
    .get(
      `transactions/searchTransactions?PageNo=${currentPage}&PageSize=${pageSize}&${filteredQuery}`
    )
    .then(
      response => {
        if (response.responseCode === '000') {
          return dispatch(getTransactionsSuccess(response));
        }
        dispatch(getTransactionsFailure(response));
      },
      err => dispatch(getTransactionsFailure(err))
    );
};

export const getTransactionDetails = id => dispatch => {
  dispatch({ type: FETCH_TRANSACTION_DETAILS.REQUEST });

  return api.get(`transactions/${id}`).then(
    response => {
      if (response.responseCode === '000') {
        return dispatch({
          type: FETCH_TRANSACTION_DETAILS.SUCCESS,
          payload: response
        });
      }
      dispatch({
        type: FETCH_TRANSACTION_DETAILS.FAILURE,
        payload: response
      });
    },
    err => dispatch({ type: FETCH_TRANSACTION_DETAILS.FAILURE, payload: err })
  );
};

export const getTransactionsExportFileRequest = () => ({
  type: FETCH_TRANSACTION_EXPORT_FILE.REQUEST
});

export const getTransactionsExportFileSuccess = data => ({
  type: FETCH_TRANSACTION_EXPORT_FILE.SUCCESS,
  data
});

export const getTransactionsExportFileFailure = error => ({
  type: FETCH_TRANSACTION_EXPORT_FILE.FAILURE,
  error
});

export const getTransactionsExportFile = (
  currentPage,
  pageSize,
  filtered
) => dispatch => {
  dispatch(getTransactionsExportFileRequest());
  const filteredQuery = getGetParams(filtered);

  return api
    .get(
      `transactions/report?PageNo=${currentPage}&PageSize=${pageSize}&${filteredQuery}`
    )
    .then(
      response => {
        if (response.responseCode === '000') {
          dispatch(getTransactionsExportFileSuccess(response));
        } else {
          dispatch(getTransactionsExportFileFailure(response));
        }
      },
      err => dispatch(getTransactionsExportFileFailure(err))
    );
};
