import React, { useEffect, useState } from 'react';
import { api } from '../../../services/rest';

const getComponentDisplayName = (component = {}) =>
  component.displayName || component.name || 'Component';

const AlertSettingsFormWrapper = Component => {
  const Wrapper = props => {
    const {
      closeModal,
      addAlert,
      modalData: { iataCode }
    } = props;
    const [isLoading, setIsLoadinng] = useState(true);
    const [data, setData] = useState({});
    const [isEmailsLoading, setIsEmailsLoading] = useState(false);
    const [emails, setEmails] = useState([]);

    const saveData = values => {
      setIsLoadinng(true);

      api
        .post(`wallets/notifications/${iataCode}`, {
          ...values,
          emails: values.emails.map(email =>
            typeof email === 'string' ? email : email.value
          )
        })
        .then(res => {
          const alertType =
            res.responseCode === '000' || res.responseCode === 'OK'
              ? 'success'
              : 'error';

          setIsLoadinng(false);
          closeModal();
          addAlert({ alertType, text: res.resDescription });
        })
        .catch(err => {
          console.warn(err);
          setIsLoadinng(false);
          closeModal();
          addAlert({
            alertType: 'error',
            text:
              err.resDescription || 'Fail saving alert notifications parameters'
          });
        });
    };

    useEffect(() => {
      setIsLoadinng(true);
      setIsEmailsLoading(true);

      api
        .get(`wallets/notifications/${iataCode}`)
        .then(response => {
          setIsLoadinng(false);

          if (response.responseCode === '000') {
            setData(response.data);
          } else {
            console.error('ERROR, are using mock data');
          }
        })
        .catch(err => {
          console.error('ERROR, are using mock data', err);
          setIsLoadinng(false);
        });

      api
        .get(`agencies/${iataCode}/users?statusId=5&includeManager=true`)
        .then(response => {
          setIsEmailsLoading(false);

          if (response.responseCode === '000') {
            setEmails(response.data);
          } else {
            console.error('ERROR:', response);
          }
        })
        .catch(err => {
          console.error('ERROR:', err);
          setIsEmailsLoading(false);
        });
    }, [iataCode]);

    return (
      <Component
        saveData={saveData}
        initialValues={data}
        isLoading={isLoading}
        emails={emails}
        isEmailsLoading={isEmailsLoading}
        {...props}
      />
    );
  };

  Wrapper.displayName = `JsonFormWidget(${getComponentDisplayName(Component)})`;
  return Wrapper;
};

export default AlertSettingsFormWrapper;
