import React, { useEffect } from 'react';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

import { HelpText } from './FormComponentElements';

const Progress = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
`;
const WalletItem = styled(Row)`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const WalletBlock = styled(Row)`
  margin-top: 20px;
  margin-right: ${({ hasMany }) => (hasMany ? '30px' : '0')};
`;
const WalletList = styled.div`
  max-height: 180px;
  overflow-y: auto;
`;

const ListCheckboxes = props => {
  const {
    label,
    options,
    labelName,
    valueName,
    keyName,
    isLoading,
    additionalValueName,
    selectIfOne,
    meta: { error },
    input: { value, onChange }
  } = props;
  const defaultAgentCode =
    selectIfOne && options.length === 1 && options[0].agentCode;

  useEffect(() => {
    if (defaultAgentCode) {
      onChange([defaultAgentCode]);
    }
  }, [onChange, defaultAgentCode]);

  return (
    <div>
      <WalletBlock hasMany={options.length > 5}>{label}</WalletBlock>
      {isLoading ? (
        <Progress size={25} />
      ) : (
        <>
          <HelpText error={error}>{error}</HelpText>
          <WalletList>
            {options.map(item => {
              return (
                <WalletItem key={item[keyName]}>
                  <div style={{ flex: 2 }}>
                    <FormControlLabel
                      label={item[labelName]}
                      control={
                        <Checkbox
                          color="primary"
                          disabled={!!defaultAgentCode}
                          onChange={event => {
                            const newValues = new Set(value);

                            if (event.target.checked) {
                              newValues.add(item[valueName]);
                            } else {
                              newValues.delete(item[valueName]);
                            }

                            return onChange([...newValues]);
                          }}
                          value={item[valueName]}
                          checked={value.some(val => val === item[valueName])}
                        />
                      }
                    />
                  </div>
                  {additionalValueName && (
                    <div style={{ flex: 3 }}>{item[additionalValueName]}</div>
                  )}
                </WalletItem>
              );
            })}
          </WalletList>
        </>
      )}
    </div>
  );
};

export default ListCheckboxes;
