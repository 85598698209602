/* eslint-disable no-unused-vars */
import {
  GET_ACCESSIBLE_AGENCIES,
  GET_AGENCIES,
  GET_INTERNAL_AGENCIES
} from '../../constants/actionTypes';

export const initState = {
  errors: null,
  isLoading: false,
  internalAgencies: [],
  accessibleAgencies: [],
  agencies: [],
  pageInfo: {}
};

export const agenciesSelector = state => state.agencies;

export default {
  [GET_ACCESSIBLE_AGENCIES.REQUEST]: state => {
    return {
      ...state
      //    isLoading: false
    };
  },
  [GET_ACCESSIBLE_AGENCIES.SUCCESS]: (state, payload) => {
    const agencies = payload.payload.data;
    return {
      ...state,
      accessibleAgencies: agencies
      //    isLoading: false
    };
  },
  [GET_ACCESSIBLE_AGENCIES.FAILURE]: (state, { code }) => {
    return { ...state };
  },
  [GET_AGENCIES.REQUEST]: state => {
    return {
      ...state,
      isLoading: true
    };
  },
  [GET_AGENCIES.SUCCESS]: (state, { payload }) => {
    const { data, pageInfo } = payload;
    return {
      ...state,
      agencies: data,
      pageInfo,
      isLoading: false
    };
  },
  [GET_AGENCIES.FAILURE]: (state, { code }) => {
    return { ...state, isLoading: false };
  },
  [GET_INTERNAL_AGENCIES.REQUEST]: state => {
    return {
      ...state,
      isLoading: true
    };
  },
  [GET_INTERNAL_AGENCIES.SUCCESS]: (state, payload) => {
    const internalAgencies = payload.payload.data;
    return {
      ...state,
      internalAgencies,
      isLoading: false
    };
  },
  [GET_INTERNAL_AGENCIES.FAILURE]: (state, { code }) => {
    return { ...state, isLoading: false };
  }
};
