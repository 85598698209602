/* eslint-disable no-nested-ternary */
// @flow
import React, { useRef } from 'react';
import ReactTable from 'react-table';
import styled from 'styled-components';
import * as moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import TableFooter from '../components/Table/TableFooter';
import {
  transactionStatus,
  transactionStatusColor
} from '../../utils/transactionStatus';
import { formatIEPNumber } from '../../utils/formatIEPNumber';
import { getSearchTransactionsPath } from '../../utils/getSearchTransactionsPath';
import { getGetParams } from '../../utils/getGetParams';
import { Calendar } from '../icons';
import {
  SET_TRANSACTION_TABLE_PAGE,
  SET_TRANSACTION_TABLE_PAGE_SIZE
} from '../../constants/actionTypes';
import NoRowsFound from '../components/NoRowsTable';

const StatusLabel = styled.div`
  display: inline-flex;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: 0.2px;
  padding: 2px 4px;
  border-radius: 2px;
  color: ${props =>
    props.status === 'green'
      ? '#00b326'
      : props.status === 'red'
      ? 'rgba(233, 20, 20, 0.87)'
      : '#fac832'};
  background: ${props =>
    props.status === 'green'
      ? 'rgba(0,179,38, 0.15)'
      : props.status === 'red'
      ? 'rgba(233, 20, 20, 0.15)'
      : 'rgba(250,200,50,0.15)'};
`;
const IconBlock = styled.div`
  position: absolute;
  margin-top: 7px;
  margin-left: 5px;
`;
const DatePickerBlock = styled.div`
  display: flex;
  flex-direction: row;
`;

const getFilters = (advancedSearchValues, filtered) => {
  let filteredQuery;
  if (advancedSearchValues) {
    filteredQuery = `?${getSearchTransactionsPath(advancedSearchValues)}`;
  } else {
    filteredQuery = getGetParams(filtered);
    if (filteredQuery) filteredQuery = `?${filteredQuery}`;
  }
  return filteredQuery;
};

const TransactionTable = props => {
  const currentPageRef = useRef(null);
  const currentPageSizeRef = useRef(null);

  const [t] = useTranslation('translation');
  const [onFilter] = useDebouncedCallback((filterable, page, pageSize) => {
    props.getTransactions(page + 1, pageSize, filterable);
  }, 1000);

  const navigateToTransaction = id => {
    props.history.push(`/portal/transactions/${id}`);
  };

  const { wallets } = props;
  const walletsList =
    wallets.length &&
    wallets.map(wallet => (
      <option value={wallet.id} key={wallet.id}>
        {wallet.name}
      </option>
    ));
  const columns = [
    {
      Header: t('Date'),
      accessor: 'date',
      Cell: row => (
        <div>
          {moment
            .utc(row.value)
            .local()
            .format('DD/MM/YYYY HH:mm:ss')}
        </div>
      ),
      Filter: ({ filter, onChange }) => (
        <DatePickerBlock>
          <DatePicker
            isClearable
            clearButtonTitle={t('Clear')}
            placeholderText={t('Choose date')}
            maxDate={new Date()}
            selected={filter ? new Date(filter.value) : null}
            onChange={date => {
              onChange(date ? moment(date).format('YYYY-MM-DD') : '');
            }}
          />
          <IconBlock>
            <Calendar />
          </IconBlock>
        </DatePickerBlock>
      )
    },
    {
      Header: t('IEP number'),
      accessor: 'iepNumber',
      Filter: ({ filter, onChange }) => (
        <input
          type="text"
          placeholder={t('Search...')}
          value={filter ? filter.value : ''}
          onChange={event => onChange(event.target.value)}
        />
      ),
      Cell: row => <div>{row.value && formatIEPNumber(row.value)}</div>
    },
    {
      Header: t('Wallet'),
      accessor: 'walletId',
      Cell: row => <div>{row.original.walletName}</div>,
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : ''}
        >
          <option value="">{t('All wallets')}</option>
          {walletsList}
        </select>
      )
    },
    {
      Header: t('Amount'),
      accessor: 'amount',
      Cell: row => (
        <div style={{ textAlign: 'right' }}>
          {row.value} {row.original.currencyCode}
        </div>
      ),
      Filter: ({ filter, onChange }) => (
        <input
          type="text"
          placeholder={t('Search...')}
          value={filter ? filter.value : ''}
          onChange={event => onChange(event.target.value)}
        />
      )
    },
    {
      Header: t('Approval code'),
      accessor: 'approvalCode',
      Filter: ({ filter, onChange }) => (
        <input
          type="text"
          placeholder={t('Search...')}
          value={filter ? filter.value : ''}
          onChange={event => onChange(event.target.value)}
        />
      )
    },
    {
      Header: t('Status'),
      accessor: 'transactionStatus',
      Cell: row => (
        <StatusLabel status={transactionStatusColor(row.value)}>
          {transactionStatus(row.value)}
        </StatusLabel>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : ''}
        >
          <option value="">{t('All statuses')}</option>
          <option value="1">Approved</option>
          <option value="2">Voided</option>
          <option value="3">Settled</option>
          <option value="4">Refunded</option>
          <option value="5">Declined</option>
          <option value="6">Failed</option>
        </select>
      )
    }
  ];

  return (
    <ReactTable
      // PaginationComponent={TableFooter}
      // eslint-disable-next-line no-shadow
      PaginationComponent={val => (
        <TableFooter
          {...props}
          {...val}
          exportUrl={
            props.exportUrl +
            getFilters(props.advancedSearchValues, val.filtered)
          }
          exportMethod={() => props.getTransactionsExportFile(1, 10)}
        />
      )}
      NoDataComponent={NoRowsFound}
      data={props.data}
      columns={columns}
      defaultPageSize={10}
      filterable={props.isFilterable}
      sortable={false}
      page={props.page}
      loading={props.loading}
      manual
      getTdProps={(state, rowInfo) => ({
        onClick: rowInfo
          ? () => navigateToTransaction(rowInfo.original.transactionId)
          : null
      })}
      pages={props.data && props.pageInfo && props.pageInfo.totalPage}
      onFetchData={({ page, pageSize, filtered }) => {
        props.setPageSize(pageSize, SET_TRANSACTION_TABLE_PAGE_SIZE);
        props.setPage(page, SET_TRANSACTION_TABLE_PAGE);
        if (
          currentPageRef.current === null ||
          currentPageRef.current !== page ||
          currentPageSizeRef.current === null ||
          currentPageSizeRef.current !== pageSize
        ) {
          currentPageRef.current = page;
          currentPageSizeRef.current = pageSize;
          props.getTransactions(page + 1, pageSize, filtered);
        } else {
          onFilter(filtered, page, pageSize);
        }
      }}
      minRows={1}
      className="-highlight transactions"
    />
  );
};

export default TransactionTable;
