import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
export const RowSelect = styled(Row)`
  display: flex;
  flex-direction: row;
  flex: 2;
  justify-content: flex-end;
  align-items: center;
  > div {
    margin-left: 10px;
  }
`;
export const IataLabel = styled.div`
  color: rgba(5, 34, 56, 0.56);
  font-size: 13px;
`;
export const PageHeader = styled.h2`
  flex: 1;
  color: rgba(5, 34, 56, 0.87);
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 0.86px;
  margin: 0 0 14px 0;
`;

export const TableHeader = styled.div`
  text-transform: uppercase;
  flex: 1;
`;

export const Total = styled.div`
  color: rgba(5, 34, 56, 0.56);
`;
export const TotalValue = styled.span`
  color: rgba(5, 34, 56, 0.87);
  font-weight: bold;
`;
export const ButtonUnderlined = styled.button`
  font-size: 14px;
  color: #004b87;
  border: none;
  outline: none;
  background: #fff;
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const RowEnd = styled(Row)`
  justify-content: flex-end;
`;

export const SubHeaderRoot = styled(Row)`
  border-top: 1px solid rgba(5, 34, 56, 0.07);
  border-bottom: 1px solid rgba(5, 34, 56, 0.07);
  padding: 18px 15px;
  margin: 0 -25px;
`;
export const BackButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
`;
