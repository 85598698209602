import React from 'react';
import { TableRow, Total, TotalValue } from './style';

const SubHeaderTotal = ({ totalName, totalAmount }) => (
  <TableRow>
    <Total>
      {totalName} <TotalValue>{totalAmount}</TotalValue>
    </Total>
  </TableRow>
);

export default SubHeaderTotal;
