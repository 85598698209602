export const fetchActionCreate = action => ({
  ACTION: action,
  REQUEST: `${action}_REQUEST`,
  SUCCESS: `${action}_SUCCESS`,
  FAILURE: `${action}_FAILURE`
});

export const FETCH_WALLETS = fetchActionCreate('FETCH_WALLETS');
export const SET_WALLETS_PAGE_SIZE = 'SET_WALLETS_PAGE_SIZE';
export const SET_WALLETS_PAGE = 'SET_WALLETS_PAGE';
export const FETCH_WALLET = fetchActionCreate('FETCH_WALLET');
export const FETCH_WALLET_TRANSACTIONS = fetchActionCreate(
  'FETCH_WALLET_TRANSACTIONS'
);
export const FETCH_WALLET_TRANSACTION_DETAIL = fetchActionCreate(
  'FETCH_WALLET_TRANSACTION_DETAIL'
);
export const FETCH_TRANSACTIONS = fetchActionCreate('FETCH_TRANSACTIONS');
export const FETCH_TRANSACTION_DETAILS = fetchActionCreate(
  'FETCH_TRANSACTION_DETAILS'
);
export const FETCH_TRANSACTION_EXPORT_FILE = fetchActionCreate(
  'FETCH_TRANSACTION_EXPORT_FILE'
);
export const FETCH_REPORTS = fetchActionCreate('FETCH_REPORTS');
export const FETCH_REPORTS_CHART = fetchActionCreate('FETCH_REPORTS_CHART');
export const FETCH_REPORTS_DETAILS = fetchActionCreate('FETCH_REPORTS_DETAILS');
export const FETCH_REPORTS_DETAILS_TRANSACTIONS = fetchActionCreate(
  'FETCH_REPORTS_DETAILS_TRANSACTIONS'
);
export const FETCH_REPORTS_AGENCIES = fetchActionCreate(
  'FETCH_REPORTS_AGENCIES'
);

export const FETCH_USERS_APPROVED = fetchActionCreate('FETCH_USERS_APPROVED');
export const FETCH_USER_DETAIL_NOT_APPROVED = fetchActionCreate(
  'FETCH_USER_DETAIL_NOT_APPROVED'
);
export const FETCH_USER_DETAIL_APPROVED = fetchActionCreate(
  'FETCH_USER_DETAIL_APPROVED'
);
export const FETCH_USERS_NOT_APPROVED = fetchActionCreate(
  'FETCH_USERS_NOT_APPROVED'
);
export const DELETE_USER = fetchActionCreate('DELETE_USER');
export const DISABLE_USER = fetchActionCreate('DISABLE_USER');
export const ENABLE_USER = fetchActionCreate('ENABLE_USER');
export const ADD_NEW_WALLET = fetchActionCreate('ADD_NEW_WALLET');
export const MAKE_TRANSFER = fetchActionCreate('MAKE_TRANSFER');
export const MAKE_WITHDRAW = fetchActionCreate('MAKE_WITHDRAW');
export const TOP_UP_BALANCE = fetchActionCreate('TOP_UP_BALANCE');
export const FETCH_TOP_UP_RESULT = fetchActionCreate('FETCH_TOP_UP_RESULT');
export const CLOSE_WALLET = fetchActionCreate('CLOSE_WALLET');
export const GENERATE_IEP_NUMBER = fetchActionCreate('GENERATE_IEP_NUMBER');
export const GET_IEP_NUMBER = fetchActionCreate('GET_IEP_NUMBER');
export const CLOSE_IEP_NUMBER = fetchActionCreate('CLOSE_IEP_NUMBER');
export const GET_ACCESSIBLE_WALLETS = fetchActionCreate(
  'GET_ACCESSIBLE_WALLETS'
);
export const INIT_APP = fetchActionCreate('INIT_APP');
export const ENABLE_COOKIE = 'ENABLE_COOKIE';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const GET_TERMS_DOCUMENT = fetchActionCreate('GET_TERMS_DOCUMENT');
export const TERMS_CONDITIONS_DOWNLOADED = 'TERMS_CONDITIONS_DOWNLOADED';
export const SET_ACCOUNT_CREATING_STATUS = fetchActionCreate(
  'SET_ACCOUNT_CREATING_STATUS'
);
export const SET_REJECT_ACCOUNT_CREATING_STATUS = fetchActionCreate(
  'SET_REJECT_ACCOUNT_CREATING_STATUS'
);
export const SET_ACCOUNT_CREATING_STATUS_CREATED =
  'SET_ACCOUNT_CREATING_STATUS_CREATED'; // TODO: remove
export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const GET_ACCESSIBLE_ROLES = fetchActionCreate('GET_ACCESSIBLE_ROLES');
export const GET_ACCESSIBLE_AGENCIES = fetchActionCreate(
  'GET_ACCESSIBLE_AGENCIES'
);
export const GET_BANK_ACCOUNTS = fetchActionCreate('GET_BANK_ACCOUNTS');
export const GET_BANKS = fetchActionCreate('GET_BANKS');
export const ADD_BANK_ACCOUNT = fetchActionCreate('ADD_BANK_ACCOUNT');
export const GET_ACTIVE_BANK_ACCOUNTS = fetchActionCreate(
  'GET_ACTIVE_BANK_ACCOUNTS'
);
export const ACTIVATE_BANK_ACCOUNT = fetchActionCreate('ACTIVATE_BANK_ACCOUNT');
export const DELETE_BANK_ACCOUNT = fetchActionCreate('DELETE_BANK_ACCOUNT');
// export const CHANGE_BANK_ACCOUNT = fetchActionCreate('CHANGE_BANK_ACCOUNT');
export const GET_AGENCIES = fetchActionCreate('GET_AGENCIES');
export const GET_INTERNAL_AGENCIES = fetchActionCreate('GET_INTERNAL_AGENCIES');
export const GET_USER_DATA_FOR_EDITING = fetchActionCreate(
  'GET_USER_DATA_FOR_EDITING'
);
export const SAVE_USER = fetchActionCreate('SAVE_USER');
export const UNSET_CURRENT_USER = 'UNSET_CURRENT_USER';
export const APPROVE_USER = fetchActionCreate('APPROVE_USER');
export const REJECT_USER = fetchActionCreate('REJECT_USER');
export const GET_USER_STATUSES = fetchActionCreate('GET_USER_STATUSES');
export const LOGIN_INTERNAL = fetchActionCreate('LOGIN_INTERNAL');
export const LOGOUT_INTERNAL = fetchActionCreate('LOGOUT_INTERNAL');
export const IS_DOCUMENT_LOADING = fetchActionCreate('IS_DOCUMENT_LOADING');

export const SET_IS_ADVANCED_SEARCH = 'SET_IS_ADVANCED_SEARCH';
export const SET_TRANSACTION_TABLE_PAGE_SIZE =
  'SET_TRANSACTION_TABLE_PAGE_SIZE';
export const SET_TRANSACTION_TABLE_PAGE = 'SET_TRANSACTION_TABLE_PAGE';
export const SET_REPORTS_TABLE_PAGE_SIZE = 'SET_REPORTS_TABLE_PAGE_SIZE';
export const SET_REPORTS_TABLE_PAGE = 'SET_REPORTS_TABLE_PAGE';
export const SET_SETTELMENT_TABLE_PAGE_SIZE = 'SET_SETTLEMENT_TABLE_PAGE_SIZE';
export const SET_SETTELMENT_TABLE_PAGE = 'SET_SETTELMENT_TABLE_PAGE';
export const SET_REPORTS_DETAILS_PAGE_SIZE = 'SET_REPORTS_DETAILS_PAGE_SIZE';
export const SET_REPORTS_DETAILS_PAGE = 'SET_REPORTS_DETAILS_PAGE';
export const HIDE_MENU = 'HIDE_MENU';
export const SHOW_MENU = 'SHOW_MENU';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
