// @flow
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '../components';

const ButtonBlock = styled.div`
  position: absolute;
  bottom: 25px;
  left: 140px;
`;
const Root = styled.div`
  margin-top: 5px;
`;
const Text = styled.p`
  margin: 5px 0;
`;
const UnableUser = props => {
  const [t] = useTranslation('translation');

  const enable = () => {
    props.enableUser(props.userId);
    props.closeModal();
  };

  return (
    <>
      <Root>
        <Text>{t('Are you sure you want to enable this user')}</Text>
        {/* <Text>Closed account will be visible under IEP portal but you cannot use it anymore</Text> */}
      </Root>
      <ButtonBlock>
        <Button primary title={t('Yes, enable user')} onClick={enable} />
      </ButtonBlock>
    </>
  );
};

export default UnableUser;
