import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Wallet from './Wallet';
import { addAlert } from '../../../redux/actions/alerts';
import {
  getWalletById,
  getWalletTransactions,
  generateIEPNumber
} from '../../../redux/actions/wallets';
import { openModal, closeModal } from '../../../redux/actions/modals';
import { isAgencySuspended } from '../../../utils/iepStatus';
import { isWithdrawAllowed } from '../../../utils/getUserRoleById';

const mapStateToProps = state => ({
  currentWallet: state.wallets.currentWallet,
  currentWalletTransactions: state.wallets.currentWalletTransactions,
  transactionPageInfo: state.wallets.transactionPageInfo,
  isModalOpen: state.modals.isModalOpen,
  modalTitle: state.modals.modalTitle,
  modalType: state.modals.modalType,
  errors: state.wallets.errors,
  isTransactionsLoading: state.wallets.isTransactionsLoading,
  isAgencySuspended: isAgencySuspended(state.appState.account.agencyStatus),
  isWithdrawAllowed: isWithdrawAllowed(state.appState.account.role)
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getWallet: getWalletById,
      generateIEPNumber,
      openModal,
      closeModal,
      getWalletTransactions,
      addAlert
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
