import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { destroy } from 'redux-form';
import TransactionsList from './TransactionsList';
import { openModal, closeModal } from '../../redux/actions/modals';
import {
  getTransactions,
  getTransactionsExportFile,
  setIsAdvancedSearch
} from '../../redux/actions/transactions';
import { setPage, setPageSize } from '../../redux/actions/commonActions';
import { getAccessibleWallets } from '../../redux/actions/wallets';
import { isAgencySuspended } from '../../utils/iepStatus';
import { isWithdrawAllowed } from '../../utils/getUserRoleById';

const mapStateToProps = state => ({
  transactions: state.transactions.transactions,
  isAdvancedSearch: state.transactions.isAdvancedSearch,
  isLoading: state.transactions.isLoading,
  isModalOpen: state.modals.isModalOpen,
  accessibleWallets: state.wallets.accessibleWallets,
  pageInfo: state.transactions.pageInfo,
  pageSize: state.transactions.pageSize,
  page: state.transactions.page,
  advancedTransactionSearch: state.form.advancedTransactionSearch,
  isAgencySuspended: isAgencySuspended(state.appState.account.agencyStatus),
  isWithdrawAllowed: isWithdrawAllowed(state.appState.account.role)
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      getTransactions,
      getAccessibleWallets,
      getTransactionsExportFile,
      setIsAdvancedSearch,
      setPageSize,
      setPage,
      destroyAdvancedSearchForm: () => destroy('advancedTransactionSearch')
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(TransactionsList);
