import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledMessage = styled.div`
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
`;

const NoRowsFound = props => {
  const [t] = useTranslation('translation');

  return (
    <StyledMessage style={{ ...props.style }}>
      {t('No rows found')}
    </StyledMessage>
  );
};

export default NoRowsFound;
