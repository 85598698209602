import React from 'react';

const CancelCard = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      className={props.className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h16v16H0z" />
        <path
          fill={props.blue ? '#004b87' : 'rgba(5,34,56,0.56)'}
          d="M8 .889A7.111 7.111 0 1 1 8 15.11 7.111 7.111 0 0 1 8 .89zM8 2.31a5.689 5.689 0 0 0-4.494 9.18l7.986-7.985A5.693 5.693 0 0 0 8 2.31zm0 11.38a5.689 5.689 0 0 0 4.494-9.18l-7.986 7.985A5.693 5.693 0 0 0 8 13.69z"
        />
      </g>
    </svg>
  );
};
export default CancelCard;
