/* eslint-disable consistent-return */
import { api } from '../../services/rest';
import {
  ENABLE_COOKIE,
  TERMS_CONDITIONS_DOWNLOADED,
  SET_ACCOUNT_CREATING_STATUS,
  INIT_APP,
  SET_ACCOUNT_CREATING_STATUS_CREATED,
  GET_TERMS_DOCUMENT,
  SET_REJECT_ACCOUNT_CREATING_STATUS,
  ADD_ALERT, // remove
  HIDE_MENU,
  SHOW_MENU,
  CHANGE_LOCALE
} from '../../constants/actionTypes';

export const hideMenu = () => ({
  type: HIDE_MENU
});

export const showMenu = () => ({
  type: SHOW_MENU
});

export const enableCookie = () => ({
  type: ENABLE_COOKIE
});

export const termsConditionsDownloaded = () => ({
  type: TERMS_CONDITIONS_DOWNLOADED
});

export const changeLocale = locale => dispatch =>
  dispatch({
    type: CHANGE_LOCALE,
    locale
  });

export const setAccountCreatingStatus = val => dispatch => {
  dispatch({ type: SET_ACCOUNT_CREATING_STATUS.REQUEST });

  return api.put(`loggedUser/termsAndConditions`, { accepted: val }).then(
    response => {
      if (response.responseCode === '000') {
        dispatch({
          type: SET_ACCOUNT_CREATING_STATUS.SUCCESS,
          payload: val
        });
        return Promise.resolve({ accepted: true });
      }
      dispatch({
        type: SET_ACCOUNT_CREATING_STATUS.FAILURE,
        payload: response
      });
      dispatch({
        type: ADD_ALERT,
        payload: { alertType: 'error', text: response.resDescription }
      });
    },
    err => {
      dispatch({ type: SET_ACCOUNT_CREATING_STATUS.FAILURE, payload: err });
      dispatch({
        type: ADD_ALERT,
        payload: { alertType: 'error', text: err.message }
      });
    }
  );
};

export const setReject = () => dispatch => {
  dispatch({ type: SET_REJECT_ACCOUNT_CREATING_STATUS.REQUEST });

  return api.put(`loggedUser/termsAndConditions`, { accepted: false }).then(
    response => {
      if (response.responseCode === '000') {
        return dispatch({
          type: SET_REJECT_ACCOUNT_CREATING_STATUS.SUCCESS,
          payload: false
        });
      }
      dispatch({
        type: SET_REJECT_ACCOUNT_CREATING_STATUS.FAILURE,
        payload: response
      });
      dispatch({
        type: ADD_ALERT,
        payload: { alertType: 'error', text: response.resDescription }
      });
    },
    err => {
      dispatch({ type: SET_ACCOUNT_CREATING_STATUS.FAILURE, payload: err });
      dispatch({
        type: ADD_ALERT,
        payload: { alertType: 'error', text: err.message }
      });
    }
  );
};

// TODO: remove in future (just for testing)
export const setAccountIsCreated = () => ({
  type: SET_ACCOUNT_CREATING_STATUS_CREATED
});

export const initApp = () => dispatch => {
  dispatch({ type: INIT_APP.REQUEST });

  return api.get(`loggedUser/userStatusesInfo`).then(
    response => {
      if (response.responseCode === '000') {
        dispatch({ type: INIT_APP.SUCCESS, payload: response });
        return response.data;
      }
      dispatch({ type: INIT_APP.FAILURE, payload: response });
      return Promise.reject();
    },
    err => {
      dispatch({ type: INIT_APP.FAILURE, payload: err });
      return Promise.reject();
    }
  );
};

export const getTermsFile = () => dispatch => {
  dispatch({ type: GET_TERMS_DOCUMENT.REQUEST });

  return api
    .get(`document/termsAndConditions`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/pdf',
        'Access-Control-Allow-Origin': '*'
      }
    })
    .then(
      response => {
        if (response.responseCode === '000') {
          dispatch({ type: GET_TERMS_DOCUMENT.SUCCESS, payload: response });
        } else {
          dispatch({ type: GET_TERMS_DOCUMENT.FAILURE, payload: response });
          dispatch({
            type: ADD_ALERT,
            payload: { alertType: 'error', text: response.resDescription }
          });
        }
      },
      err => {
        dispatch({ type: GET_TERMS_DOCUMENT.FAILURE, payload: err });
      }
    );
};
