// @flow
import React from 'react';
import styled from 'styled-components';
import Menu from './Menu';
import TopNavContainer from './TopNavContainer';

const Root = styled.header`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
`;

const Header = ({ isMenuShown }) => {
  return (
    <Root>
      <TopNavContainer isMenuShown={isMenuShown} />
      <Menu isMenuShown={isMenuShown} />
    </Root>
  );
};
export default Header;
