import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import store from './redux/configureStore';
import App from './core/App';
import { initApp, showMenu } from './redux/actions/appState';
import { addAlert } from './redux/actions/alerts';
import history from './core/history';
import i18n from './i18n/i18n';

const renderApp = () => {
  ReactDOM.render(
    <Suspense fallback="...loading">
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <App />
        </Provider>
      </I18nextProvider>
    </Suspense>,
    document.getElementById('root')
  );
};
if (
  window.location.href.includes('redirect-page?token') ||
  window.location.href.includes('redirect-internal-page?token')
) {
  renderApp();
} else {
  store
    .dispatch(initApp())
    .then(userInfo => {
      const page = {
        1: '/termsAndConditions',
        2: '/agencyAccountBeingCreated',
        3: '/userAccountBeingCreated',
        4: '/portal'
      }[userInfo.userNavigationState];

      if (!page) {
        store.dispatch(
          addAlert({
            alertType: 'error',
            text:
              'Wrong User Navigation state. Please correct user navigation state on API.'
          })
        );
      } else {
        // eslint-disable-next-line no-unused-expressions
        page === '/portal' ? store.dispatch(showMenu()) : history.push(page);
      }

      renderApp();
    })
    .catch(() => {
      console.log('init app failed');
    });
}
