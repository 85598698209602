// @flow
import React from 'react';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Button, FormattedAmount } from '../../components';
import { currencyFormat } from '../../../utils/currencyFormat';
import {
  getAccessibleWallets,
  makeTransfer
} from '../../../redux/actions/wallets';
import { closeModal } from '../../../redux/actions/modals';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
`;

const TextBlock = styled.div`
  margin-top: 8px;
  margin-bottom: 18px;
  line-height: 1.43;
`;
const ButtonProceed = styled(Button)`
  position: absolute;
  bottom: 24px;
  left: 140px;
`;
const Text = styled.p`
  margin: 2px;
`;
const GreyText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  color: rgba(5, 34, 56, 0.56);
  margin-right: 20px;
`;
const Label = styled.p`
  color: rgba(5, 34, 56, 0.87);
  font-size: 12px;
  margin-bottom: 5px;
  opacity: 0.56;
`;

const renderSelect = props => {
  const {
    input,
    meta: { touched, error },
    label,
    placeholder,
    options
  } = props;
  return (
    <div>
      <Label>{label}</Label>
      <Select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        isSearchable={false}
        placeholder={placeholder}
        error={!touched && error}
        options={options}
        maxMenuHeight={100}
        onBlur={() => input.onBlur(input.value)}
      />
    </div>
  );
};
const validate = values => {
  const errors = {};
  if (!values.amount || Number.isNaN(Number(values.amount))) {
    errors.amount = 'Invalid amount';
  }

  if (parseFloat(values.amount) === 0) {
    errors.amount = 'Has to be more than 0';
  }

  if (!values.transferToWallet) {
    errors.transferToWallet = 'Cannot be empty';
  }
  return errors;
};
// eslint-disable-next-line react/jsx-props-no-spreading
const WalletsField = props => <Field component={renderSelect} {...props} />;

class Transfer extends React.Component {
  componentDidMount() {
    // const { walletId } = this.props.modalData;
    this.props.getAccessibleWallets(true);
  }

  handleTransferFormSubmit = val => {
    const { walletId, currencyCode } = this.props.modalData;

    const data = {
      // eslint-disable-next-line radix
      receiverWalletUniqueId: val.transferToWallet.value,
      amount: parseFloat(val.amount),
      currencyCode
    };
    this.props.makeTransfer(walletId, JSON.stringify(data));
    this.props.closeModal();
  };

  render() {
    const selectOptions = this.props.wallets
      ? this.props.wallets
          .filter(w => w.id !== this.props.modalData.walletId)
          .map(wallet => {
            return { value: wallet.id, label: wallet.name };
          })
      : [];

    return (
      <Root>
        <TextBlock>
          {this.props.t('Transfer your money to any wallet you want')}
        </TextBlock>
        <Row>
          <GreyText>{this.props.t('Existing balance')}</GreyText>
          <Text>
            {this.props.modalData
              ? `${currencyFormat(this.props.modalData.availableBalance || 0)} `
              : ''}
            {this.props.modalData ? this.props.modalData.currencyCode : ''}
          </Text>
        </Row>
        <form onSubmit={this.props.handleSubmit(this.handleTransferFormSubmit)}>
          <WalletsField
            name="transferToWallet"
            options={selectOptions || null}
            label={this.props.t('Transfer to wallet')}
            placeholder={this.props.t('Select wallet')}
          />
          <FormattedAmount
            name="amount"
            required
            placeholder={this.props.t('Enter transfer amount')}
            label={`${this.props.t('Transfer amount')} *`}
            decimalPoints={this.props.decimalPoints}
            currencyCode={
              this.props.modalData ? this.props.modalData.currencyCode : ''
            }
            id="transferAmount"
          />
          <ButtonProceed
            primary
            title={this.props.t('Confirm transfer')}
            type="submit"
            disabled={this.props.invalid}
          />
        </form>
      </Root>
    );
  }
}

// eslint-disable-next-line no-class-assign
Transfer = reduxForm({
  form: 'transfer',
  validate
})(Transfer);

const mapStateToProps = state => ({
  modalData: state.modals.modalData,
  wallets: state.wallets.accessibleWallets,
  decimalPoints: state.appState.account.decimalPoints
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAccessibleWallets,
      makeTransfer,
      closeModal
    },
    dispatch
  );
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translation')
)(Transfer);
