import React from 'react';

const SuccessInfo = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      className={props.className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#00B326"
          fillRule="nonzero"
          d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm5.247 4.341l1.506 1.318-7.704 8.804-4.756-4.756 1.414-1.414 3.244 3.244 6.296-7.196z"
        />
      </g>
    </svg>
  );
};
export default SuccessInfo;
