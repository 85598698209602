import moment from 'moment';
import isNil from 'lodash.isnil';

export const formatWeeklyDailyPeriod = ({ startDate, endDate }) =>
  `${moment(startDate).format('DD/MM/YYYY')}${
    endDate ? ` - ${moment(endDate).format('DD/MM/YYYY')}` : ''
  }`;

export const formatMonthlyPeriod = startDate =>
  moment(startDate).format('MMM YYYY');

export const getCorrectPeriod = ({ startDate, endDate }) =>
  !isNil(endDate)
    ? { startDate, endDate }
    : {
        startDate: moment(startDate).startOf('month'),
        endDate: moment(startDate).endOf('month')
      };
