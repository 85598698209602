import {
  FETCH_REPORTS,
  FETCH_REPORTS_CHART,
  SET_REPORTS_TABLE_PAGE,
  SET_REPORTS_TABLE_PAGE_SIZE,
  FETCH_REPORTS_AGENCIES
} from '../../constants/actionTypes';

export const initState = {
  reports: [],
  chartReports: [],
  dropdowns: [],
  isLoading: false,
  pageInfo: null,
  pageSize: 10,
  page: 0,
  pagedTotal: {}
};

export default {
  [FETCH_REPORTS.REQUEST]: state => {
    return {
      ...state,
      isLoading: true
    };
  },
  [FETCH_REPORTS.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      reports: payload.data,
      isLoading: false,
      pageInfo: payload.pageInfo,
      pagedTotal: payload.pagedTotal
    };
  },
  [FETCH_REPORTS.FAILURE]: state => {
    return {
      ...state,
      isLoading: false
    };
  },
  [FETCH_REPORTS_CHART.REQUEST]: state => {
    return {
      ...state,
      isLoading: true
    };
  },
  [FETCH_REPORTS_CHART.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      chartReports: payload.data,
      pageInfo: payload.pageInfo,
      isLoading: false
    };
  },
  [FETCH_REPORTS_CHART.FAILURE]: state => {
    return {
      ...state,
      isLoading: false
    };
  },
  [SET_REPORTS_TABLE_PAGE]: (state, { page }) => {
    return {
      ...state,
      page
    };
  },
  [SET_REPORTS_TABLE_PAGE_SIZE]: (state, { pageSize }) => {
    return {
      ...state,
      pageSize
    };
  },
  [FETCH_REPORTS_AGENCIES.REQUEST]: state => {
    return {
      ...state,
      isLoading: true
    };
  },
  [FETCH_REPORTS_AGENCIES.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      agencies: payload.data.agencies,
      isLoading: false
    };
  },
  [FETCH_REPORTS_AGENCIES.FAILURE]: state => {
    return {
      ...state,
      isLoading: false
    };
  }
};
