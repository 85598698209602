import axios from 'axios';
import publicIp from 'public-ip';
import history from '../core/history';
import store from '../redux/configureStore';

const API =
  process.env.REACT_APP_HOST_API === 'null'
    ? ''
    : process.env.REACT_APP_HOST_API;

const DEFAULT_HEADERS = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
};

(async () => {
  const ip = await publicIp.v4();
  console.log(ip);
  axios.defaults.headers.common['X-Forwarded-For'] = ip;
})();

axios.interceptors.request.use(request => {
  request.headers.Culture = store.getState().appState.locale.culture;
  return request;
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      if (error.response.status === 404) {
        return;
      }
      if (error.response.status === 401) {
        window.location = error.response.data.RedirectUrl;
        localStorage.setItem('returnUrl', history.location.pathname);
        return;
      }
      if (error.response.status === 403) {
        history.push('/portal/accessDenied');
        return;
      }
      if (error.response.status === 503) {
        // window.location = '/503'
        console.log('503!!!!!!!!', error.response.data);
      }
    }
  }
);

export const api = {
  handleError(res) {
    console.error(res);
  },

  getUrl(url) {
    return `${API}/v1/${url}`;
  },

  getNotVersionedUrl(url) {
    return `${API}/${url}`;
  },

  post(url, body) {
    return axios
      .post(this.getUrl(url), body, DEFAULT_HEADERS)
      .then(response => response.data);
  },

  get(url, params) {
    return axios
      .get(this.getUrl(url), params, DEFAULT_HEADERS)
      .then(response => response.data);
  },

  put(url, params) {
    return axios
      .put(this.getUrl(url), params, DEFAULT_HEADERS)
      .then(response => response.data);
  },

  delete(url, params) {
    return axios
      .delete(this.getUrl(url), params, DEFAULT_HEADERS)
      .then(response => response.data);
  }
};

export default api;
