import React from 'react';
import styled from 'styled-components';
import Question from '../../icons/Question';

const IEPnumberTooltip = styled.div`
  border: 1px solid #ccc;
  padding: 4px;
  border-radius: 2px;
  color: rgba(5, 34, 56, 0.56);
  position: absolute;
  font-size: 12px;
  line-height: 1.33;
  bottom: 40px;
  text-align: left;
  display: none;
  background-color: #fff;
`;

const StyledDiv = styled.div`
  margin-left: 16px;
  &:hover + ${IEPnumberTooltip} {
    display: flex;
  }
`;

const QuestionIcon = ({ questionIcon, tooltipText }) => (
  <div>
    {questionIcon && (
      <StyledDiv>
        <Question />
      </StyledDiv>
    )}
    <IEPnumberTooltip>{tooltipText}</IEPnumberTooltip>
  </div>
);

export default QuestionIcon;
