/* eslint-disable consistent-return */
import axios from 'axios';
import { LOGIN_INTERNAL, LOGOUT_INTERNAL } from '../../constants/actionTypes';
import { api } from '../../services/rest';
import {
  INTERNAL_TOKEN_KEY,
  INTERNAL_USER_IATA_CODE_KEY
} from '../../components/RedirectPage';
import { initApp, showMenu, hideMenu } from './appState';
import history from '../../core/history';

export const loginInternalRequest = code => dispatch => {
  dispatch({ type: LOGIN_INTERNAL.REQUEST });

  const url = api.getNotVersionedUrl(`Account/LoginInternal?code=${code}`);
  const token = localStorage.getItem(INTERNAL_TOKEN_KEY);

  return axios.get(url, { headers: { authorization: `Bearer ${token}` } }).then(
    response => {
      if (response && response.status === 200) {
        localStorage.setItem(INTERNAL_USER_IATA_CODE_KEY, code);

        dispatch({
          type: LOGIN_INTERNAL.SUCCESS,
          payload: { code }
        });

        return dispatch(initApp()).then(() => {
          dispatch(showMenu());
          history.push('/');
        });
      }
      dispatch({ type: LOGIN_INTERNAL.FAILURE, response });
    },
    err => dispatch({ type: LOGIN_INTERNAL.FAILURE, err })
  );
};

export const logoutInternalRequest = () => dispatch => {
  dispatch({ type: LOGOUT_INTERNAL.REQUEST });

  const url = api.getNotVersionedUrl('Account/LogoutInternal');

  return axios.get(url).then(
    response => {
      if (response && response.status === 200) {
        history.push('/internal-code');

        dispatch(hideMenu());
        return dispatch({
          type: LOGOUT_INTERNAL.SUCCESS
        });
      }
      dispatch({ type: LOGOUT_INTERNAL.FAILURE, response });
    },
    err => dispatch({ type: LOGOUT_INTERNAL.FAILURE, err })
  );
};
