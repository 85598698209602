import React from 'react';

const LastPage = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width="16"
      height="16"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M16 16V0H0v16z" />
        <path
          fill="#052238"
          fillOpacity=".56"
          d="M6.657 2.343L1 8l5.657 5.657V2.343zm6 0L7 8l5.657 5.657V2.343z"
        />
      </g>
    </svg>
  );
};
export default LastPage;
