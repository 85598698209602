import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getReportsAgencies } from '../../redux/actions/reports';
import Reports from './Reports';

const mapStateToProps = state => ({
  agencies: state.reports.agencies,
  isLoading: state.reports.isLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getReportsAgencies
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
