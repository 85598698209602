import React from 'react';

const FailedTransaction = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      className={props.className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#E91414"
          fillOpacity=".87"
          d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm0-2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z"
        />
        <path
          fill="#E91414"
          fillOpacity=".87"
          d="M16.914 8.429l-3.571 3.57 3.571 3.572-1.414 1.414-3.571-3.571-3.5 3.5L7.015 15.5l3.5-3.5-3.5-3.5 1.414-1.414 3.5 3.5L15.5 7.014l1.414 1.414z"
        />
      </g>
    </svg>
  );
};
export default FailedTransaction;
