/* eslint-disable no-unused-vars */
import {
  GET_BANK_ACCOUNTS,
  ADD_BANK_ACCOUNT,
  GET_ACTIVE_BANK_ACCOUNTS,
  // CHANGE_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNT,
  ACTIVATE_BANK_ACCOUNT,
  GET_BANKS
} from '../../constants/actionTypes';

export const initState = {
  errors: null,
  isLoading: false,
  activeAccounts: [],
  bankAccounts: [],
  banks: []
};

export default {
  [GET_BANK_ACCOUNTS.REQUEST]: state => {
    return {
      ...state,
      isLoading: true
    };
  },
  [GET_BANK_ACCOUNTS.SUCCESS]: (state, payload) => {
    const bankAccounts = payload.payload.data;
    return {
      ...state,
      bankAccounts,
      isLoading: false
    };
  },
  [GET_BANK_ACCOUNTS.FAILURE]: (state, { code }) => {
    return { ...state, isLoading: false };
  },
  [GET_ACTIVE_BANK_ACCOUNTS.REQUEST]: state => {
    return {
      ...state
    };
  },
  [GET_ACTIVE_BANK_ACCOUNTS.SUCCESS]: (state, payload) => {
    const activeAccounts = payload.payload.data;
    return {
      ...state,
      activeAccounts
    };
  },
  [GET_ACTIVE_BANK_ACCOUNTS.FAILURE]: (state, { code }) => {
    return { ...state };
  },
  [ADD_BANK_ACCOUNT.REQUEST]: state => {
    return {
      ...state,
      errors: null
    };
  },
  [ADD_BANK_ACCOUNT.SUCCESS]: (state, { data }) => {
    return {
      ...state,
      bankAccounts: state.bankAccounts.concat(data.data),
      errors: null
    };
  },
  [ADD_BANK_ACCOUNT.FAILURE]: (state, { response }) => {
    return {
      ...state,
      errors: response.ResDescription
    };
  },
  // [CHANGE_BANK_ACCOUNT.REQUEST]: state => {
  //   return {
  //     ...state
  //   };
  // },
  // [CHANGE_BANK_ACCOUNT.SUCCESS]: (state, { data }) => {
  //   return {
  //     ...state
  //     //    bankAccounts: state.bankAccounts.concat(data.data),
  //   };
  // },
  // [CHANGE_BANK_ACCOUNT.FAILURE]: (state, response) => {
  //   return {
  //     ...state
  //   };
  // },
  [GET_BANKS.REQUEST]: state => {
    return {
      ...state
    };
  },
  [GET_BANKS.SUCCESS]: (state, payload) => {
    const banks = payload.payload.data;
    return {
      ...state,
      banks
    };
  },
  [GET_BANKS.FAILURE]: (state, { code }) => {
    return { ...state };
  }
};
