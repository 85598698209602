import moment from 'moment';
import {
  DURATION_LAST_YEAR,
  DURATION_LAST_QUARTER,
  DURATION_LAST_MONTH,
  DURATION_LAST_WEEK,
  FREQUENCY_MONTHLY,
  FREQUENCY_WEEKLY
} from '../components/Reports/ReportsConstants';

const getStartEndDate = period => {
  switch (period) {
    case DURATION_LAST_YEAR:
      return {
        startDate: new Date(
          moment()
            .subtract(1, 'years')
            .startOf('month')
        ),
        endDate: new Date(moment().endOf('month'))
      };
    case DURATION_LAST_QUARTER:
      return {
        startDate: new Date(
          moment()
            .subtract(2, FREQUENCY_MONTHLY)
            .startOf('month')
        ),
        endDate: new Date(moment().endOf('month'))
      };
    case DURATION_LAST_MONTH:
      return {
        startDate: new Date(
          moment()
            .subtract(1, FREQUENCY_MONTHLY)
            .startOf('month')
        ),
        endDate: new Date(
          moment()
            .subtract(1, FREQUENCY_MONTHLY)
            .endOf('month')
        )
      };
    case DURATION_LAST_WEEK:
      return {
        startDate: new Date(
          moment()
            .subtract(1, FREQUENCY_WEEKLY)
            .startOf('week')
        ),
        endDate: new Date(
          moment()
            .subtract(1, FREQUENCY_WEEKLY)
            .endOf('week')
        )
      };
    default:
      return { startDate: null, endDate: null };
  }
};

export default getStartEndDate;
