export const userStatusById = id => {
  return (
    {
      1: 'Pending',
      2: 'Approved',
      3: 'Disagree',
      4: 'Rejected',
      5: 'Active',
      6: 'Inactive',
      7: 'Deleted'
    }[id] || ''
  );
};
