export const iepStatuses = {
  1: 'No IEP Account',
  2: 'Declined By Agent',
  3: 'In Progress',
  4: 'Master Wallet Creation In Progress',
  5: 'Open',
  6: 'Rejected By IEP Supplier',
  7: 'Blocked',
  8: 'Closed 2 Years',
  9: 'Closed 3 Months',
  10: 'Suspended',
  11: 'Rejected By IATA',
  12: 'Master Wallet Created'
};

export const iepStatus = status => {
  return iepStatuses[status] || '-';
};

export const isAgencySuspended = status => iepStatus(status) === 'Suspended';
export const isAgencyClosed = status => {
  const statusText = iepStatus(status);

  return statusText === 'Closed 2 Years' || statusText === 'Closed 3 Months';
};
