import React from 'react';

const Trash = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      className={props.className}
    >
      <path
        fill="rgba(233,20,20,0.87)"
        fillRule="evenodd"
        d="M15 6l-.79 11.071a1 1 0 0 1-.998.929H4.788a1 1 0 0 1-.997-.929L3 6h12zM6 1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1h3.523a1 1 0 0 1 .928.629L16.6 3a.73.73 0 0 1-.677 1H2.077A.73.73 0 0 1 1.4 3l.149-.371A1 1 0 0 1 2.477 2H6V1z"
      />
    </svg>
  );
};
export default Trash;
