// @flow
import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Card, Button, PageHeader } from '../components';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const TermsInfo = styled.div`
  line-height: 1.43;
  letter-spacing: 0.3px;
`;

const InlineComponent = styled.div`
  display: inline-flex;
  margin-top: 0px;
`;
const ButtonBack = styled(Button)`
  margin-top: 16px;
`;

const AccountIsBeingCreated = ({ header }) => {
  const [t] = useTranslation('translation');
  return (
    <div>
      <PageHeader>{header}</PageHeader>
      <Card>
        <Column>
          <TermsInfo>
            <Trans i18nKey="Account is in the process of being created" t={t} />
          </TermsInfo>
          <InlineComponent>
            <a href="https://portal.iata.org/s/">
              <ButtonBack title={t('Back to IATA customer portal')} primary />
            </a>
          </InlineComponent>
        </Column>
      </Card>
    </div>
  );
};

export default AccountIsBeingCreated;
