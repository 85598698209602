/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { userStatusById } from '../../utils/userStatusById';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
const Text = styled.div`
  flex: 3;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  margin: 4px 0;
`;
const GreyText = styled(Text)`
  flex: 1;
  color: rgba(5, 34, 56, 0.56);
`;
const StatusLabel = styled.div`
  display: inline-flex;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: 0.2px;
  padding: 2px 4px;
  border-radius: 2px;
  color: ${props =>
    props.status === 2 || props.status === 5
      ? '#00b326'
      : props.status === 3 ||
        props.status === 4 ||
        props.status === 6 ||
        props.status === 7
      ? 'rgba(233, 20, 20, 0.87)'
      : '#fac832'};
  background: ${props =>
    props.status === 2 || props.status === 5
      ? 'rgba(0,179,38, 0.15)'
      : props.status === 3 ||
        props.status === 4 ||
        props.status === 6 ||
        props.status === 7
      ? 'rgba(233, 20, 20, 0.15)'
      : 'rgba(250,200,50,0.15)'};
`;

const MainUserInformation = ({ status, code, email }) => {
  const [t] = useTranslation('translation');
  return (
    <>
      <Row>
        <GreyText>{t('User status')}</GreyText>
        <Text>
          <StatusLabel status={status}>{userStatusById(status)}</StatusLabel>
        </Text>
      </Row>
      <Row>
        <GreyText>{t('Email')}</GreyText>
        <Text>{email}</Text>
      </Row>
      <Row>
        <GreyText>{t('IATA Code')}</GreyText>
        <Text>{code}</Text>
      </Row>
      {/* <Row>
        <GreyText>Registered wallets</GreyText>
        <Text>{wallets}</Text>
      </Row> */}
    </>
  );
};

export default MainUserInformation;
