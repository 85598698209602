import { IS_DOCUMENT_LOADING } from '../../constants/actionTypes';

export const initState = {
  isLoading: false
};

export default {
  [IS_DOCUMENT_LOADING.REQUEST]: state => ({
    ...state,
    isLoading: true
  }),
  [IS_DOCUMENT_LOADING.SUCCESS]: state => ({
    ...state,
    isLoading: false
  }),
  [IS_DOCUMENT_LOADING.FAILURE]: state => ({
    ...state,
    isLoading: false
  })
};
