const colorConstant = {
  kellyGreen: '#00b326',
  brandDarkBlue: '#052238',
  brandBlue: '#004b87',
  deepSeaBlue: '#004b87',
  navy12: '#052238',
  navy56: '#052238',
  brandWhite: '#ffffff',
  tomato: '#e91414',
  brandYellow: '#f9c00c'
};

const color = {
  primary: colorConstant.brandBlue,
  secondary: colorConstant.navy12,
  brandDarkGrey: colorConstant.brandViolet,
  white: colorConstant.brandWhite,
  error: colorConstant.brandRed,
  green: colorConstant.brandGreen,
  yellow: colorConstant.brandYellow,
  background: {
    blue: colorConstant.brandBlue,
    default: colorConstant.white,
    grey: colorConstant.navy12
  },
  font: {
    default: colorConstant.brandDarkGrey,
    inactive: colorConstant.navy12,
    grey: colorConstant.brandGrey,
    white: colorConstant.brandWhite,
    blue: colorConstant.brandBlue,
    error: colorConstant.brandRed
  }
};

const border = {
  default: `1px solid rgba(5, 34, 56, 0.25)`,
  white: '1px solid #ffffff',
  radius: {
    small: '2px',
    default: '5px',
    oval: '25px',
    round: '100%'
  }
};
const opacity = {
  default: 0.7
};

const theme = {
  border,
  color,
  colorConstant,
  opacity
};

export default theme;
