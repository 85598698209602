import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Settings from './Settings';
import { closeModal } from '../../redux/actions/modals';
import {
  addBankAccount,
  deleteBankAccount,
  activateBankAccount
} from '../../redux/actions/bankAccounts';

const mapStateToProps = state => state.modals;
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeModal,
      addBankAccount,
      deleteBankAccount,
      activateBankAccount
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
