import React, { useEffect } from 'react';
import styled from 'styled-components';
import Alert from './Alert';

const AlertBlock = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: ${props => (props.isVisible ? '70px' : '0')};
  overflow: hidden;
`;
const Alerts = props => {
  const { alerts, removeAlert } = props;
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (alerts.length) {
      const timer = setTimeout(() => {
        removeAlert();
      }, 7000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts]);

  return (
    <AlertBlock isVisible={alerts.length}>
      {alerts.length
        ? alerts.map((alert, i) => (
            <Alert
              type={alert.alertType}
              text={alert.text}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              onClose={props.removeAlert}
            />
          ))
        : null}
    </AlertBlock>
  );
};

export default Alerts;
