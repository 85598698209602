// @flow
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Card, Button } from '../../components';
import QuestionIcon from './QuestionIcon';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
const CardMarginLeft = styled(Card)`
  margin-left: 24px;
  padding: 16px;
`;
const UppercaseHeader = styled.h5`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  height: 24px;
  text-transform: uppercase;
  margin: 0;
`;
const GreyText = styled.div`
  color: rgba(5, 34, 56, 0.56);
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.3px;
  margin: 10px 0;
`;
const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

const ButtonStyled = styled(Button)`
  width: 193px;
  height: 50px;
`;

const WalletCard = ({
  title,
  text,
  buttonTitle,
  buttonClick,
  disabled,
  isMultiUseIepnAllowed,
  questionIcon
}) => {
  const [t] = useTranslation('translation');

  return (
    <CardMarginLeft>
      <Root>
        <UppercaseHeader>{title}</UppercaseHeader>
        {text && <GreyText>{text}</GreyText>}
        <ButtonContainer>
          <ButtonStyled
            primary
            title={buttonTitle}
            onClick={() => buttonClick('single-use')}
            disabled={disabled}
          />
          <QuestionIcon
            questionIcon={questionIcon}
            tooltipText={t(
              'One-time-use IEP number, cannot be reused for next transaction'
            )}
          />
        </ButtonContainer>
        {isMultiUseIepnAllowed && (
          <ButtonContainer>
            <ButtonStyled
              primary
              title={t('Generate multi-use IEPN')}
              onClick={() => buttonClick('multi-use')}
              disabled={disabled}
            />
            <QuestionIcon
              questionIcon={questionIcon}
              tooltipText={t(
                'Multi-use IEP number, reusable within expiry date'
              )}
            />
          </ButtonContainer>
        )}
      </Root>
    </CardMarginLeft>
  );
};

export default WalletCard;
