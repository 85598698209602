/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { openModal, closeModal } from '../../../redux/actions/modals';
import { addAlert, removeAlert } from '../../../redux/actions/alerts';
import { activateBankAccount } from '../../../redux/actions/bankAccounts';
import { Button } from '../../components';
import { Trash } from '../../icons';
import { ShowForPermission } from '../../ShowForPermission';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 16px;
  border-width: 2px;
  border-radius: 3px;
  border-style: solid;
  border-color: ${props =>
    props.status === 1
      ? '#fac832'
      : props.status === 5
      ? '#00b326'
      : '#F2F3F5'};
  width: 260px;
  height: 105px;
  justify-content: space-between;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BankName = styled.h5`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
`;
const DeleteButton = styled.button`
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
`;
const GreyText = styled.p`
  color: rgba(5, 34, 56, 0.56);
  font-size: 12px;
  margin: 0;
`;
const RedText = styled(GreyText)`
  color: #e91414;
`;
const StatusLabel = styled.div`
  display: inline-flex;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: 0.2px;
  padding: 2px 4px;
  border-radius: 2px;
  color: ${props =>
    props.status === 5
      ? '#00b326'
      : props.status === 2
      ? 'rgba(5, 34, 56, 0.87)'
      : '#fac832'};
  background: ${props =>
    props.status === 5
      ? 'rgba(0,179,38, 0.15)'
      : props.status === 2
      ? 'rgba(5, 34, 56, 0.1)'
      : 'rgba(250,200,50,0.15)'};
`;

const balance = 10;

const BankAccount = props => {
  const [t] = useTranslation('translation');

  const activateBank = () => {
    props.activateBankAccount(props.id);
  };

  return (
    <>
      {(props.status === 5 || props.status === 2 || props.status === 1) && (
        <Root status={props.status}>
          <Row>
            <BankName>{props.name}</BankName>
            <StatusLabel status={props.status}>
              {props.status === 1
                ? 'Pending'
                : props.status === 2
                ? 'Approved'
                : props.status === 5
                ? 'Active'
                : props.status === 3
                ? 'Inactive'
                : 'Deleted'}
            </StatusLabel>
          </Row>

          {props.number}
          {props.status === 5 ? (
            balance ? (
              <GreyText>
                {t(
                  'This bank account is using for all withdrawal transactions'
                )}{' '}
              </GreyText>
            ) : (
              <RedText>
                {t(
                  'This bank account is empty. Please activate another bank account to withdraw funds'
                )}
              </RedText>
            )
          ) : props.status === 1 ? (
            <GreyText>
              {t(
                'Your bank account is registered and pending to approval. Account review is done in 5 working days'
              )}
            </GreyText>
          ) : props.status === 2 ? (
            <Row>
              <ShowForPermission contextName="BankAccount" action="Update">
                <Button title={t('Activate')} primary onClick={activateBank} />
              </ShowForPermission>
              <ShowForPermission contextName="BankAccount" action="Delete">
                <DeleteButton
                  onClick={() =>
                    props.openModal('deleteBank', t('Delete bank account'), {
                      id: props.id,
                      number: props.number
                    })
                  }
                >
                  <Trash />
                </DeleteButton>
              </ShowForPermission>
            </Row>
          ) : null}
        </Root>
      )}
    </>
  );
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      addAlert,
      removeAlert,
      activateBankAccount
    },
    dispatch
  );
export default connect(null, mapDispatchToProps)(BankAccount);
