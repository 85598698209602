import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Transaction from './Transaction';
import { getTransactionDetails } from '../../../redux/actions/transactions';

const mapStateToProps = state => ({
  transactions: state.transactions.transactions,
  transaction: state.transactions.currentTransaction,
  isLoading: state.transactions.isLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTransactionDetails
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
