/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Card, Button, InlineLoader as Loader } from '../../components';
import { ShowForPermission } from '../../ShowForPermission';
import { UppercaseHeader, Row } from '../Agencies/Agencies';
import BankAccount from './BankAccount';

const GreyText = styled.div`
  color: rgba(5, 34, 56, 0.56);
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.3px;
  margin: 10px 0 20px 0;
`;

const GreyTextM2 = styled(GreyText)`
  width: 70%;
`;

const Column = styled(Row)`
  flex-direction: column;
`;

const ButtonStyled = styled(Button)`
  max-width: 300px;
  height: 50px;
`;

const BankCard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 16px;
  border: 2px solid #f2f3f5;
  border-radius: 3px;
  width: 260px;
  height: 105px;
  button {
    width: 200px;
  }
`;

const BankAccounts = ({
  getBanks,
  getBankAccounts,
  openModal,
  bankAccounts,
  isLoading
}) => {
  const [t] = useTranslation('translation');
  useEffect(() => {
    getBankAccounts();
  }, [getBankAccounts]);

  const registerBank = () => {
    getBanks();
    openModal('bank', t('Register new bank account'));
  };

  const bankAccountList =
    bankAccounts &&
    bankAccounts.map(bank => (
      <BankAccount
        key={bank.id}
        name={bank.accountName}
        id={bank.id}
        status={bank.status}
        number={bank.accountNumber}
      />
    ));

  return (
    <Card>
      <UppercaseHeader>{t('Bank Account')}</UppercaseHeader>
      {isLoading ? (
        <Loader />
      ) : bankAccounts && !bankAccounts.length ? (
        <ShowForPermission contextName="BankAccount" action="Create">
          <Column>
            <GreyTextM2>{t('Please register your bank account')}</GreyTextM2>
            <ButtonStyled
              primary
              title={t('Register Bank Account')}
              onClick={registerBank}
            />
          </Column>
        </ShowForPermission>
      ) : (
        <Row>
          {bankAccountList}
          <ShowForPermission contextName="BankAccount" action="Create">
            {bankAccounts && bankAccounts.length === 1 && (
              <BankCard>
                <GreyText>{t('You can have another bank account')}</GreyText>
                <ButtonStyled
                  secondary
                  title={t('Register Bank Account')}
                  onClick={registerBank}
                />
              </BankCard>
            )}
          </ShowForPermission>
        </Row>
      )}
    </Card>
  );
};

export default BankAccounts;
