import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { hasPermission } from '../utils/hasPermission';

const ShowForPermission = ({ contexts, contextName, action, children }) => {
  const showComponent = hasPermission({ contexts, contextName, action });
  if (typeof children === 'function') {
    return children(showComponent);
  }
  if (showComponent) {
    return children || null;
  }
  return null;
};

const ShowForAllPermissions = ({ contexts, permissions, children }) => {
  if (
    permissions.every(({ contextName, action }) =>
      hasPermission({ contexts, contextName, action })
    )
  ) {
    return children;
  }
  return null;
};

const ShowForSomePermissions = ({ contexts, permissions, children }) => {
  if (
    permissions.some(({ contextName, action }) =>
      hasPermission({ contexts, contextName, action })
    )
  ) {
    return children;
  }
  return null;
};

const contextNames = [
  'Wallet',
  'Transaction',
  'Withdrawal',
  'BankAccount',
  'Agency',
  'IEPN',
  'Transfer',
  'Topup',
  'User',
  'Report'
];

const actions = ['Create', 'Read', 'Update', 'Delete'];

ShowForPermission.propTypes = {
  contexts: PropTypes.arrayOf(
    PropTypes.shape({
      contextNameString: PropTypes.oneOf(contextNames),
      actions: PropTypes.arrayOf(PropTypes.oneOf(actions))
    })
  ),
  contextName: PropTypes.oneOf(contextNames),
  action: PropTypes.oneOf(actions)
};

const mapStateToProps = state => ({
  contexts: state.appState.account.accesses
    ? state.appState.account.accesses.contexts
    : []
});

const connectedShowForPermission = connect(mapStateToProps)(ShowForPermission);
const connectedShowForAllPermissions = connect(mapStateToProps)(
  ShowForAllPermissions
);
const connectedShowForSomePermissions = connect(mapStateToProps)(
  ShowForSomePermissions
);

export {
  connectedShowForPermission as ShowForPermission,
  connectedShowForAllPermissions as ShowForAllPermissions,
  connectedShowForSomePermissions as ShowForSomePermissions
};
