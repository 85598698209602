/* eslint-disable consistent-return */
import {
  GET_BANK_ACCOUNTS,
  ADD_BANK_ACCOUNT,
  GET_ACTIVE_BANK_ACCOUNTS,
  DELETE_BANK_ACCOUNT,
  ACTIVATE_BANK_ACCOUNT,
  GET_BANKS
} from '../../constants/actionTypes';
import { addAlert } from './alerts';
import { api } from '../../services/rest';
import i18n from '../../i18n/i18n';

export const getBankAccounts = () => dispatch => {
  dispatch({ type: GET_BANK_ACCOUNTS.REQUEST });

  return api.get(`bankaccounts`).then(
    response => {
      if (response.responseCode === '000') {
        return dispatch({ type: GET_BANK_ACCOUNTS.SUCCESS, payload: response });
      }
      dispatch({ type: GET_BANK_ACCOUNTS.FAILURE, response });
    },
    err => dispatch({ type: GET_BANK_ACCOUNTS.FAILURE, err })
  );
};

export const addBankAccount = data => dispatch => {
  dispatch({ type: ADD_BANK_ACCOUNT.REQUEST });
  const headers = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*'
    }
  };
  return api.post(`bankaccounts`, data, headers).then(
    response => {
      if (response.responseCode === '000') {
        dispatch({ type: ADD_BANK_ACCOUNT.SUCCESS, data: response });
        dispatch(getBankAccounts());
        dispatch(
          addAlert({
            alertType: 'success',
            text: i18n.t('Your bank account is successfully added')
          })
        );
      } else {
        dispatch({ type: ADD_BANK_ACCOUNT.FAILURE, response });
        dispatch(
          addAlert({ alertType: 'error', text: response.resDescription })
        );
      }
    },
    err => dispatch({ type: ADD_BANK_ACCOUNT.FAILURE, err })
  );
};

export const getActiveBankAccounts = () => dispatch => {
  dispatch({ type: GET_ACTIVE_BANK_ACCOUNTS.REQUEST });

  return api.get(`bankaccounts/active`).then(
    response => {
      if (response.responseCode === '000') {
        return dispatch({
          type: GET_ACTIVE_BANK_ACCOUNTS.SUCCESS,
          data: response
        });
      }
      dispatch({ type: GET_ACTIVE_BANK_ACCOUNTS.FAILURE, response });
    },
    err => dispatch({ type: GET_ACTIVE_BANK_ACCOUNTS.FAILURE, err })
  );
};

// export const changeBankAccountStatus = (id, status) => dispatch => {
//   dispatch({ type: CHANGE_BANK_ACCOUNT.REQUEST });

//   return api.put(`bankaccounts/${id}`, { bankAccountStatus: status }).then(
//     response => {
//       if (response.responseCode === '000') {
//         dispatch({ type: CHANGE_BANK_ACCOUNT.SUCCESS, payload: response });
//         dispatch(getBankAccounts());
//         dispatch(
//           addAlert({
//             alertType: 'success',
//             text:
//               status === 5
//                 ? 'Your bank account is successfully changed'
//                 : 'Your bank account is successfully removed'
//           })
//         );
//       } else {
//         dispatch({ type: CHANGE_BANK_ACCOUNT.FAILURE, response });
//         dispatch(
//           addAlert({ alertType: 'error', text: response.resDescription })
//         );
//       }
//     },
//     err => dispatch({ type: CHANGE_BANK_ACCOUNT.FAILURE, err })
//   );
// };
export const activateBankAccount = id => dispatch => {
  dispatch({ type: ACTIVATE_BANK_ACCOUNT.REQUEST });

  return api.put(`bankaccounts/${id}/activate`).then(
    response => {
      if (response.responseCode === '000') {
        dispatch({ type: ACTIVATE_BANK_ACCOUNT.SUCCESS, payload: response });
        dispatch(getBankAccounts());
        dispatch(
          addAlert({
            alertType: 'success',
            text: 'Your bank account is successfully activated'
          })
        );
      } else {
        dispatch({ type: ACTIVATE_BANK_ACCOUNT.FAILURE, response });
        dispatch(
          addAlert({ alertType: 'error', text: response.resDescription })
        );
      }
    },
    err => dispatch({ type: ACTIVATE_BANK_ACCOUNT.FAILURE, err })
  );
};

export const deleteBankAccount = id => dispatch => {
  dispatch({ type: DELETE_BANK_ACCOUNT.REQUEST });

  return api.put(`bankaccounts/${id}/delete`).then(
    response => {
      if (response.responseCode === '000') {
        dispatch({ type: DELETE_BANK_ACCOUNT.SUCCESS, payload: response });
        dispatch(getBankAccounts());
        dispatch(
          addAlert({
            alertType: 'success',
            text: 'Your bank account is successfully deleted'
          })
        );
      } else {
        dispatch({ type: DELETE_BANK_ACCOUNT.FAILURE, response });
        dispatch(
          addAlert({ alertType: 'error', text: response.resDescription })
        );
      }
    },
    err => dispatch({ type: DELETE_BANK_ACCOUNT.FAILURE, err })
  );
};

export const getBanks = () => dispatch => {
  dispatch({ type: GET_BANKS.REQUEST });

  return api.get(`bankaccounts/banks`).then(
    response => {
      if (response.responseCode === '000') {
        dispatch({ type: GET_BANKS.SUCCESS, payload: response });
      } else {
        dispatch({ type: GET_BANKS.FAILURE, response });
      }
    },
    err => dispatch({ type: GET_BANKS.FAILURE, err })
  );
};
