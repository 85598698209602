/* eslint-disable consistent-return */
import {
  FETCH_USER_DETAIL_NOT_APPROVED,
  FETCH_USERS_NOT_APPROVED,
  UNSET_CURRENT_USER,
  APPROVE_USER,
  REJECT_USER,
  SAVE_USER
} from '../../constants/actionTypes';
import { api } from '../../services/rest';
import { addAlert } from './alerts';
import { getUserDetailsApproved } from './usersApproved';
import { getGetParams } from '../../utils/getGetParams';

export const getUsersPending = (
  currentPage,
  pageSize,
  filtered
) => dispatch => {
  dispatch({ type: FETCH_USERS_NOT_APPROVED.REQUEST });
  const filteredQuery = getGetParams(filtered);

  return api
    .get(
      `users?status=1&PageNo=${currentPage}&PageSize=${pageSize}&${filteredQuery}`
    )
    .then(
      response => {
        if (response.responseCode === '000') {
          return dispatch({
            type: FETCH_USERS_NOT_APPROVED.SUCCESS,
            payload: response
          });
        }
        dispatch({ type: FETCH_USERS_NOT_APPROVED.FAILURE, payload: response });
      },
      err => dispatch({ type: FETCH_USERS_NOT_APPROVED.FAILURE, payload: err })
    );
};
export const getUserDetailsPending = id => dispatch => {
  dispatch({ type: FETCH_USER_DETAIL_NOT_APPROVED.REQUEST });

  return api.get(`users/${id}`).then(
    response => {
      if (response.responseCode === '000') {
        return dispatch({
          type: FETCH_USER_DETAIL_NOT_APPROVED.SUCCESS,
          payload: response
        });
      }
      dispatch({
        type: FETCH_USER_DETAIL_NOT_APPROVED.FAILURE,
        payload: response
      });
    },
    err =>
      dispatch({ type: FETCH_USER_DETAIL_NOT_APPROVED.FAILURE, payload: err })
  );
};

export const approveUser = (id, data) => dispatch => {
  dispatch({ type: APPROVE_USER.REQUEST });
  return api.put(`users/${id}`, { userStatus: 2 }).then(
    response => {
      if (response.responseCode === '000') {
        dispatch({ type: APPROVE_USER.SUCCESS, payload: response });
        // eslint-disable-next-line no-use-before-define
        dispatch(saveUser(id, data, true));
      } else {
        dispatch({ type: APPROVE_USER.FAILURE, payload: response });
      }
    },
    err => dispatch({ type: APPROVE_USER.FAILURE, payload: err })
  );
};
export const unsetCurrentUser = () => dispatch =>
  dispatch({ type: UNSET_CURRENT_USER });

export const saveUser = (id, data, save) => dispatch => {
  dispatch({ type: SAVE_USER.REQUEST });

  return api.post(`users/${id}/save`, data).then(
    response => {
      if (response.responseCode === '000') {
        dispatch({ type: SAVE_USER.SUCCESS, payload: response });
        if (save) {
          dispatch(approveUser(id));
          dispatch(getUserDetailsPending(id));
        } else {
          dispatch(getUserDetailsApproved(id));
        }

        dispatch(
          addAlert({ alertType: 'success', text: 'User is successfully saved' })
        );
      } else {
        dispatch({ type: SAVE_USER.FAILURE, payload: response });
        dispatch(
          addAlert({ alertType: 'error', text: response.resDescription })
        );
      }
    },
    err => {
      dispatch({ type: SAVE_USER.FAILURE, payload: err });
      dispatch(addAlert({ alertType: 'error', text: err.message }));
    }
  );
};

export const rejectUser = id => dispatch => {
  dispatch({ type: REJECT_USER.REQUEST });
  return api.put(`users/${id}`, { userStatus: 4 }).then(
    response => {
      if (response.responseCode === '000') {
        dispatch({ type: REJECT_USER.SUCCESS, payload: response });
        dispatch(
          addAlert({
            alertType: 'success',
            text: 'User is successfully rejected'
          })
        );
        dispatch(getUserDetailsPending(id));
      } else {
        dispatch({ type: REJECT_USER.FAILURE, payload: response });
      }
    },
    err => dispatch({ type: REJECT_USER.FAILURE, payload: err })
  );
};
