import React from 'react';

const UploadFile = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      className={props.className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#004B87"
          d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zm-.5 14v3h-3v-3H8l4-4 4 4h-2.5zM13 9V3.5L18.5 9H13z"
        />
      </g>
    </svg>
  );
};
export default UploadFile;
