import styled from 'styled-components';
import theme from '../../styles/theme';

const ButtonUnderlined = styled.button`
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  border: none;
  outline: none;
  background: transparent;
  color: ${props =>
    props.disabled ? 'rgba(5, 34, 56, 0.56)' : theme.color.primary};
  text-decoration: ${props => (props.disabled ? 'none' : 'underline')};
`;

export default ButtonUnderlined;
