import { ADD_ALERT, REMOVE_ALERT } from '../../constants/actionTypes';

export const addAlert = data => {
  return {
    type: ADD_ALERT,
    payload: data
  };
};

export const removeAlert = () => {
  return {
    type: REMOVE_ALERT
  };
};
