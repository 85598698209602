/* eslint-disable camelcase */
import { api } from '../../services/rest';
import {
  FETCH_TOP_UP_RESULT,
  TOP_UP_BALANCE
} from '../../constants/actionTypes';
// import { closeModal, openModal, showNotification } from './appState';
import { addAlert } from './alerts';

export const topupMoneyRequest = data => ({
  type: TOP_UP_BALANCE.REQUEST,
  data
});

export const topupMoneySuccess = data => ({
  type: TOP_UP_BALANCE.SUCCESS,
  data
});

export const topupMoneyFailure = error => ({
  type: TOP_UP_BALANCE.FAILURE,
  error
});

export const fetchTopUpResultRequest = data => ({
  type: FETCH_TOP_UP_RESULT.REQUEST,
  data
});

export const fetchTopUpResultSuccess = data => ({
  type: FETCH_TOP_UP_RESULT.SUCCESS,
  data
});

export const fetchTopUpResultFailure = error => ({
  type: FETCH_TOP_UP_RESULT.FAILURE,
  error
});

const requestToPGW = options => {
  const form = document.createElement('form');
  form.style.display = 'none';
  const version = document.createElement('input');
  const merchant_id = document.createElement('input');
  const result_url_1 = document.createElement('input');
  const hash_value = document.createElement('input');
  const payment_description = document.createElement('input');
  const order_id = document.createElement('input');
  const amount = document.createElement('input');

  version.value = options.version;
  version.name = 'version';
  form.appendChild(version);

  merchant_id.value = options.pgwMerchantId;
  merchant_id.name = 'merchant_id';
  form.appendChild(merchant_id);

  result_url_1.value = options.redirectUrl;
  result_url_1.name = 'result_url_1';
  form.appendChild(result_url_1);

  hash_value.value = options.paymentHash;
  hash_value.name = 'hash_value';
  form.appendChild(hash_value);

  payment_description.value = options.paymentDescription;
  payment_description.name = 'payment_description';
  form.appendChild(payment_description);

  order_id.value = options.gipTransactionId;
  order_id.name = 'order_id';
  form.appendChild(order_id);

  amount.value = options.formattedAmount;
  amount.name = 'amount';
  form.appendChild(amount);

  form.method = 'POST';
  form.action = options.paymentUrl;

  document.body.appendChild(form);
  form.submit();
};

export const topUp = (id, val) => dispatch => {
  // eslint-disable-next-line radix
  const value = {
    amount: parseFloat(val.amount),
    accountType: val.accountType
  };

  dispatch(topupMoneyRequest());
  return api
    .post(`wallets/${id}/topup`, value)
    .then(response => {
      if (response.responseCode === '000') {
        dispatch(topupMoneySuccess(response.data));
        requestToPGW(response.data);
      } else {
        dispatch(
          addAlert({ alertType: 'error', text: response.resDescription })
        );
        dispatch(topupMoneyFailure(response.resDescription));
      }
    })
    .catch(error => {
      dispatch(addAlert({ alertType: 'error', text: error.message }));
      dispatch(topupMoneyFailure(error));
    });
};

// eslint-disable-next-line consistent-return
export const fetchTopUpResult = () => (dispatch, getState) => {
  const { userInfo, topUpInfo } = getState();
  const { accessToken } = userInfo;

  dispatch(fetchTopUpResultRequest());
  //   dispatch(openModal('Loading'));
  if (topUpInfo.transactionId)
    return api
      .get(`wallettopup/${topUpInfo.transactionId}`, accessToken)
      .then(({ data }) => {
        dispatch(fetchTopUpResultSuccess(data));
        // dispatch(fetchUserInfo());
        // dispatch(closeModal());
      })
      .catch(error => {
        dispatch(fetchTopUpResultFailure(error));
        // dispatch(openModal('Error'));
      });
};
