/* eslint-disable no-unused-vars */
import { LOGIN_INTERNAL, LOGOUT_INTERNAL } from '../../constants/actionTypes';

export const initState = {
  error: null,
  isLoading: false,
  iataCode: null
};

export const loginInternalSelector = state => state.loginInternal;

export default {
  [LOGIN_INTERNAL.REQUEST]: state => {
    return {
      ...state,
      isLoading: true
    };
  },
  [LOGIN_INTERNAL.SUCCESS]: (state, action) => {
    const iataCode = action.payload.code;
    return {
      ...state,
      iataCode,
      isLoading: false
    };
  },
  [LOGOUT_INTERNAL.SUCCESS]: state => {
    return {
      ...state,
      iataCode: null,
      isLoading: false
    };
  },
  [LOGIN_INTERNAL.FAILURE]: state => {
    return { ...state, isLoading: false };
  },
  [LOGOUT_INTERNAL.FAILURE]: state => {
    return { ...state, isLoading: false };
  }
};
