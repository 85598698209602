import React from 'react';
import queryString from 'query-string';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { initApp, showMenu } from '../redux/actions/appState';
import { api } from '../services/rest';
import store from '../redux/configureStore';
import { addAlert } from '../redux/actions/alerts';

const INTERNAL_USER_NAVIGATION_CODE = 300;
export const INTERNAL_TOKEN_KEY = 'internal_token';
export const INTERNAL_USER_IATA_CODE_KEY = 'internal_token_iata_code';

class RedirectPage extends React.Component {
  componentDidMount() {
    const { token } = queryString.parse(this.props.location.search);
    localStorage.setItem(INTERNAL_TOKEN_KEY, token);
    const url = api.getNotVersionedUrl('Account/Login');
    axios
      .get(url, { headers: { authorization: `Bearer ${token}` } })
      .then(this.checkInternalUser)
      .then(userInfo => {
        const page = {
          1: '/termsAndConditions',
          2: '/agencyAccountBeingCreated',
          3: '/userAccountBeingCreated',
          4: '/portal',
          300: '/internal-code'
        }[userInfo.userNavigationState];

        if (!page) {
          store.dispatch(
            addAlert({
              alertType: 'error',
              text:
                'Wrong User Navigation state. Please correct user navigation state on API.'
            })
          );
        }

        if (page === '/portal') {
          this.props.showMenu();
          if (localStorage.getItem('returnUrl')) {
            this.props.history.push(localStorage.getItem('returnUrl'));
            localStorage.removeItem('returnUrl');
          } else {
            this.props.history.push('/');
          }
        } else {
          this.props.history.push(page);
        }
      });
  }

  // eslint-disable-next-line consistent-return
  checkInternalUser = res => {
    if (res.data && res.data.continueAsInternal) {
      return { userNavigationState: INTERNAL_USER_NAVIGATION_CODE };
    }
    localStorage.removeItem(INTERNAL_TOKEN_KEY);
    localStorage.removeItem(INTERNAL_USER_IATA_CODE_KEY);
    return this.props.initApp();
  };

  render() {
    return <div>Loading...</div>;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // eslint-disable-next-line no-shadow
      initApp: () => dispatch => dispatch(initApp()),
      showMenu
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(RedirectPage);
