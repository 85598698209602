/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import ReactTable from 'react-table';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { walletStatus } from '../../utils/walletStatus';
import '../../styles/react-table.css';
import { Card, Button, PageHeader } from '../components';
import Modal from '../components/Modal';
import { Plus } from '../icons';
import CookieBanner from '../components/CookieBanner';
import TopUp from './TopUp';
import IEPNumber from './IEPNumber';
import AddWallet from './AddWallet';
import CancelIEPNumber from './CancelIEPNumber';
import { ShowForPermission } from '../ShowForPermission';
import NoRowsFound from '../components/NoRowsTable';
import TableFooter from '../components/Table/TableFooter';
import { getGetParams } from '../../utils/getGetParams';
import {
  SET_WALLETS_PAGE_SIZE,
  SET_WALLETS_PAGE
} from '../../constants/actionTypes';

const POSITIVE_NUMBERS_REGEX = /[^\d]/;

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
const CardNoPadding = styled(Card)`
  padding: 0 0 10px 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const LinkStyled = styled(Link)`
  color: #004b87;
  text-decoration: underline;
`;
const ButtonUnderlined = styled.button`
  color: #004b87;
  text-decoration: underline;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
`;
const NoDataBlock = styled.div`
  font-size: 14px;
  color: rgba(5, 34, 56, 0.56);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
`;
const StatusLabel = styled.div`
  display: inline-flex;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: 0.2px;
  padding: 2px 4px;
  border-radius: 2px;
  color: ${props =>
    props.status === 1
      ? '#00b326'
      : props.status === 2
      ? 'rgba(233, 20, 20, 0.87)'
      : '#fac832'};
  background: ${props =>
    props.status === 1
      ? 'rgba(0,179,38, 0.15)'
      : props.status === 2
      ? 'rgba(233, 20, 20, 0.15)'
      : 'rgba(250,200,50,0.15)'};
`;
const BalanceCell = styled.div`
  text-align: right;
  color: ${props => (props.isClosed ? 'rgba(233, 20, 20, 0.87)' : '')};
`;

const filterCaseInsensitive = ({ id, value }, row) =>
  row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;
const prepareQueryString = (filterable, newPage, newPageSize) => {
  const filtersString = getGetParams(filterable);
  const paginationString = queryString.stringify({
    PageNo: newPage + 1,
    PageSize: newPageSize
  });

  return `${paginationString}${filtersString ? `&${filtersString}` : ''}`;
};
const WalletsList = props => {
  const {
    enableCookie,
    getWallets,
    isCookieEnabled,
    agencyStatus,
    isAgencySuspended,
    isAgencyClosed,
    openModal,
    addAlert,
    closeModal,
    isModalOpen,
    modalTitle,
    modalType,
    wallets,
    pageInfo,
    isLoading,
    pageSize,
    page
  } = props;
  const [t] = useTranslation('translation');
  const [onFilter] = useDebouncedCallback(
    (filterable, pageIndex, newPageSize) => {
      getWallets(prepareQueryString(filterable, pageIndex, newPageSize));
    },
    1000
  );
  const initWalletAdding = () => {
    if (isAgencySuspended || isAgencyClosed) {
      addAlert({
        alertType: 'error',
        text: `Failed to add new wallet since IEP Account is ${agencyStatus}.`
      });
    } else {
      props.getAccessibleAgencies(5);
      props.openModal('addWallet', t('Add new wallet'));
    }
  };

  const columns = [
    {
      Header: t('Wallet name'),
      accessor: 'walletName',
      Cell: rows => {
        return (
          <LinkStyled to={`/portal/wallets/${rows.original.walletId}`}>
            {' '}
            {rows.original.walletName}{' '}
          </LinkStyled>
        );
      },
      filterMethod: filterCaseInsensitive,
      Filter: ({ filter, onChange }) => (
        <input
          type="text"
          placeholder={t('All wallets')}
          value={filter ? filter.value : ''}
          onChange={event => onChange(event.target.value)}
        />
      )
    },
    {
      Header: t('IATA Code'),
      accessor: 'agentCode',
      Filter: ({ filter, onChange }) => (
        <input
          type="text"
          placeholder={t('Search...')}
          value={filter ? filter.value : ''}
          maxLength={8}
          onChange={event =>
            onChange(event.target.value.replace(POSITIVE_NUMBERS_REGEX, ''))
          }
        />
      )
    },
    {
      Header: t('Balance'),
      accessor: 'availableBalance',
      Cell: row => {
        const currency = row.original.currencyCode;
        return (
          <BalanceCell isClosed={row.value === 0}>
            {row.value} {currency}
          </BalanceCell>
        );
      },

      Filter: ({ filter, onChange }) => (
        <input
          type="text"
          placeholder={t('Search...')}
          value={filter ? filter.value : ''}
          onChange={event => onChange(event.target.value)}
        />
      )
    },
    {
      Header: t('Status'),
      accessor: 'walletStatus',
      Cell: row => (
        <StatusLabel status={row.value}>{walletStatus(row.value)}</StatusLabel>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : ''}
        >
          <option value="">{t('All statuses')}</option>
          <option value="1">Open</option>
          <option value="2">Closed</option>
          <option value="3">Pending</option>
        </select>
      )
    },
    {
      Header: '',
      accessor: 'iepCode',
      filterable: false,
      Cell: row => {
        const value = row.original;
        const isOpen = value.walletStatus === 1;
        // eslint-disable-next-line no-shadow
        const IEPNumber = value.iepCode;
        const cellValue =
          isOpen && IEPNumber ? (
            <ShowForPermission contextName="IEPN" action="Read">
              {hasPermission =>
                hasPermission ? (
                  <ButtonUnderlined
                    onClick={() =>
                      openModal('iep', t('IEP number'), {
                        IEPNumber,
                        ExpDate: 'xx/xx',
                        walletId: row.original.walletId,
                        source: 'wallet-list'
                      })
                    }
                  >
                    {t('View IEP number')}
                  </ButtonUnderlined>
                ) : (
                  <NoDataBlock style={{ cursor: 'not-allowed' }}>
                    {t('View IEP number')}
                  </NoDataBlock>
                )
              }
            </ShowForPermission>
          ) : (
            <NoDataBlock>{t('No IEPN generated')}</NoDataBlock>
          );
        return cellValue;
      }
    },
    {
      Header: '',
      filterable: false,
      accessor: 'walletId',
      Cell: row => {
        const isClosed = row.original.walletStatus === 2;
        const { availableBalance, walletName, currencyCode } = row.original;

        const cellValue = isClosed ? (
          <NoDataBlock>{t(`Can't be topped up`)}</NoDataBlock>
        ) : (
          <ShowForPermission contextName="Topup" action="Create">
            {hasPermission =>
              hasPermission && !isAgencySuspended ? (
                <ButtonUnderlined
                  onClick={() =>
                    openModal('topUp', t('Top up IATA EP Wallet'), {
                      walletName,
                      availableBalance,
                      currencyCode,
                      walletId: row.value
                    })
                  }
                >
                  {t('Top up balance')}
                </ButtonUnderlined>
              ) : (
                <NoDataBlock style={{ cursor: 'not-allowed' }}>
                  {t('Top up balance')}
                </NoDataBlock>
              )
            }
          </ShowForPermission>
        );

        return cellValue;
      }
    }
  ];

  return (
    <Root>
      <Row>
        <PageHeader>{t('Wallets')}</PageHeader>
        <ShowForPermission contextName="Wallet" action="Create">
          <Button
            title={t('Add wallet')}
            icon={<Plus inverse />}
            primary
            onClick={initWalletAdding}
          />
        </ShowForPermission>
      </Row>
      <CardNoPadding>
        <ReactTable
          PaginationComponent={val => (
            <TableFooter {...props} {...val} exportUrl="/v1/wallets/report" />
          )}
          NoDataComponent={() => <NoRowsFound style={{ top: '73%' }} />}
          data={wallets}
          columns={columns}
          pageInfo={pageInfo}
          defaultPageSize={20}
          loading={isLoading}
          page={page}
          pages={props.pageInfo && props.pageInfo.totalPage}
          minRows={1}
          maxRows={50}
          filterable
          manual
          sortable={false}
          resizable={false}
          className="-highlight wallets"
          onPageSizeChange={newPageSize =>
            props.setPageSize(newPageSize, SET_WALLETS_PAGE_SIZE)
          }
          onPageChange={pageIndex => {
            props.setPage(pageIndex, SET_WALLETS_PAGE);
          }}
          onFetchData={({ page: newPage, pageSize: newPageSize, filtered }) => {
            if (
              page !== newPage ||
              pageSize !== newPageSize ||
              !filtered.length
            ) {
              getWallets(prepareQueryString(filtered, newPage, newPageSize));
            } else {
              onFilter(filtered, newPage, newPageSize);
            }

            props.setPageSize(newPageSize, SET_WALLETS_PAGE_SIZE);
            props.setPage(newPage, SET_WALLETS_PAGE);
          }}
        />
      </CardNoPadding>
      {!isCookieEnabled && <CookieBanner enableCookie={enableCookie} />}
      <Modal
        title={modalTitle}
        open={isModalOpen}
        closeButtonTitle={modalType === 'iep' && 'Ok'}
      >
        {modalType === 'topUp' ? (
          <TopUp closeModal={closeModal} />
        ) : modalType === 'iep' ? (
          <IEPNumber />
        ) : modalType === 'addWallet' ? (
          <AddWallet
            closeModal={closeModal}
            walletId={wallets.length + 1} // Just for mocked server
            submitMethod={props.addNewWallet}
          />
        ) : (
          <CancelIEPNumber closeModal={props.closeModal} />
        )}
      </Modal>
    </Root>
  );
};

export default WalletsList;
