import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '..';
import { Cancel } from '../../icons';

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(5, 34, 56, 0.87);
  z-index: 21;
`;
const ModalBody = styled.div`
  display: flex;
  flex: 1;
  // width: 50%;
  width: 600px;
  flex-direction: column;
  background-color: #fff;
  border-radius: 3px;
  margin: ${props => (props.top ? '40px auto' : '5% auto')};
  padding: 24px;
  max-height: 80%;
  position: relative;
`;
const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Title = styled.h3`
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.51px;
  color: rgba(5, 34, 56, 0.87);
  margin: 0;
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-bottom: 21px;
`;
const CloseButton = styled.button`
  right: 12px;
  top: 16px;
  position: absolute;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
`;
const ButtonStyled = styled(Button)`
  margin-right: 16px;
`;
const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid rgba(5, 34, 56, 0.12);
  padding-top: 23px;
`;
const Modal = props => {
  const [t] = useTranslation('translation');

  return props.open
    ? ReactDOM.createPortal(
        <Overlay>
          <ModalBody top={props.top}>
            <ModalTitle>
              <Title>{props.title}</Title>
              <CloseButton onClick={props.closeModal}>
                <Cancel color="rgba(5, 34, 56, 0.47)" />
              </CloseButton>
            </ModalTitle>
            <ModalContent>{props.children}</ModalContent>
            <ModalFooter>
              <ButtonStyled
                secondary
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                onClick={props.closeModal}
                title={
                  props.closeButtonTitle ? props.closeButtonTitle : t('Cancel')
                }
              />
              {/* <Button primary onClick={triggerSave} title={buttonTitle}/> */}
            </ModalFooter>
          </ModalBody>
        </Overlay>,
        document.getElementById('modal')
      )
    : null;
};

export default Modal;
