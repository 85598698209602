/* eslint-disable no-unused-vars */
import {
  FETCH_WALLETS,
  SET_WALLETS_PAGE,
  SET_WALLETS_PAGE_SIZE,
  ADD_NEW_WALLET,
  FETCH_WALLET,
  FETCH_WALLET_TRANSACTIONS,
  GENERATE_IEP_NUMBER,
  GET_ACCESSIBLE_WALLETS,
  FETCH_WALLET_TRANSACTION_DETAIL,
  CLOSE_IEP_NUMBER
} from '../../constants/actionTypes';

export const initState = {
  wallets: [],
  currentWallet: null,
  currentWalletTransactions: [],
  currentWalletTransactionDetail: null,
  currentIEPNumber: null,
  accessibleWallets: [],
  cardInfo: null,
  pageInfo: null,
  pageSize: 10,
  page: 0,
  isLoading: false,
  errors: null,
  transactionPageInfo: null,
  isTransactionsLoading: false,
  isCancelIEPNumberLoading: false,
  isGeneratingNumber: false
};

export default {
  [FETCH_WALLETS.REQUEST]: state => {
    return {
      ...state,
      isLoading: true,
      errors: null
    };
  },
  [FETCH_WALLETS.SUCCESS]: (state, { data }) => {
    return {
      ...state,
      wallets: data.data,
      pageInfo: data.pageInfo,
      isLoading: false,
      errors: null
    };
  },
  [FETCH_WALLETS.FAILURE]: (state, { code }) => {
    return { ...state, isLoading: false };
  },

  [SET_WALLETS_PAGE]: (state, { page }) => {
    return {
      ...state,
      page
    };
  },
  [SET_WALLETS_PAGE_SIZE]: (state, { pageSize }) => {
    return {
      ...state,
      pageSize
    };
  },
  [ADD_NEW_WALLET.REQUEST]: state => {
    return {
      ...state,
      errors: null
    };
  },
  [ADD_NEW_WALLET.SUCCESS]: (state, { data }) => {
    return {
      ...state,
      wallets: state.wallets.concat(data.data),
      errors: null
    };
  },
  [ADD_NEW_WALLET.FAILURE]: (state, response) => {
    return {
      ...state,
      errors: response.resDescription
    };
  },
  [FETCH_WALLET.REQUEST]: state => {
    return {
      ...state,
      isLoading: true,
      errors: null
    };
  },
  [FETCH_WALLET.SUCCESS]: (state, { data }) => {
    return {
      ...state,
      currentWallet: data.data,
      isLoading: false,
      errors: null
    };
  },
  [FETCH_WALLET.FAILURE]: (state, { error }) => {
    return { ...state, isLoading: false, errors: error.resDescription };
  },
  [FETCH_WALLET_TRANSACTIONS.REQUEST]: state => {
    return {
      ...state,
      isTransactionsLoading: true,
      errors: null
    };
  },
  [FETCH_WALLET_TRANSACTIONS.SUCCESS]: (state, { data }) => {
    return {
      ...state,
      currentWalletTransactions: data.data,
      transactionPageInfo: data.pageInfo,
      isTransactionsLoading: false,
      errors: null
    };
  },
  [FETCH_WALLET_TRANSACTIONS.FAILURE]: (state, { code }) => {
    return { ...state, isTransactionsLoading: false };
  },
  [GET_ACCESSIBLE_WALLETS.REQUEST]: state => {
    return {
      ...state,
      errors: null
    };
  },
  [GET_ACCESSIBLE_WALLETS.SUCCESS]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      accessibleWallets: Object.entries(data).length ? data.wallets : [],
      errors: null
    };
  },
  [GET_ACCESSIBLE_WALLETS.FAILURE]: (state, { code }) => {
    return { ...state };
  },
  [GENERATE_IEP_NUMBER.REQUEST]: state => {
    return {
      ...state,
      errors: null,
      isGeneratingNumber: true
    };
  },
  [GENERATE_IEP_NUMBER.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      currentIEPNumber: payload.data.maskedPan,
      errors: null,
      isGeneratingNumber: false
    };
  },
  [GENERATE_IEP_NUMBER.FAILURE]: (state, { payload }) => {
    return {
      ...state,
      errors: payload.resDescription,
      isGeneratingNumber: false
    };
  },
  [FETCH_WALLET_TRANSACTION_DETAIL.REQUEST]: state => {
    return {
      ...state,
      errors: null
    };
  },
  [FETCH_WALLET_TRANSACTION_DETAIL.SUCCESS]: (state, { data }) => {
    return {
      ...state,
      currentWalletTransactionDetail: data.data,
      pageInfo: data.pageInfo,
      errors: null
    };
  },
  [FETCH_WALLET_TRANSACTION_DETAIL.FAILURE]: (state, { code }) => {
    return {
      ...state
    };
  },
  [CLOSE_IEP_NUMBER.REQUEST]: state => {
    return {
      ...state,
      isCancelIEPNumberLoading: true
    };
  },
  [CLOSE_IEP_NUMBER.SUCCESS]: state => {
    return {
      ...state,
      isCancelIEPNumberLoading: false
    };
  },
  [CLOSE_IEP_NUMBER.FAILURE]: state => {
    return {
      ...state,
      isCancelIEPNumberLoading: false
    };
  }
};
