import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import styled, { createGlobalStyle } from 'styled-components';

export const TabBlock = styled.div`
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
  justify-content: space-between;
  // align-items: center;
`;

export const TabsGlobal = createGlobalStyle`
  .MuiTab-root {
    min-height: 20px!important;
    min-width: 50px!important;
  }
  .MuiTabs-root{
    min-height: 36px!important;
  }
  .MuiTab-textColorInherit {
    background: #f9f9fb;
    color: rgba(5, 34, 56, 0.56);
    text-transform: uppercase;
    font-size: 12px;
    padding: 8px 16px;
    border-radius: 2px;
    font-weight: 500 !important;
    opacity: 1 !important;
  }
  .MuiTab-textColorInherit.Mui-selected {
    background-color: #004b87 !important;
    color: #fff;
  }
  .MuiTab-root {
    background-color: #f9f9fb !important;
    text-transform: none !important;
    font-size: 14px !important;
  }
  .MuiTypography-root {
    > div {
      padding: 0;
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
`;

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default ({ tabs, children }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <TabsGlobal />
      <TabBlock>
        <Tabs value={value} onChange={handleChange} aria-label="users">
          {tabs.map(({ label }, i) => (
            <Tab label={label} {...a11yProps(i)} />
          ))}
        </Tabs>
      </TabBlock>
      {children}
      {tabs.map(({ component: Component }, i) => (
        <TabPanel value={value} index={i}>
          <Component />
        </TabPanel>
      ))}
    </>
  );
};
