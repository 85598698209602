import React, { useMemo } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import baseTheme from '../../styles/theme';
import { Root, Label, HelpText } from './FormComponentElements';

const StyledSelect = styled(Select)`
  width: 100%;
  padding: 8px 0 8px 2px;
  font-size: 14px;
  line-height: 1.71;
  color: ${baseTheme.color.font.default};
  background: ${props => (props.disabled ? '#f7f7fe' : '#fff')};
  border: none;
  background-color: inherit;

  .react-select {
    &__control {
      border: ${({ error, touched }) =>
        error && touched
          ? '2px solid #f15c5c'
          : '1px solid rgba(5, 34, 56, 0.25)'};
    }
  }
`;

const FormSelect = props => {
  const {
    className,
    label,
    labelOpacity,
    id,
    helpTextColor,
    disabled,
    placeholder,
    helpTextFontSize,
    helpText,
    isMulti,
    options,
    meta: { error, touched },
    input
  } = props;
  const inputValue = input.value;
  const value = useMemo(() => {
    if (Array.isArray(inputValue)) {
      return inputValue.map(item =>
        typeof item === 'object' ? item : { label: item, value: item }
      );
    }

    return inputValue;
  }, [inputValue]);

  return (
    <Root className={className}>
      <Label htmlFor={id} opacity={labelOpacity}>
        {label}
      </Label>
      <StyledSelect
        {...input}
        value={value}
        error={error}
        touched={touched}
        disabled={disabled}
        inputId={id}
        placeholder={placeholder}
        className={`react-select-container${isMulti ? ' multi' : ''}`}
        classNamePrefix="react-select"
        isMulti={isMulti}
        options={options}
        closeMenuOnSelect={false}
        onBlur={() => input.onBlur(input.value)}
      />

      {helpText && (
        <HelpText color={helpTextColor} fontSize={helpTextFontSize}>
          {helpText}
        </HelpText>
      )}
      <HelpText error={error}>{touched ? error : ''}</HelpText>
    </Root>
  );
};
export default FormSelect;
