import React from 'react';
import styled from 'styled-components';

const Root = styled.article`
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 8px 1px rgba(63, 63, 68, 0.05);
  border: solid 1px rgba(5, 34, 56, 0.07);
  padding: 24px;
  margin: 5px 0 15px 0;
`;
const Card = props => <Root className={props.className}>{props.children}</Root>;

export default Card;
