// @flow
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import '../../styles/react-table.css';
import { Card, PageHeader } from '../components';
import Modal from '../components/Modal';
import TransactionTable from './TransactionTable';
import AdvancedSearch from './AdvancedSearch';
import { Search } from '../icons';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
const CardNoPadding = styled(Card)`
  padding: 0 0 10px 0;
`;
const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px;
  border-bottom: 1px solid rgba(5, 34, 56, 0.07);
  justify-content: flex-end;
`;
const Button = styled.button`
  font-size: 14px;
  color: #004b87;
  border: none;
  outline: none;
  background: #fff;
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const TransactionsList = props => {
  const {
    getAccessibleWallets,
    isAgencySuspended,
    isWithdrawAllowed,
    isAdvancedSearch
  } = props;
  const isSearchAndExportBlocked = isAgencySuspended && !isWithdrawAllowed;
  const [t] = useTranslation('translation');

  useEffect(() => {
    getAccessibleWallets();
  }, [getAccessibleWallets]);

  return (
    <Root>
      <>
        <PageHeader>{t('Transactions')}</PageHeader>
        <CardNoPadding>
          <TableHeader>
            {isAdvancedSearch && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '10px'
                }}
              >
                <div>
                  {props.pageInfo.totalRecord}{' '}
                  <span style={{ color: 'rgba(5, 34, 56, 0.56)' }}>
                    {t('Transactions found')}
                  </span>
                </div>
                <Button
                  onClick={() => {
                    props.destroyAdvancedSearchForm();
                    props.setPage(0);
                    props.setIsAdvancedSearch(false);
                    props.getTransactions(1, props.pageSize);
                  }}
                >
                  {t('Clear')}
                </Button>
              </div>
            )}
            <Button
              disabled={isSearchAndExportBlocked}
              onClick={() => props.openModal(null)}
            >
              <Search /> {t('Advanced Search')}
            </Button>
          </TableHeader>
          <TransactionTable
            data={props.transactions}
            loading={props.isLoading}
            pageInfo={props.pageInfo}
            wallets={props.accessibleWallets}
            history={props.history}
            getTransactions={props.getTransactions}
            setPageSize={props.setPageSize}
            setPage={props.setPage}
            page={props.page}
            isFilterable={!props.isAdvancedSearch}
            advancedSearchValues={
              props.isAdvancedSearch &&
              props.advancedTransactionSearch &&
              props.advancedTransactionSearch.values
            }
            exportUrl={
              isSearchAndExportBlocked ? '' : '/v1/transactions/report'
            }
          />
        </CardNoPadding>

        <Modal title={t('Search transactions')} top open={props.isModalOpen}>
          <AdvancedSearch />
        </Modal>
      </>
    </Root>
  );
};

export default TransactionsList;
