import React, { useEffect, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ShowForPermission } from '../../ShowForPermission';
import SettingsTable from './SettingsTable';
import { CancelCard, Alert } from '../../icons';
import { Card, InlineLoader, Loader } from '../../components';
import { api } from '../../../services/rest';

const AgencyButton = styled.button`
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ disabled }) =>
    disabled ? `rgba(5, 34, 56, 0.56)` : `rgba(5, 34, 56, 0.7)`};
  outline: none;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  > svg {
    margin-right: 6px;
  }

  :hover {
    color: ${({ disabled }) =>
      disabled ? `rgba(5, 34, 56, 0.56)` : `rgba(5, 34, 56, 1)`};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const RowStretched = styled(Row)`
  justify-content: space-between;
`;

const CardNoPadding = styled(Card)`
  padding: 0 0 10px 0;
`;

export const UppercaseHeader = styled.h5`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  height: 24px;
  text-transform: uppercase;
  margin: 0;
  padding-left: 10px;
`;

const UppercaseHeaderTable = styled(UppercaseHeader)`
  margin-top: 24px;
  margin-left: 20px;
`;

const Agencies = ({
  openModal,
  closeModal,
  getAgencies,
  isAgencyClosed,
  agencies,
  isLoading,
  addAlert,
  initApp,
  pageInfo
}) => {
  const [isQuering, setIsQuering] = useState(false);
  const [t] = useTranslation('translation');
  const headAgency = useMemo(
    () => agencies.find(({ parentAgentCode }) => !parentAgentCode),
    [agencies]
  );
  const agencyToAction = headAgency || agencies[0] || {};
  const canBeReopened = !!headAgency && isAgencyClosed;

  const accountCanBeClosed = useMemo(
    () => agencies.every(({ canBeClosed }) => !!canBeClosed),
    [agencies]
  );
  const makeRequest = useCallback(
    (url, errorText) => {
      setIsQuering(true);
      closeModal();
      api
        .put(url)
        .then(response => {
          const alertType =
            response.responseCode === '000' || response.responseCode === 'OK'
              ? 'success'
              : 'error';

          setIsQuering(false);
          addAlert({ alertType, text: response.resDescription });
          getAgencies();
          initApp();
        })
        .catch(err => {
          setIsQuering(false);
          addAlert({
            alertType: 'error',
            text: err.resDescription || errorText
          });
        });
    },
    [initApp, getAgencies, addAlert, closeModal]
  );
  const onCloseAgency = useCallback(
    () =>
      makeRequest(
        `agencies/${agencyToAction.id}/close`,
        'Fail close IEP account'
      ),
    [makeRequest, agencyToAction.id]
  );
  const onReopenAgency = useCallback(
    () =>
      makeRequest(
        `agencies/${agencyToAction.id}/reopen`,
        'Fail reopen IEP account'
      ),
    [makeRequest, agencyToAction.id]
  );

  useEffect(() => {
    getAgencies();
  }, [getAgencies]);

  return (
    <CardNoPadding>
      {isQuering && <Loader />}
      <RowStretched>
        <UppercaseHeaderTable>{t('IEP Account')}</UppercaseHeaderTable>
        <ShowForPermission contextName="Agency" action="Update">
          {canBeReopened && (
            <AgencyButton
              onClick={() => {
                openModal('confirmModal', t('Re-open IEP account'), {
                  onConfirm: onReopenAgency,
                  description: t(
                    'Are you sure you want to re-open IEP account?'
                  ),
                  buttonText: t('Yes, re-open IEP account')
                });
              }}
            >
              <Alert viewBox="-10 -10 50 50" />
              {t('Re-open IEP account')}
            </AgencyButton>
          )}
        </ShowForPermission>
        <ShowForPermission contextName="Agency" action="Delete">
          {!isAgencyClosed && (
            <AgencyButton
              onClick={() => {
                if (accountCanBeClosed) {
                  openModal('confirmModal', t('Close IEP Account'), {
                    onConfirm: onCloseAgency,
                    description: t(
                      'Are you sure you want to close this account?'
                    ),
                    buttonText: t('Yes, close account')
                  });
                } else {
                  addAlert({
                    alertType: 'error',
                    text: t(
                      'You can close the account only if all assigned wallets balances are 0.'
                    )
                  });
                }
              }}
            >
              <CancelCard blue={accountCanBeClosed} />
              {t('Close IEP Account')}
            </AgencyButton>
          )}
        </ShowForPermission>
      </RowStretched>

      {isLoading ? (
        <InlineLoader style={{ marginLeft: '25px' }} />
      ) : (
        <SettingsTable
          data={agencies}
          pageInfo={pageInfo}
          editNotifications={data =>
            openModal('alertSettings', t('Manage alert settings'), data)
          }
        />
      )}
    </CardNoPadding>
  );
};

export default Agencies;
