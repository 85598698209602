import React, { useEffect } from 'react';
import {
  ComposedChart,
  XAxis,
  YAxis,
  Area,
  Bar,
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';
import moment from 'moment';
import isEmpty from 'lodash.isempty';
import { useTranslation } from 'react-i18next';
import {
  getFakeMiddleOfPeriod,
  PERIODIOCITY,
  formatTooltipLabel,
  TRANSACTION_COUNT,
  SETTLEMENT_AMOUNT,
  REFUND_AMOUNT,
  buildChartObject,
  formatChartLabel,
  FREQUENCY_WEEKLY
} from '../ReportsConstants';
import { Loader } from '../../components';
import CustomizedAxisTick from './CustomizedAxisTick';

const getTransactions = (timestamp, chartReports, reportObjects) => {
  const newReportObjects = { ...reportObjects };
  chartReports.forEach(chartReport => {
    const isBetween = moment(timestamp).isBetween(
      new Date(chartReport.startDate).getTime(),
      new Date(chartReport.endDate).getTime()
    );

    if (!newReportObjects[timestamp] && !isBetween) {
      newReportObjects[timestamp] = buildChartObject(timestamp);
    } else {
      newReportObjects[timestamp] = isBetween
        ? {
            transactionCount: chartReport.totalCount,
            totalAmount: chartReport.numericTotalPayment,
            totalRefund: chartReport.numericTotalRefund,
            labelTotalAmount: `${chartReport.totalPayment} ${chartReport.currency}`,
            labelTotalRefund: `${chartReport.totalRefund} ${chartReport.currency}`,
            time: timestamp
          }
        : newReportObjects[timestamp];
    }
  });
  return newReportObjects;
};

const buildData = ({ startDate, endDate }, period, chartReports) => {
  const reportObjects = {};
  let nextDate = getFakeMiddleOfPeriod(moment(startDate), period);
  while (nextDate.isBefore(moment(endDate).add(1, period))) {
    const timestamp = new Date(nextDate).getTime();
    if (isEmpty(chartReports)) {
      reportObjects[timestamp] = buildChartObject(timestamp);
    } else {
      Object.assign(
        reportObjects,
        getTransactions(timestamp, chartReports, reportObjects)
      );
    }
    nextDate = nextDate.add(1, period);
  }
  return Object.values(reportObjects);
};

const Chart = props => {
  const [t] = useTranslation('translation');
  const { getChartReports, iataCode, walletId, customRange, period } = props;

  useEffect(() => {
    getChartReports({
      AgencyId: iataCode.id,
      WalletId: walletId,
      ...customRange,
      Periodicity: PERIODIOCITY[period]
    });
  }, [getChartReports, iataCode.id, walletId, period, customRange]);

  return props.isLoading || props.chartReport ? (
    <Loader />
  ) : (
    <div className="chart-container">
      <ResponsiveContainer>
        <ComposedChart
          data={buildData(props.customRange, props.period, props.chartReports)}
        >
          <XAxis
            dataKey="time"
            tick={<CustomizedAxisTick period={props.period} />}
            interval={
              props.period === FREQUENCY_WEEKLY ? 'preserveStartEnd' : 0
            }
          />
          <YAxis yAxisId={0} tickCount={12} />
          <YAxis yAxisId={1} orientation="right" />
          <Area
            yAxisId={1}
            type="monotone"
            fill="rgba(217, 218, 217)"
            stroke="rgba(183, 184, 183)"
            dataKey="transactionCount"
            name={TRANSACTION_COUNT}
          />
          <Bar
            yAxisId={0}
            barSize={14}
            fill="rgba(198, 223, 180)"
            dataKey="totalAmount"
            name={SETTLEMENT_AMOUNT}
          />
          <Bar
            yAxisId={0}
            barSize={14}
            fill="rgba(255, 155, 141)"
            dataKey="totalRefund"
            name={REFUND_AMOUNT}
          />
          <Legend
            verticalAlign="top"
            iconType="square"
            align="left"
            height={36}
            iconSize={20}
            formatter={value => t(value)}
          />
          <Tooltip
            labelFormatter={label => formatChartLabel(label, props.period)}
            formatter={(value, name, prop) => formatTooltipLabel(name, prop, t)}
          />
          <CartesianGrid />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
