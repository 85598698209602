const roles = {
  1: 'IEP Admin',
  2: 'Master Wallet Manager',
  3: 'Sub Wallet Manager',
  4: 'Ticket Issuer',
  5: 'Agency Read Only',
  6: 'CS Agent',
  7: 'Approver',
  8: 'Super Admin'
};

export const getUserRoleById = id => {
  return roles[id] || '';
};

export const isWithdrawAllowed = roleId => {
  const role = getUserRoleById(roleId);
  return (
    role === 'IEP Admin' ||
    role === 'Master Wallet Manager' ||
    role === 'Sub Wallet Manager'
  );
};

export const isAdmin = roleId => getUserRoleById(roleId) === 'IEP Admin';
