/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Card, Button, Loader } from '../components';
import Modal from '../components/Modal';
import PageHeader from '../components/PageHeader';
import { Back, Edit, Trash, CancelCard } from '../icons';
import MainUserInformation from '../components/MainUserInformation';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';
import DisableUser from './DisableUser';
import EnableUser from './EnableUser';
import { getUserRoleById } from '../../utils/getUserRoleById';
import { ShowForPermission } from '../ShowForPermission';

const Root = styled.div`
  width: 745px;
  margin: 0 auto;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
const RowStretched = styled(Row)`
  justify-content: space-between;
`;
const BackButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
`;
const Text = styled.div`
  flex: 3;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  margin: 4px 0;
`;
const WalletText = styled(Text)`
  flex: 1;
`;
const GreyText = styled(Text)`
  flex: 1;
  color: rgba(5, 34, 56, 0.56);
`;
const WalletBlock = styled(Row)`
  margin-top: 20px;
`;
const GreyTextSmall = styled(GreyText)`
  font-size: 12px;
`;
const GreyTextCurrency = styled(GreyText)`
  font-size: 12px;
  flex: 3;
`;
const Divider = styled.div`
  margin-top: 7px;
  margin-bottom: 16px;
  width: 250px;
  height: 1px;
  border-bottom: 1px solid rgba(5, 34, 56, 0.12);
`;
const DividerFull = styled(Divider)`
  width: 100%;
`;
const ActionBlock = styled(Row)`
  border-top: 1px solid rgba(5, 34, 56, 0.12);
  padding-top: 10px;
`;
const ButtonDisable = styled.button`
  display: flex;
  flex-direaction: row;
  align-items: center;
  color: #004b87;
  text-decoration: underline;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  padding: 5px;
  > svg {
    padding-right: 5px;
  }
`;
const ButtonDelete = styled(ButtonDisable)`
  color: rgba(233, 20, 20, 0.87);
  margin-left: 25px;
`;

const User = props => {
  const [t] = useTranslation('translation');

  useEffect(() => {
    // why are we making these requests when user has no permission to see users
    props.getUserDetailsApproved(props.match.params.userId);
    props.getUserDataForEditing(props.match.params.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const walletList =
    props.user &&
    props.user.wallets &&
    props.user.wallets.map(wallet => (
      <Row key={wallet.walletId}>
        <WalletText>{wallet.name}</WalletText>
        <Text>{wallet.currency}</Text>
      </Row>
    ));
  const agencyList =
    props.user &&
    props.user.agencies &&
    props.user.agencies.map(agency => (
      <Row key={agency.agencyId}>
        <Text>{agency.name}</Text>
        <Text>{agency.agentCode}</Text>
      </Row>
    ));

  return (
    <ShowForPermission contextName="User" action="Read">
      <Root>
        {!props.isLoading && props.user ? (
          <>
            <RowStretched>
              <Row>
                <BackButton onClick={props.history.goBack}>
                  <Back />
                </BackButton>
                <PageHeader>{props.user.name}</PageHeader>
              </Row>
              <ShowForPermission contextName="User" action="Update">
                {props.user.canBeEdited && (
                  <Button
                    title={t('Edit')}
                    icon={<Edit />}
                    onClick={() =>
                      props.openModal('editUser', t('Edit user account'))
                    }
                    secondary
                  />
                )}
              </ShowForPermission>
            </RowStretched>
            <Card>
              <MainUserInformation
                status={props.user.status}
                email={props.user.email}
                code={props.user.agentCode}
                //  wallets={props.user.wallets.length}
              />
              <Row>
                <GreyText>{t('User role')}</GreyText>
                <Text>{getUserRoleById(props.user.role)}</Text>
              </Row>
              {props.user.wallets && props.user.wallets.length > 0 && (
                <>
                  <WalletBlock>
                    <GreyTextSmall>{t('Assigned wallet')}</GreyTextSmall>
                    <GreyTextCurrency>{t('Currency')}</GreyTextCurrency>
                  </WalletBlock>
                  <Divider />
                  <div>{walletList}</div>
                </>
              )}
              {props.user.agencies && props.user.agencies.length > 0 && (
                <>
                  <WalletBlock>
                    <GreyTextCurrency>{t('Assigned agency')}</GreyTextCurrency>
                    <GreyTextCurrency>{t('Agent code')}</GreyTextCurrency>
                  </WalletBlock>
                  <DividerFull />
                  <div>{agencyList}</div>
                </>
              )}
              <ActionBlock>
                <ShowForPermission contextName="User" action="Update">
                  {props.user.canBeActivated && (
                    <ButtonDisable
                      onClick={() =>
                        props.openModal(
                          'enableUser',
                          `${t('Enable')} ${props.user.name}`
                        )
                      }
                    >
                      <CancelCard blue />
                      {t('Enable user')}
                    </ButtonDisable>
                  )}
                  {props.user.canBeInactivated && (
                    <ButtonDisable
                      onClick={() =>
                        props.openModal(
                          'disableUser',
                          `${t('Disable')} ${props.user.name}`
                        )
                      }
                    >
                      <CancelCard blue />
                      {t('Disable user')}
                    </ButtonDisable>
                  )}
                </ShowForPermission>

                <ShowForPermission contextName="User" action="Delete">
                  {props.user.canBeDeleted && (
                    <ButtonDelete
                      onClick={() =>
                        props.openModal(
                          'deleteUser',
                          `${t('Delete')} ${props.user.name}`
                        )
                      }
                    >
                      <Trash /> {t('Delete user')}
                    </ButtonDelete>
                  )}
                </ShowForPermission>
              </ActionBlock>
            </Card>
            <Modal
              open={props.isModalOpen}
              title={props.modalTitle}
              closeModal={props.closeModal}
            >
              {props.modalType === 'editUser' ? (
                <EditUser
                  saveUser={props.saveUser}
                  closeModal={props.closeModal}
                />
              ) : props.modalType === 'disableUser' ? (
                <DisableUser
                  disableUser={props.disableUser}
                  userId={props.user.id}
                  closeModal={props.closeModal}
                />
              ) : props.modalType === 'enableUser' ? (
                <EnableUser
                  enableUser={props.enableUser}
                  userId={props.user.id}
                  closeModal={props.closeModal}
                />
              ) : (
                <DeleteUser
                  deleteUser={props.deleteUser}
                  userId={props.user.id}
                  closeModal={props.closeModal}
                  goBack={props.history.goBack}
                />
              )}
            </Modal>
          </>
        ) : (
          <Loader />
        )}
      </Root>
    </ShowForPermission>
  );
};

export default User;
