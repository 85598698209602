// @flow
import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import Select from 'react-select';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextInput, Button } from '../components';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonProceed = styled(Button)`
  position: absolute;
  bottom: 24px;
  left: 140px;
`;
const Label = styled.p`
  color: rgba(5, 34, 56, 0.87);
  font-size: 12px;
  margin-bottom: 5px;
  opacity: 0.56;
`;

const renderField = props => {
  const {
    input,
    type,
    meta: { touched, error },
    placeholder,
    label,
    maxlength
  } = props;
  return (
    <TextInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...input}
      type={type}
      error={!touched && error}
      label={label}
      placeholder={placeholder}
      helperText={error}
      maxlength={maxlength}
    />
  );
};

const validate = values => {
  const errors = {};
  if (!values.walletName || values.walletName.trim().length === 0) {
    errors.walletName = 'Cannot be empty';
  }
  if (!values.internalId) {
    errors.internalId = 'Cannot be empty';
  }
  return errors;
};
// eslint-disable-next-line react/jsx-props-no-spreading
const AmountField = props => <Field component={TextInput} {...props} />;
const renderSelect = props => {
  const {
    input,
    meta: { touched, error },
    label,
    placeholder,
    options,
    maxMenuHeight,
    disabled
  } = props;
  return (
    <div>
      <Label>{label}</Label>
      <Select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        isSearchable={false}
        placeholder={placeholder}
        error={!touched && error}
        options={options}
        onBlur={() => input.onBlur(input.value)}
        isDisabled={disabled}
        maxMenuHeight={maxMenuHeight}
      />
    </div>
  );
};

const AddWallet = props => {
  const [t] = useTranslation('translation');
  const { accessibleAgencies } = props;
  const handleAddWalletFormSubmit = val => {
    const data = {
      walletName: val.walletName,
      agentCode: val.internalId.value
    };
    props.submitMethod(data);
    props.closeModal();
  };

  const iataCodes = accessibleAgencies.map(agency => {
    return { value: agency.agentCode, label: agency.agentCode };
  });
  const numbersAndLettersOnly = value => {
    const NOT_ALLOWED_CHARS_REGEXP = /[-!$%^&*()_+|~=`{}[\]:";'<>?,./]/;
    return value.replace(NOT_ALLOWED_CHARS_REGEXP, '');
  };

  return (
    <Root>
      <form onSubmit={props.handleSubmit(handleAddWalletFormSubmit)}>
        <Field
          component={renderSelect}
          name="internalId"
          options={iataCodes}
          label={t('IATA Code')}
          placeholder={t('Choose IATA code')}
          disabled={props.accessibleAgencies.length === 1}
          maxMenuHeight={100}
        />
        <AmountField
          name="walletName"
          component={renderField}
          placeholder={t('Enter wallet name')}
          label={t('Wallet name')}
          normalize={numbersAndLettersOnly}
          // onKeyPress={event => restrictCharacters(event, ALLOWED_CHARS_REGEXP)}
          maxlength="50"
        />
        <ButtonProceed
          primary
          title={t('Save')}
          type="submit"
          disabled={props.invalid}
        />
      </form>
    </Root>
  );
};

const formCreator = reduxForm({
  form: 'addWallet',
  validate
});

function mapStateToProps(state) {
  const { accessibleAgencies } = state.agencies;
  const agenciesLength = accessibleAgencies.length;

  return {
    accessibleAgencies,
    initialValues: agenciesLength === 1 && {
      internalId: {
        value: accessibleAgencies[0].agentCode,
        label: accessibleAgencies[0].agentCode
      }
    }
  };
}

const reduxConnector = connect(mapStateToProps, null);

export default R.compose(reduxConnector, formCreator)(AddWallet);
