import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Agencies from './Agencies';
import { openModal, closeModal } from '../../../redux/actions/modals';
import { getAgencies } from '../../../redux/actions/agencies';
import { addAlert } from '../../../redux/actions/alerts';
import { initApp } from '../../../redux/actions/appState';
import { isAgencyClosed } from '../../../utils';
import { isAdmin } from '../../../utils/getUserRoleById';

const mapStateToProps = state => ({
  isLoading: state.agencies.isLoading,
  agencies: state.agencies.agencies,
  pageInfo: state.agencies.pageInfo,
  isAgencyClosed: isAgencyClosed(state.appState.account.agencyStatus),
  isAdmin: isAdmin(state.appState.account.role)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      initApp,
      addAlert,
      openModal,
      closeModal,
      getAgencies
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Agencies);
