import React from 'react';

const Edit = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      className={props.className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#004B87"
          d="M21.218 6.992c.438-.438.438-1.167 0-1.583l-2.627-2.627c-.416-.438-1.145-.438-1.583 0l-2.066 2.054 4.21 4.211 2.066-2.055zM1.333 18.456v4.21h4.21l12.42-12.429-4.211-4.21-12.419 12.43z"
        />
      </g>
    </svg>
  );
};
export default Edit;
