// @flow
import React from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../../assets/logo.png';
import { ShowForPermission } from '../../ShowForPermission';
import { INTERNAL_USER_IATA_CODE_KEY } from '../../RedirectPage';
import {
  PREFERRED_LOCALE,
  LOCALES,
  ENGLISH_LOCALE
} from '../../../constants/LocalizationConstants';

const Root = styled.div`
  background: #fff;
  padding: 8px 37px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const LinkUnderlined = styled(Link)`
  color: #004b87;
  text-decoration: underline;
  margin: 0 5px;
`;
const HrefUnderlined = styled.a`
  color: #004b87;
  text-decoration: underline;
  margin: 0 8px;
`;
const UserInfo = styled.div`
  color: rgba(5, 34, 56, 0.56);
  margin-right: 15px;
`;
const LogoutButton = styled.button`
  color: #004b87;
  text-decoration: underline;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  margin-right: 15px;
`;

const TopNav = ({
  isMenuShown,
  account,
  iataCode,
  logoutInternalRequest,
  location: { pathname },
  selectedLocale,
  changeLocale
}) => {
  const [t] = useTranslation('translation');

  const switchUser = () => {
    logoutInternalRequest();
  };

  const localStorageIataCode = localStorage.getItem(
    INTERNAL_USER_IATA_CODE_KEY
  );
  const code = iataCode || localStorageIataCode;

  const localesMap = LOCALES.reduce((map, l) => {
    // eslint-disable-next-line no-param-reassign
    map[l.language] = l;
    return map;
  }, {});

  const handleLocaleChange = event => {
    const newLocale = localesMap[event.target.value];
    changeLocale(newLocale);
    localStorage.setItem(PREFERRED_LOCALE, JSON.stringify(newLocale));
  };

  return (
    <Root>
      <Row>
        <img src={logo} alt="logo" />
        <HrefUnderlined href="https://portal.iata.org">
          {t('IATA customer portal')}
        </HrefUnderlined>
        <LinkUnderlined to="/portal/help">{t('Help')}</LinkUnderlined>
        {isMenuShown && (
          <ShowForPermission contextName="Agency" action="Read">
            <LinkUnderlined to="/portal/settings">
              {t('Settings')}
            </LinkUnderlined>
          </ShowForPermission>
        )}
      </Row>
      {Boolean(code) && !pathname.includes('/internal-code') && (
        <Row>
          {t('IATA Code')}: {code}{' '}
          <LogoutButton onClick={switchUser}>switch</LogoutButton>
        </Row>
      )}
      <Row>
        {account.name && (
          <UserInfo>
            {t('Signed in as')}: {account.name}
          </UserInfo>
        )}
        <select
          onChange={event => handleLocaleChange(event)}
          value={selectedLocale.language}
        >
          {LOCALES.filter(
            locale =>
              locale.language === ENGLISH_LOCALE.language ||
              locale.language ===
                (account.agentCulture ? account.agentCulture.language : 'en')
          ).map(locale => (
            <option key={locale.culture} value={locale.language}>
              {locale.displayLanguage}
            </option>
          ))}
        </select>
      </Row>
    </Root>
  );
};

export default withRouter(TopNav);
