import React from 'react';
import {
  Root,
  Icon,
  Label,
  HelpText,
  InputContent,
  StyledInput
} from './FormComponentElements';

const TextInput = props => {
  const {
    className,
    label,
    labelOpacity,
    type = 'text',
    icon,
    id,
    value,
    helpTextColor,
    helpTextFontSize,
    placeholder,
    onChange,
    rawErrors,
    maxlength,
    disabled,
    min,
    helpText,
    touched,
    onFocus,
    onBlur,
    ...restProps
  } = props;

  return (
    <Root className={className}>
      <Label htmlFor={id} opacity={labelOpacity}>
        {label}
      </Label>
      <InputContent>
        <Icon>{icon}</Icon>
        <StyledInput
          {...restProps}
          touched={touched}
          type={type}
          id={id}
          withIcon={icon}
          value={value}
          min={min}
          placeholder={placeholder}
          onChange={event => onChange(event.target.value)}
          error={rawErrors}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={maxlength}
          disabled={disabled}
        />
      </InputContent>
      {helpText && (
        <HelpText color={helpTextColor} fontSize={helpTextFontSize}>
          {helpText}
        </HelpText>
      )}
      <HelpText error={rawErrors}>{touched ? rawErrors : ''}</HelpText>
    </Root>
  );
};
export default TextInput;
