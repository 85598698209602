import React from 'react';

const Cancel = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      className={props.className}
    >
      <path
        fill={props.color ? `${props.color}` : '#F15C5C'}
        fillRule="evenodd"
        d="M19.071 6.343L13.414 12l5.657 5.657-1.414 1.414L12 13.414l-5.657 5.657-1.414-1.414L10.586 12 4.929 6.343 6.343 4.93 12 10.586l5.657-5.657 1.414 1.414z"
      />
    </svg>
  );
};
export default Cancel;
