import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, PageHeader, BackButton } from '../ReportDetails/components/style';
import { Back, Download } from '../icons';
import { Card, InlineLoader } from '../components';
import Button from '../components/Button';
import downloadDocument from '../../redux/actions/document';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoldText = styled.span`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
`;

const GreyText = styled.span`
  color: rgba(178, 178, 178, 1);
  font-size: 14px;
  margin-bottom: 32px;
`;

const ButtonStyled = styled(Button)`
  width: 150px;
  justify-content: flex-start;
  margin-right: 16px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Help = ({
  history,
  downloadDocument: downloadFaqDocument,
  isDocumentLoading
}) => {
  const [t] = useTranslation('translation');

  const download = () => {
    downloadFaqDocument('document/faq', 'FAQ.pdf');
  };

  return (
    <>
      <Row>
        <PageHeader>
          <BackButton onClick={() => history.goBack()}>
            <Back />
          </BackButton>
          {t('Help')}
        </PageHeader>
      </Row>
      <Card>
        <Column>
          <BoldText>{t('Frequently Asked Questions')}</BoldText>
          <GreyText>
            {t('Need guildline? Check out the FAQ for general information.')}
          </GreyText>
          <StyledRow>
            <ButtonStyled
              primary
              title="FAQ"
              icon={<Download className="faq-download-icon" />}
              onClick={download}
            />
            {isDocumentLoading && <InlineLoader size={24} />}
          </StyledRow>
        </Column>
      </Card>
    </>
  );
};

const mapStateToProps = state => ({
  isDocumentLoading: state.documents.isLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      downloadDocument
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Help);
