/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef } from 'react';
import ReactTable from 'react-table';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import TableFooter from '../../components/Table/TableFooter';
import { userStatusById } from '../../../utils/userStatusById';
import { getUserRoleById } from '../../../utils/getUserRoleById';
import { restrictCharacters } from '../../../utils/restrictCharacters';
import NoRowsFound from '../../components/NoRowsTable';

const POSITIVE_NUMBERS_REGEX = /[^\d]/;

const StatusLabel = styled.div`
  display: inline-flex;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: 0.2px;
  padding: 2px 4px;
  border-radius: 2px;
  color: ${props =>
    props.status === 2 || props.status === 5
      ? '#00b326'
      : props.status === 3 ||
        props.status === 4 ||
        props.status === 6 ||
        props.status === 7
      ? 'rgba(233, 20, 20, 0.87)'
      : '#fac832'};
  background: ${props =>
    props.status === 2 || props.status === 5
      ? 'rgba(0,179,38, 0.15)'
      : props.status === 3 ||
        props.status === 4 ||
        props.status === 6 ||
        props.status === 7
      ? 'rgba(233, 20, 20, 0.15)'
      : 'rgba(250,200,50,0.15)'};
`;
const ButtonUnderlined = styled.button`
  color: #004b87;
  text-decoration: underline;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
`;
const UserName = styled.div`
  margin-left: 5px;
`;

const UserList = props => {
  const [t] = useTranslation('translation');
  const { getUsersApproved, getUserStatuses } = props;
  const [onFilter] = useDebouncedCallback((filterable, page, pageSize) => {
    getUsersApproved(page + 1, pageSize, filterable);
  }, 1000);

  const currentPageRef = useRef(null);
  const currentPageSizeRef = useRef(null);

  const userStatusesOptions = props.userStatuses.map(status => (
    <option key={status.id} value={status.id}>
      {status.displayName}
    </option>
  ));
  const ALLOWED_CHARS_REGEXP = /[0-9/]+/;

  useEffect(() => {
    getUserStatuses();
  }, [getUserStatuses]);

  const columns = [
    {
      Header: t('User name'),
      accessor: 'name',
      Cell: row => {
        const { userId } = row.original;
        const { canBeEdited } = row.original;
        return canBeEdited ? (
          <ButtonUnderlined
            onClick={() => props.history.push(`/portal/users/${userId}`)}
          >
            {row.value}
          </ButtonUnderlined>
        ) : (
          <UserName>{row.value}</UserName>
        );
      },
      Filter: ({ filter, onChange }) => (
        <input
          type="text"
          placeholder={t('Search...')}
          value={filter ? filter.value : ''}
          onChange={event => onChange(event.target.value)}
        />
      )
    },
    {
      Header: t('Email'),
      accessor: 'email',
      Filter: ({ filter, onChange }) => (
        <input
          type="text"
          placeholder={t('Search...')}
          value={filter ? filter.value : ''}
          onChange={event => onChange(event.target.value)}
        />
      )
    },
    {
      Header: t('IATA Code'),
      accessor: 'agentCode',
      Filter: ({ filter, onChange }) => (
        <input
          type="text"
          placeholder={t('Search...')}
          maxLength={8}
          onKeyPress={event => restrictCharacters(event, ALLOWED_CHARS_REGEXP)}
          value={filter ? filter.value : ''}
          onChange={event =>
            onChange(event.target.value.replace(POSITIVE_NUMBERS_REGEX, ''))
          }
        />
      )
    },
    {
      Header: t('User role'),
      accessor: 'role',
      Cell: row => <div>{getUserRoleById(row.value)}</div>,
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : ''}
        >
          <option value="0">All</option>
          <option value="1">IEP Admin</option>
          <option value="2">Master Wallet Manager</option>
          <option value="3">Sub Wallet Manager</option>
          <option value="4">Ticket Issuer</option>
          <option value="5">Agency Read Only</option>
        </select>
      )
    },
    {
      Header: t('User status'),
      accessor: 'status',
      Cell: row => (
        <StatusLabel status={row.value}>
          {userStatusById(row.value)}
        </StatusLabel>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : ''}
        >
          {userStatusesOptions}
        </select>
      )
    }
  ];

  return (
    <div>
      <ReactTable
        PaginationComponent={TableFooter}
        NoDataComponent={NoRowsFound}
        data={props.users}
        columns={columns}
        defaultPageSize={10}
        filterable
        loading={props.isLoading}
        manual
        minRows={1}
        sortable={false}
        pageInfo={props.pageInfo}
        pages={props.pageInfo && props.pageInfo.totalPage}
        onFetchData={({ page, pageSize, filtered }) => {
          if (
            currentPageRef.current === null ||
            currentPageRef.current !== page ||
            currentPageSizeRef.current === null ||
            currentPageSizeRef.current !== pageSize
          ) {
            currentPageRef.current = page;
            currentPageSizeRef.current = pageSize;
            props.getUsersApproved(page + 1, pageSize, filtered);
          } else {
            onFilter(filtered, page, pageSize);
          }
        }}
        className="-highlight"
      />
    </div>
  );
};

export default UserList;
