import { OPEN_MODAL, CLOSE_MODAL } from '../../constants/actionTypes';

export const initState = {
  isModalOpen: false,
  modalType: null,
  modalTitle: '',
  modalData: null
};

export default {
  [OPEN_MODAL]: (state, payload) => {
    return {
      ...state,
      isModalOpen: true,
      modalType: payload.modalType,
      modalTitle: payload.modalTitle,
      modalData: payload.modalData
    };
  },
  // eslint-disable-next-line no-unused-vars
  [CLOSE_MODAL]: (state, payload) => {
    return {
      ...state,
      isModalOpen: false,
      modalType: null,
      modalTitle: '',
      modalData: null
    };
  }
};
