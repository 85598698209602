import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Root = styled.footer`
  display: flex;
  flex-direction: row;
  background: #004b87;
  color: rgba(255, 255, 255, 0.87);
  padding: 12px;
  justify-content: center;
  align-items: center;
`;

const Footer = () => {
  const [t] = useTranslation('');
  return (
    <Root>
      {t('International Air Transport Association (IATA). All rights reserved')}
    </Root>
  );
};
export default Footer;
