/* eslint-disable consistent-return */
import {
  FETCH_WALLETS,
  ADD_NEW_WALLET,
  FETCH_WALLET,
  FETCH_WALLET_TRANSACTIONS,
  MAKE_TRANSFER,
  MAKE_WITHDRAW,
  CLOSE_WALLET,
  GENERATE_IEP_NUMBER,
  GET_ACCESSIBLE_WALLETS,
  CLOSE_IEP_NUMBER,
  FETCH_WALLET_TRANSACTION_DETAIL,
  ADD_ALERT,
  CLOSE_MODAL
} from '../../constants/actionTypes';
import { api } from '../../services/rest';
import { getGetParams } from '../../utils/getGetParams';
import { addAlert } from './alerts';
import i18n from '../../i18n/i18n';

export const getWalletsRequest = () => ({
  type: FETCH_WALLETS.REQUEST
});

export const getWalletsSuccess = data => ({
  type: FETCH_WALLETS.SUCCESS,
  data
});

export const getWalletsFailure = error => ({
  type: FETCH_WALLETS.FAILURE,
  error
});

// export const getWallets = (currentPage, pageSize, filtered) => dispatch => {
//   dispatch(getWalletsRequest());
//   const filteredQuery = getGetParams(filtered);
//   return api
//     .get(`wallets?PageNo=${currentPage}&PageSize=${pageSize}&${filteredQuery}`)
//     .then(
//       response => {
//         if (response.responseCode === '000') {
//           return dispatch(getWalletsSuccess(response));
//         }
//         if (response.responseCode === 'Unauthorized') {
//           dispatch({
//             type: ADD_ALERT,
//             payload: { alertType: 'error', text: response.resDescription }
//           });
//           return dispatch(getWalletsSuccess(response));
//         }
//         dispatch(getWalletsFailure(response));
//       },
//       err => dispatch(getWalletsFailure(err))
//     );
// };
export const getWallets = params => dispatch => {
  dispatch(getWalletsRequest());
  const urlParams = params ? `?${params}` : '';
  return api.get(`wallets${urlParams}`).then(
    response => {
      if (response.responseCode === '000') {
        return dispatch(getWalletsSuccess(response));
      }
      if (response.responseCode === 'Unauthorized') {
        dispatch({
          type: ADD_ALERT,
          payload: { alertType: 'error', text: response.resDescription }
        });
        return dispatch(getWalletsSuccess(response));
      }
      dispatch(getWalletsFailure(response));
    },
    err => dispatch(getWalletsFailure(err))
  );
};

export const addNewWalletRequest = () => ({
  type: ADD_NEW_WALLET.REQUEST
});

export const addNewWalletSuccess = data => ({
  type: ADD_NEW_WALLET.SUCCESS,
  data
});

export const addNewWalletFailure = error => ({
  type: ADD_NEW_WALLET.FAILURE,
  error
});

export const addNewWallet = data => dispatch => {
  dispatch(addNewWalletRequest());

  return api.post('wallets', data).then(
    response => {
      if (response.responseCode === '000') {
        dispatch(addNewWalletSuccess(response));
        dispatch({
          type: ADD_ALERT,
          payload: {
            alertType: 'success',
            text: i18n.t('New wallet has been successfully created', {
              walletName: data.walletName
            })
          }
        });
      } else {
        dispatch(addNewWalletFailure(response));
        dispatch({
          type: ADD_ALERT,
          payload: { alertType: 'error', text: response.resDescription }
        });
      }
    },
    err => {
      dispatch(addNewWalletFailure(err));
      dispatch({
        type: ADD_ALERT,
        payload: { alertType: 'error', text: err.message }
      });
    }
  );
};

// TODO: fix for real API
export const getWalletRequest = () => ({
  type: FETCH_WALLET.REQUEST
});

export const getWalletSuccess = data => ({
  type: FETCH_WALLET.SUCCESS,
  data
});

export const getWalletFailure = error => ({
  type: FETCH_WALLET.FAILURE,
  error
});

export const getWalletById = id => dispatch => {
  dispatch(getWalletRequest());

  return api.get(`wallets/${id}`).then(
    response => {
      if (response.responseCode === '000' || response.responseCode === 'OK') {
        return dispatch(getWalletSuccess(response));
      }
      if (response.responseCode === 'U05') {
        dispatch(getWalletSuccess(response));
        dispatch({
          type: ADD_ALERT,
          payload: { alertType: 'error', text: response.resDescription }
        });
      } else if (response.responseCode === 'Unauthorized') {
        dispatch({
          type: ADD_ALERT,
          payload: {
            alertType: 'error',
            text: 'GIP client processing failure (Unauthorized)'
          }
        });
        return dispatch(getWalletSuccess(response));
      } else {
        dispatch(getWalletFailure(response));
      }
    },
    err => dispatch(getWalletFailure(err))
  );
};

export const getWalletTransactionsRequest = () => ({
  type: FETCH_WALLET_TRANSACTIONS.REQUEST
});

export const getWalletTransactionsSuccess = data => ({
  type: FETCH_WALLET_TRANSACTIONS.SUCCESS,
  data
});

export const getWalletTransactionsFailure = error => ({
  type: FETCH_WALLET_TRANSACTIONS.FAILURE,
  error
});

export const getWalletTransactions = (
  id,
  currentPage,
  pageSize,
  filtered
) => dispatch => {
  dispatch(getWalletTransactionsRequest());
  const filteredQuery = getGetParams(filtered);

  return api
    .get(
      `wallets/${id}/transactions?PageNo=${currentPage}&PageSize=${pageSize}&${filteredQuery}`
    )
    .then(
      response => {
        if (response.responseCode === '000') {
          return dispatch(getWalletTransactionsSuccess(response));
        }
        dispatch(getWalletTransactionsFailure(response));
      },
      err => dispatch(getWalletTransactionsFailure(err))
    );
};

// transfer
export const makeTransfer = (id, data) => dispatch => {
  dispatch({ type: MAKE_TRANSFER.REQUEST });
  return api.post(`wallets/${id}/transfers`, data).then(
    response => {
      if (response.responseCode === '000') {
        dispatch({ type: MAKE_TRANSFER.SUCCESS, payload: response });
        dispatch({
          type: ADD_ALERT,
          payload: { alertType: 'success', text: 'Transfer is done' }
        });
        dispatch(getWalletTransactions(id, 1, 10));
        dispatch(getWalletById(id));
      } else {
        dispatch({ type: MAKE_TRANSFER.FAILURE, payload: response });
        dispatch({
          type: ADD_ALERT,
          payload: { alertType: 'error', text: response.resDescription }
        });
      }
    },
    err => dispatch(getWalletTransactionsFailure(err))
  );
};
// eslint-disable-next-line no-unused-vars
export const getAccessibleWallets = openOnly => dispatch => {
  dispatch({ type: GET_ACCESSIBLE_WALLETS.REQUEST });

  return api.get(openOnly ? `wallets/openBriefly` : `wallets/briefly`).then(
    response => {
      if (response.responseCode === '000') {
        return dispatch({
          type: GET_ACCESSIBLE_WALLETS.SUCCESS,
          payload: response
        });
      }
      dispatch({ type: GET_ACCESSIBLE_WALLETS.FAILURE, payload: response });
    },
    err => dispatch({ type: GET_ACCESSIBLE_WALLETS.FAILURE, payload: err })
  );
};
// Withdraw

export const withdraw = (id, data) => dispatch => {
  dispatch({ type: MAKE_WITHDRAW.REQUEST });
  return api.post(`wallets/${id}/withdrawals`, data).then(
    response => {
      if (response.responseCode === '000') {
        dispatch({ type: MAKE_WITHDRAW.SUCCESS, payload: response });
        dispatch({
          type: ADD_ALERT,
          payload: {
            alertType: 'success',
            text: i18n.t('Withdrawal amount is successfully proceeded', {
              amount: data.amount
            })
          }
        });
        dispatch(getWalletById(id));
        dispatch(getWalletTransactions(id, 1, 10));
      } else {
        dispatch({ type: MAKE_WITHDRAW.FAILURE, payload: response });
        dispatch({
          type: ADD_ALERT,
          payload: { alertType: 'error', text: response.resDescription }
        });
        dispatch(getWalletTransactions(id, 1, 10));
      }
    },
    err => dispatch({ type: MAKE_WITHDRAW.FAILURE, payload: err })
  );
};

// Close wallet
export const closeWallet = (id, data) => dispatch => {
  dispatch({ type: CLOSE_WALLET.REQUEST });
  return api.put(`wallets/${id}`, data).then(
    response => {
      if (response.responseCode === '000' || response.responseCode === 'OK') {
        dispatch({ type: CLOSE_WALLET.SUCCESS, payload: response });
        dispatch(getWalletById(id));
      } else {
        dispatch(
          addAlert({ alertType: 'error', text: response.resDescription })
        );
        dispatch({ type: CLOSE_WALLET.FAILURE, payload: response });
      }
    },
    err => dispatch({ type: CLOSE_WALLET.FAILURE, payload: err })
  );
};

// IEP number
export const generateIEPNumber = (id, data) => dispatch => {
  dispatch({ type: GENERATE_IEP_NUMBER.REQUEST });
  return api.post(`wallets/${id}/iep`, data).then(
    response => {
      if (response.responseCode === '000' || response.responseCode === 'OK') {
        dispatch({ type: GENERATE_IEP_NUMBER.SUCCESS, payload: response });
        dispatch(getWalletById(id));
      } else {
        dispatch({ type: GENERATE_IEP_NUMBER.FAILURE, payload: response });
      }
    },
    err => dispatch({ type: GENERATE_IEP_NUMBER.FAILURE, payload: err })
  );
};

export const closeIEPNumber = id => dispatch => {
  dispatch({ type: CLOSE_IEP_NUMBER.REQUEST });
  return api.delete(`wallets/${id}/iep`).then(
    response => {
      if (response.responseCode === '000') {
        // TODO: We may close wrong modal if we get response
        // after we closed "closeIEPNumber" modal and opened another
        dispatch({ type: CLOSE_MODAL });
        dispatch({ type: CLOSE_IEP_NUMBER.SUCCESS, payload: response });
        dispatch(getWalletById(id));
        dispatch(getWallets());
        dispatch({
          type: ADD_ALERT,
          payload: {
            alertType: 'success',
            text: i18n.t('IEP number is removed')
          }
        });
      } else {
        dispatch({ type: CLOSE_IEP_NUMBER.FAILURE, payload: response });
        dispatch({
          type: ADD_ALERT,
          payload: { alertType: 'error', text: response.resDescription }
        });
      }
    },
    err => dispatch({ type: CLOSE_IEP_NUMBER.FAILURE, payload: err })
  );
};

// Wallet transaction details
export const getWalletTransactionDetail = (
  walletId,
  transactionId
) => dispatch => {
  dispatch({ type: FETCH_WALLET_TRANSACTION_DETAIL.REQUEST });
  return api.get(`wallets/${walletId}/transactions/${transactionId}`).then(
    response => {
      if (response.responseCode === '000') {
        dispatch({
          type: FETCH_WALLET_TRANSACTION_DETAIL.SUCCESS,
          payload: response
        });
      } else {
        dispatch({
          type: FETCH_WALLET_TRANSACTION_DETAIL.FAILURE,
          payload: response
        });
      }
    },
    err =>
      dispatch({ type: FETCH_WALLET_TRANSACTION_DETAIL.FAILURE, payload: err })
  );
};

// Export wallets list

export const exportWalletsList = () => {
  api
    .get(`wallets/report`, {
      responseType: 'arraybuffer'
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Wallets.xlsx');
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => console.log(err));
};
