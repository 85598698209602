// @flow
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Select from 'react-select';
import { connect } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { TextInput, Button, FileInput } from '../../components';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
`;

const TextBlock = styled.div`
  margin-top: 8px;
  margin-bottom: 18px;
  line-height: 1.43;
`;
const ButtonProceed = styled(Button)`
  position: absolute;
  bottom: 24px;
  left: 140px;
`;
const Label = styled.p`
  color: rgba(5, 34, 56, 0.87);
  font-size: 12px;
  margin-bottom: 5px;
  opacity: 0.56;
`;
const InputGlobal = createGlobalStyle`
	.swift{
		margin-left: 8px;
	}
`;

const renderField = props => {
  const {
    input,
    type,
    meta: { touched, error },
    placeholder,
    label,
    className,
    maxlength,
    minlength
  } = props;
  return (
    <TextInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...input}
      type={type}
      error={!touched && error}
      label={label}
      placeholder={placeholder}
      helperText={error}
      className={className}
      maxlength={maxlength}
      minlength={minlength}
    />
  );
};
const renderSelect = props => {
  const {
    input,
    meta: { touched, error },
    label,
    placeholder,
    options
  } = props;
  return (
    <div>
      <Label>{label}</Label>
      <Select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        isSearchable={false}
        placeholder={placeholder}
        error={!touched && error}
        options={options}
        maxMenuHeight={130}
        onBlur={() => input.onBlur(input.value)}
      />
    </div>
  );
};
const renderFileField = props => {
  const {
    input,
    type,
    meta: { touched, error },
    placeholder,
    label,
    helpText
  } = props;
  return (
    <FileInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...input}
      type={type}
      error={!touched && error}
      label={label}
      placeholder={placeholder}
      helpText={helpText}
    />
  );
};

const validate = values => {
  const errors = {};
  if (!values.accountNumber || Number.isNaN(Number(values.accountNumber))) {
    errors.accountNumber = ' ';
  } else if (!values.accountName) {
    errors.accountName = ' ';
  } else if (!values.bankId) {
    errors.bankId = ' ';
  } else if (!values.bankAccountDocument) {
    errors.bankAccountDocument = ' ';
  }
  return errors;
};
// eslint-disable-next-line react/jsx-props-no-spreading
const FormField = props => <Field component={TextInput} {...props} />;
// eslint-disable-next-line react/jsx-props-no-spreading
const FileField = props => <Field component={FileInput} {...props} />;

const numbersOnly = value => {
  return value.replace(/[^\d]/g, '');
};

let RegisterBank = props => {
  const [t] = useTranslation('translation');

  const handleRegisterBankFormSubmit = val => {
    const formData = new FormData();
    formData.append('accountNumber', val.accountNumber);
    formData.append('bankId', val.bankId.value);
    formData.append('accountDocument', val.bankAccountDocument.name);
    formData.append('accountName', val.accountName);
    formData.append('bankAccountDocument', val.bankAccountDocument);

    props.submitMethod(formData);
    props.closeModal();
  };

  const banksOptions = [...props.banks]
    .sort((a, b) => (a.bankName > b.bankName ? 1 : -1))
    .map(bank => {
      return { value: bank.bankId, label: bank.bankName };
    });

  return (
    <Root>
      <InputGlobal />
      {props.invalid ? (
        <TextBlock>
          <Trans i18nKey="You do not have registered bank account for withdrawal Line break" />
        </TextBlock>
      ) : (
        <TextBlock>{t('Register bank account to withdraw money')}</TextBlock>
      )}
      <form
        onSubmit={props.handleSubmit(handleRegisterBankFormSubmit)}
        encType="multipart/form-data"
      >
        <FormField
          name="accountName"
          component={renderField}
          placeholder={t('Enter account name')}
          label={t('Account name')}
        />
        <Field
          component={renderSelect}
          name="bankId"
          options={banksOptions}
          label={t('Bank name')}
          placeholder={t('Choose bank')}
        />
        <Row>
          <FormField
            name="accountNumber"
            component={renderField}
            placeholder={t('Enter account number')}
            normalize={numbersOnly}
            label={t('Account number')}
            maxlength="16"
            minlength="4"
          />
        </Row>
        <FileField
          name="bankAccountDocument"
          placeholder={t('Choose your file document')}
          label={t('Account Document')}
          component={renderFileField}
          helpText={t('Available formats')}
        />
        <ButtonProceed
          primary
          title={t('Register')}
          type="submit"
          disabled={props.invalid}
        />
      </form>
    </Root>
  );
};

RegisterBank = reduxForm({
  form: 'RegisterBankAccount',
  validate
})(RegisterBank);

const mapStateToProps = state => ({
  modalData: state.modals.modalData,
  errors: state.bankAccounts.errors,
  banks: state.bankAccounts.banks
});

export default connect(mapStateToProps)(RegisterBank);
