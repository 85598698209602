// @flow
import React from 'react';
import styled from 'styled-components';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { closeIEPNumber } from '../../redux/actions/wallets';
import {
  Button,
  ButtonUnderlined,
  InlineLoader as Loader
} from '../components';
import { api } from '../../services/rest';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const GreyText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  color: rgba(5, 34, 56, 0.56);
  margin-right: 15px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
`;

const IepCode = styled.div`
  height: 32px;
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.51px;
`;
const Cancel = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 25px;
  left: 140px;
`;
const Errors = styled.div`
  color: red;
`;

class CancelIEPNumber extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    iepNumber: this.props.modalData.IEPNumber || this.props.currentIEPNumber,
    iepNumberIsLoading: false,
    expDate: 'xx/xx',
    isNumberVisible: false,
    errors: null
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currentIEPNumber !== this.props.currentIEPNumber) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ iepNumber: this.props.currentIEPNumber });
    }
  }

  getIepNumber = () => {
    const id = this.props.modalData.walletId;
    this.setState({ iepNumberIsLoading: true });
    // TODO: this call is duplication of the call in src/components/Wallets/IEPNumber.js
    api
      .get(`wallets/${id}/iep`, { id })
      .then(
        response => {
          if (response.responseCode === '000') {
            if (Object.keys(response.data).length === 0) {
              this.setState({
                errors: this.props.t('Sorry. Data is not correct. Try again')
              });
            } else {
              this.setState({
                iepNumber: response.data.cardNo,
                isNumberVisible: true,
                expDate: response.data.cardExpiry,
                errors: ''
              });
            }
          } else {
            this.setState({ errors: response.resDescription });
          }
        },
        err => this.setState({ errors: err.resDescription })
      )
      .finally(() => {
        this.setState({ iepNumberIsLoading: false });
      });
  };

  hideIep = () =>
    this.setState({
      iepNumber: this.props.modalData.IEPNumber,
      isNumberVisible: false,
      expDate: 'xx/xx'
    });

  deleteIEP = () => {
    this.props.closeIEPNumber(this.props.modalData.walletId);
  };

  render() {
    const {
      iepNumber,
      isNumberVisible,
      expDate,
      errors,
      iepNumberIsLoading
    } = this.state;
    return (
      <Root>
        {errors && <Errors>{errors}</Errors>}
        <p>{this.props.t('Are you sure you want to cancel this IEP number')}</p>
        <Row>
          <IepCode>{iepNumber || ''}</IepCode>
          {isNumberVisible ? (
            <ButtonUnderlined onClick={this.hideIep}>
              {this.props.t('Hide number')}
            </ButtonUnderlined>
          ) : (
            <div style={{ display: 'flex' }}>
              <ButtonUnderlined
                disabled={iepNumberIsLoading}
                onClick={this.getIepNumber}
              >
                {this.props.t('Show number')}
              </ButtonUnderlined>
              {iepNumberIsLoading && <Loader size={18} />}
            </div>
          )}
        </Row>
        <Row>
          <GreyText>{this.props.t('Expiry date')}</GreyText>
          <div>{expDate || ''}</div>
        </Row>
        <Cancel>
          <Button
            disabled={this.props.isCancelIEPNumberLoading}
            title={this.props.t('Yes, cancel IEP number')}
            destructive
            onClick={this.deleteIEP}
          />
          {this.props.isCancelIEPNumberLoading && (
            <Loader style={{ marginLeft: '10px' }} size={18} />
          )}
        </Cancel>
      </Root>
    );
  }
}

const mapStateToProps = state => ({
  modalData: state.modals.modalData,
  isCancelIEPNumberLoading: state.wallets.isCancelIEPNumberLoading
  // iepNumberState: state.wallets.currentWallet && state.wallets.currentWallet.iepCode,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeIEPNumber
    },
    dispatch
  );
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translation')
)(CancelIEPNumber);
