import React from 'react';

const Search = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      className={props.className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h18v18H0z" />
        <path
          fill="#004B87"
          d="M11.625 10.5l3.75 3.75-1.125 1.125-3.75-3.75v-.592l-.203-.21A4.853 4.853 0 0 1 7.125 12 4.875 4.875 0 1 1 12 7.125a4.853 4.853 0 0 1-1.178 3.172l.21.203h.593m-4.5 0C9 10.5 10.5 9 10.5 7.125S9 3.75 7.125 3.75A3.361 3.361 0 0 0 3.75 7.125C3.75 9 5.25 10.5 7.125 10.5M9 7.5H7.5V9h-.75V7.5h-1.5v-.75h1.5v-1.5h.75v1.5H9v.75z"
        />
      </g>
    </svg>
  );
};
export default Search;
