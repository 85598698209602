import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// eslint-disable-next-line import/extensions
import translationEng from '../locales/en/translation';
// eslint-disable-next-line import/extensions
import translationTh from '../locales/th/translation';
// eslint-disable-next-line import/extensions
import translationVn from '../locales/vi/translation';
// eslint-disable-next-line import/extensions
import translationId from '../locales/id/translation';

import { ENGLISH_LOCALE } from '../constants/LocalizationConstants';

const languageFromLocalStorage = localStorage.getItem('i18nextLng');

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    lng: languageFromLocalStorage || ENGLISH_LOCALE.language,
    fallbackLng: ENGLISH_LOCALE.language,
    interpolation: {
      escapeValue: false
    },
    keySeparator: false,
    resources: {
      en: {
        translation: translationEng
      },
      th: {
        translation: translationTh
      },
      // Disabled for now
      // ms: {
      //   translation: translationMy
      // },
      vi: {
        translation: translationVn
      },
      id: {
        translation: translationId
      }
    }
  });

export default i18n;
