import queryString from 'query-string';
import moment from 'moment';
import flowright from 'lodash.flowright';
import { filterEntries } from './helpers';

const parserOptions = { arrayFormat: 'index' };
export const parseQueryParams = params =>
  queryString.parse(params, parserOptions);
export const stringifyQueryParams = params =>
  queryString.stringify(params, parserOptions);

export const formatValues = arr =>
  arr.map(([key, value]) => {
    if (value instanceof Date) {
      return [key, moment(value).format('YYYY-MM-DD')];
    }
    if (value instanceof Object) {
      return [key, value.value];
    }
    return [key, value];
  });

export const stringifyQueryParamsWithOmit = flowright(
  stringifyQueryParams,
  Object.fromEntries,
  formatValues,
  filterEntries,
  Object.entries
);
