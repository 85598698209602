/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import {
  Cancel,
  FailedTransaction,
  PendingApproval,
  SuccessInfo,
  Notification
} from '../../icons';

const ModalBody = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  flex-direction: row;
  background-color: ${props =>
    props.type === 'error'
      ? 'rgba(233, 20, 20, 0.32)'
      : props.type === 'notification'
      ? 'rgba(0, 75, 135, 0.13)'
      : props.type === 'warning'
      ? 'rgba(250, 200, 50, 0.32)'
      : 'rgba(0, 179, 38, 0.32)'};
  border-width: 1px;
  border-color: ${props =>
    props.type === 'error'
      ? 'rgba(233, 20, 20, 0.87)'
      : props.type === 'notification'
      ? '#004b87'
      : props.type === 'warning'
      ? '#fac832'
      : '#00b326'};
  border-radius: 2px;
  padding: 16px;
  align-items: center;
  border-style: solid;
  margin-bottom: 10px;
  visibility: ${props => (props.out ? 'hidden' : 'visible')};
  animation: ${props => (props.out ? 'fadeOut' : 'fadeIn')} 1s linear;
  transition: visibility 1s linear;
`;
const Icon = styled.div`
  margin-right: 12px;
`;
const CloseButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  position: absolute;
  right: 0;
`;

class Alert extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = { out: true };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ out: false });
    }, 10);
  }

  componentWillUnmount() {
    this.setState({ out: true });
  }

  render() {
    const icon =
      this.props.type === 'success' ? (
        <SuccessInfo />
      ) : this.props.type === 'notification' ? (
        <Notification />
      ) : this.props.type === 'warning' ? (
        <PendingApproval />
      ) : (
        <FailedTransaction />
      );
    return (
      <ModalBody type={this.props.type} out={this.state.out}>
        <Icon>{icon}</Icon>
        {this.props.text}
        <CloseButton onClick={this.props.onClose}>
          <Cancel color="rgba(5, 34, 56, 0.47)" />
        </CloseButton>
      </ModalBody>
    );
  }
}

export default Alert;
