import React from 'react';
import styled from 'styled-components';
import Button from '../Button';

const ButtonBlock = styled.div`
  position: absolute;
  bottom: 25px;
  left: 140px;
`;
const Root = styled.div`
  margin-top: 5px;
`;
const Text = styled.p`
  margin: 5px 0;
`;
const ConfirmModal = ({ onConfirm, description, buttonText }) => (
  <>
    <Root>
      <Text>{description}</Text>
    </Root>
    <ButtonBlock>
      <Button destructive title={buttonText} onClick={onConfirm} />
    </ButtonBlock>
  </>
);

export default ConfirmModal;
