import React, { useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled, { createGlobalStyle } from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Settlement from './components/Settlement';
import Refund from './components/Refund';
import { TableHeader } from './components/style';
import {
  getCorrectPeriod,
  formatWeeklyDailyPeriod,
  formatMonthlyPeriod
} from '../../utils/formatReportsPeriod';
import { Loader } from '../components';
import { getSearchTransactionsPath } from '../../utils/getSearchTransactionsPath';

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export const statusKindMap = {
  0: '1',
  1: '2'
};

export const TabBlock = styled.div`
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
  justify-content: space-between;
  // align-items: center;
`;

export const TabsGlobal = createGlobalStyle`
  .MuiTab-root {
    min-height: 20px!important;
    min-width: 50px!important;
  }
  .MuiTabs-root{
    min-height: 36px!important;
  }
  .MuiTab-textColorInherit {
    background: #f9f9fb;
    color: rgba(5, 34, 56, 0.56);
    text-transform: uppercase;
    font-size: 12px;
    padding: 8px 16px;
    border-radius: 2px;
    font-weight: 500 !important;
    opacity: 1 !important;
  }
  .MuiTab-textColorInherit.Mui-selected {
    background-color: #004b87 !important;
    color: #fff;
  }
  .MuiTab-root {
    background-color: #f9f9fb !important;
    text-transform: none !important;
    font-size: 14px !important;
  }
  .MuiTypography-root {
    > div {
      padding: 0;
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
`;

const columns = t => [
  {
    Header: t('Transaction date and time'),
    accessor: 'transactionDateTime',
    Cell: row => (
      <div>
        {moment
          .utc(row.value)
          .local()
          .format('DD/MM/YYYY HH:mm:ss')}
      </div>
    )
  },
  {
    Header: t('IEP number'),
    accessor: 'iepNumber'
  },
  {
    Header: t('Agent code'),
    accessor: 'agentCode'
  },
  {
    Header: t('Wallet'),
    accessor: 'walletName'
  },
  {
    Header: t('Amount'),
    accessor: 'amount'
  },
  {
    Header: t('Currency'),
    accessor: 'currency'
  },
  {
    Header: t('Approval code'),
    accessor: 'approvalCode'
  }
];

const reportTabs = [
  { component: Settlement, transactionStatus: 1 },
  { component: Refund, transactionStatus: 2 }
];

const ReportDetailsRouter = props => {
  const [t] = useTranslation('translation');
  const [value, setValue] = useState(
    Number(
      Object.keys(statusKindMap).find(
        key => statusKindMap[key] === props.statusKind
      )
    )
  );

  const { component: ReportDetailsTab, transactionStatus } = reportTabs[value];
  const range = getCorrectPeriod(props.range);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    props.getReportsDetailsTransactions(props.reportId, {
      StatusKind: transactionStatus,
      PageNo: props.page + 1,
      PageSize: props.pageSize,
      Periodicity: props.period
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, props.page, props.pageSize]);

  return props.isLoading ? (
    <Loader />
  ) : (
    <>
      <TabsGlobal />
      <TabBlock style={{ marginBottom: '20px' }}>
        <TableHeader>
          {t('Transaction History')}{' '}
          {range.endDate
            ? formatWeeklyDailyPeriod(range)
            : formatMonthlyPeriod(range.startDate)}
        </TableHeader>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="reportDetailsTab"
        >
          <Tab label={t('Settlement')} {...a11yProps(0)} />
          <Tab label={t('Refund')} {...a11yProps(1)} />
        </Tabs>
      </TabBlock>
      <ReportDetailsTab
        {...props}
        totalAmount={`${props.pagedTotal.totalAmount} ${props.pagedTotal.currency}`}
        columns={columns(t)}
        exportUrl={`/v1/reports/${
          props.reportId
        }/transactions/excel?${getSearchTransactionsPath({
          id: props.reportId,
          StatusKind: transactionStatus,
          Periodicity: props.period
        })}`}
      />
    </>
  );
};

export default ReportDetailsRouter;
