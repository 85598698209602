import React, { useCallback, useEffect, useState } from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';
import TextInput from '../TextInput';

export const AmountCurrency = styled.div`
  display: flex;
  flex-direction: row;
  background: #ccc;
  padding: 0 15px;
  position: absolute;
  top: 31px;
  right: 0;
  justify-content: center;
  align-items: center;
  height: ${({ hasError, focused }) => {
    if (focused) {
      return '41px';
    }

    if (hasError) {
      return '42px';
    }

    return '40px';
  }};
`;
const BlockWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  align-items: center;
  margin-right: 20px;
`;
const NUMBER_REGEX = /[^\d,]/g;
const RenderField = props => {
  const {
    input,
    type,
    id,
    meta: { touched, error },
    setHasError,
    handleFocus,
    placeholder,
    label,
    className,
    helpText,
    min,
    maxlength,
    labelOpacity,
    helpTextFontSize,
    helpTextColor
  } = props;

  useEffect(() => {
    if (typeof setHasError === 'function') {
      setHasError(touched ? !!error : false);
    }
  }, [touched, error, setHasError]);

  return (
    <TextInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...input}
      type={type}
      id={id}
      min={min}
      helpTextFontSize={helpTextFontSize}
      helpTextColor={helpTextColor}
      labelOpacity={labelOpacity}
      error={!touched && error}
      onFocus={e => {
        input.onFocus(e);
        handleFocus(true);
      }}
      onBlur={e => {
        input.onBlur(e);
        handleFocus(false);
      }}
      rawErrors={error}
      label={label}
      placeholder={placeholder}
      helperText={error}
      helpText={helpText}
      className={className}
      maxlength={maxlength}
      touched={touched}
    />
  );
};
const normalizeCurrency = val => {
  if (val === '.') {
    return '0.';
  }

  return Number.isNaN(parseFloat(val)) ? '' : val.replace(/,/g, '');
};
const validate = value => {
  if (!value) {
    return 'Field is required';
  }

  if (value && Number.isNaN(parseFloat(value))) {
    return 'Invalid amount';
  }

  return '';
};
const FormattedAmount = ({
  decimalPoints = 2,
  label,
  name,
  id,
  placeholder,
  className,
  currencyCode,
  required,
  ...restProps
}) => {
  const [hasError, setHasError] = useState(false);
  const [focused, setFocused] = useState(false);
  const formatCallback = useCallback(
    input => {
      if (input) {
        const stringInput = `${input}`;
        // eslint-disable-next-line consistent-return
        const hasPoint = stringInput.includes('.');
        const [number = '', decimals = ''] = stringInput.split('.');
        const formattedNumber = `${parseFloat(
          number.replace(NUMBER_REGEX, '')
        )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        const formattedDecimals = decimals
          .slice(0, decimalPoints)
          .replace(NUMBER_REGEX, '');

        return `${formattedNumber}${
          hasPoint && decimalPoints ? '.' : ''
        }${formattedDecimals}`;
      }

      return '';
    },
    [decimalPoints]
  );

  return (
    <BlockWrapper>
      <Field
        name={name}
        id={id}
        component={RenderField}
        placeholder={placeholder}
        className={className}
        normalize={normalizeCurrency}
        format={formatCallback}
        label={label}
        validate={required ? validate : null}
        setHasError={setHasError}
        handleFocus={setFocused}
        {...restProps}
      />
      {currencyCode && (
        <AmountCurrency hasError={hasError} focused={focused}>
          {currencyCode}
        </AmountCurrency>
      )}
    </BlockWrapper>
  );
};

export default FormattedAmount;
