/* eslint-disable no-nested-ternary */
// @flow
import React from 'react';
import styled from 'styled-components';
import ReactTable from 'react-table';
import { useTranslation } from 'react-i18next';
import TableFooter from '../../components/Table/TableFooter';
import { iepStatus, iepStatuses } from '../../../utils/iepStatus';
import NoRowsFound from '../../components/NoRowsTable';

const StatusLabel = styled.div`
  display: inline-flex;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: 0.2px;
  padding: 2px 4px;
  border-radius: 2px;
  color: ${props =>
    props.status === 5
      ? '#00b326'
      : props.status === 3 || props.status === 4
      ? '#fac832'
      : 'rgba(233, 20, 20, 0.87)'};
  background: ${props =>
    props.status === 5
      ? 'rgba(0,179,38, 0.15)'
      : props.status === 3 || props.status === 4
      ? 'rgba(250,200,50,0.15)'
      : 'rgba(233, 20, 20, 0.15)'};
`;
const Button = styled.button`
  font-size: 14px;
  color: #004b87;
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const columns = (t, editNotifications) => [
  {
    Header: t('IATA Code'),
    accessor: 'iataCode'
  },
  {
    Header: t('Entity type'),
    accessor: 'locationType'
  },
  {
    Header: t('Organization name'),
    accessor: 'name'
  },
  {
    Header: t('Billing country'),
    accessor: 'country'
  },
  {
    Header: t('Billing city'),
    accessor: 'city'
  },
  {
    Header: t('IEP Status'),
    accessor: 'iepStatus',
    Cell: row => (
      <StatusLabel status={row.value}>{iepStatus(row.value)}</StatusLabel>
    )
  },
  {
    Cell: row => (
      <Button
        onClick={() => editNotifications(row.original)}
        disabled={iepStatus(row.original.iepStatus) !== iepStatuses[5]}
      >
        {t('Manage alert settings')}
      </Button>
    )
  }
];

const SettingsTable = props => {
  const [t] = useTranslation('translation');
  return (
    <ReactTable
      PaginationComponent={TableFooter}
      NoDataComponent={NoRowsFound}
      data={props.data}
      columns={columns(t, props.editNotifications)}
      minRows={1}
      sortable={false}
      pageInfo={props.pageInfo}
      defaultPageSize={10}
      className="-highlight"
    />
  );
};

export default SettingsTable;
