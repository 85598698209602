import React from 'react';

const Check = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      className={props.className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h18v18H0z" />
        <path
          fill="#00B326"
          fillRule="nonzero"
          d="M18 4L6 16 .5 10.5l1.41-1.41L6 13.17 16.59 2.59z"
        />
      </g>
    </svg>
  );
};
export default Check;
