import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReportDetailsRouter from './ReportDetailsRouter';
import { getReportsDetailsTransactions } from '../../redux/actions/reportsDetails';
import { setPageSize, setPage } from '../../redux/actions/commonActions';

const mapStateToProps = state => ({
  data: state.reportsDetails.reportsDetailsTransactions,
  isLoading: state.reportsDetails.isLoading,
  pageSize: state.reportsDetails.pageSize,
  page: state.reportsDetails.page,
  pageInfo: state.reportsDetails.pageInfo,
  pagedTotal: state.reportsDetails.pagedTotal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getReportsDetailsTransactions,
      setPageSize,
      setPage
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportDetailsRouter);
