import React from 'react';
import styled, { keyframes } from 'styled-components';

const Root = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 21;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const AbsoluteRoot = styled(Root)`
  position: absolute;
`;
const animationName = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
export const Spinner = styled.div`
  display: inline-block;
  width: 64px;
  height: 64px;
  animation: ${animationName} 1s 0.5s infinite;
  &:after {
    content: ' ';
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #004b87;
    border-color: #004b87 transparent #004b87 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
`;

const Loader = ({ absolute }) => {
  const Wrapper = absolute ? AbsoluteRoot : Root;

  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
};

export default Loader;
