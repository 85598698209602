// @flow
import React from 'react';
import { bindActionCreators, compose } from 'redux';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { InlineLoader as Loader, ButtonUnderlined } from '../components';
import { CancelCard, Check } from '../icons';
import { openModal, closeModal } from '../../redux/actions/modals';
import { generateIEPNumber } from '../../redux/actions/wallets';
import { api } from '../../services/rest';
import { ShowForPermission } from '../ShowForPermission';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const GreyText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  color: rgba(5, 34, 56, 0.56);
  margin-right: 15px;
`;
const RedText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  color: red;
  margin-right: 15px;
`;

const GreyTextSmall = styled(GreyText)`
  font-size: 12px;
  margin-top: 20px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
`;

const IepCode = styled.div`
  height: 32px;
  font-family: Arial;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.51px;
`;
const CancelIEP = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  > svg path:not(:nth-child(1)) {
    fill: rgba(233, 20, 20, 0.87);
  }
`;
const CloseText = styled.div`
  cursor: pointer;
  color: rgba(233, 20, 20, 0.87);
  text-decoration: underlined;
  margin-left: 5px;
`;
const HiddenTextArea = styled.textarea`
  height: 0;
  width: 0;
  opacity: 0;
`;
const SuccessCopy = styled.div`
  color: #00b326;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin: 0 10px;
`;
const Errors = styled.div`
  color: red;
`;
const ErrorBig = styled(Errors)`
  font-size: 25px;
  margin-top: 10px;
`;
const StyledDivText = styled.div`
  margin-right: 15px;
`;

type Props = {
  modalData: mixed
};
type State = {
  iepNumber: string,
  isNumberVisible: boolean,
  isNumberExpired: boolean,
  copySuccess: boolean
};
class IEPNumber extends React.Component<Props, State> {
  textToCopy = React.createRef();

  // eslint-disable-next-line react/state-in-constructor
  state = {
    iepNumber: this.props.modalData.IEPNumber || this.props.currentIEPNumber,
    iepNumberIsLoading: false,
    expDate: 'xx/xx',
    isNumberVisible: false,
    isNumberExpired: false,
    copySuccess: false,
    errors: null,
    iepNumberType: this.props.modalData.iepNumberType
  };

  componentDidMount() {
    if (this.props.modalData.source !== 'wallet') this.getIepNumber();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentIEPNumber !== this.props.currentIEPNumber) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ iepNumber: this.props.currentIEPNumber });
    }
  }

  closeWallet = () => {
    this.props.closeModal();
    this.props.openModal('cancel', this.props.t('Cancel IEP number'), {
      IEPNumber: this.props.modalData.IEPNumber || this.props.iepNumberState,
      ExpDate: this.props.modalData.ExpDate,
      walletId: this.props.modalData.walletId
    });
  };

  getIepNumber = () => {
    const id = this.props.modalData.walletId;
    this.setState({ iepNumberIsLoading: true });
    api
      .get(`wallets/${id}/iep`, { id })
      .then(
        response => {
          if (response.responseCode === '000') {
            if (Object.keys(response.data).length === 0) {
              this.setState({
                errors: this.props.t('Sorry. Data is not correct. Try again')
              });
            } else {
              this.setState({
                iepNumber: response.data.cardNo,
                isNumberVisible: true,
                expDate: response.data.cardExpiry,
                errors: '',
                iepNumberType: response.data.isMultiUseIepn
                  ? 'multi-use'
                  : 'single-use'
              });
            }
          } else {
            this.setState({ errors: response.resDescription });
          }
        },
        err => this.setState({ errors: err.resDescription })
      )
      .finally(() => {
        const { expDate } = this.state;
        const panExpDate = moment(expDate, 'YY/MM');
        const isPanExpired = panExpDate.isValid()
          ? moment()
              .utc()
              .format() > panExpDate.format()
          : false;
        this.setState({
          iepNumberIsLoading: false,
          isNumberExpired: isPanExpired
        });
      });
  };

  hideIep = () =>
    this.setState({
      iepNumber: this.props.modalData.IEPNumber || this.props.currentIEPNumber,
      isNumberVisible: false,
      copySuccess: false,
      expDate: 'xx/xx'
    });

  copyToClipboard = () => {
    this.textToCopy.current.select();
    document.execCommand('copy');
    this.setState({ copySuccess: true });
  };

  render() {
    const {
      iepNumber,
      isNumberVisible,
      copySuccess,
      errors,
      iepNumberType,
      isNumberExpired,
      iepNumberIsLoading,
      expDate
    } = this.state;
    const formattedDate = moment(expDate, 'YY/MM').isValid()
      ? moment(expDate, 'YY/MM').format('MM/YY')
      : expDate;

    return (
      <Root>
        {(!this.props.currentIEPNumber &&
          !this.props.errors &&
          !this.props.modalData.IEPNumber) ||
        this.props.isGeneratingNumber ? (
          <div>Loading...</div>
        ) : (
          <div>
            {this.props.currentIEPNumber && (
              <p>{this.props.t('Your IEP number is successfully generated')}</p>
            )}
            {errors && <Errors>{errors}</Errors>}
            {this.props.errors && <ErrorBig>{this.props.errors}</ErrorBig>}
            {!this.props.errors && (
              <div>
                <Row>
                  <IepCode>{iepNumber}</IepCode>
                  <HiddenTextArea
                    readOnly
                    ref={this.textToCopy}
                    value={iepNumber || ''}
                  />
                  {isNumberVisible ? (
                    <Row>
                      {this.state.copySuccess}
                      {document.queryCommandSupported('copy') &&
                        !copySuccess && (
                          <ButtonUnderlined onClick={this.copyToClipboard}>
                            {this.props.t('Copy to clipboard')}
                          </ButtonUnderlined>
                        )}
                      {copySuccess && (
                        <SuccessCopy>
                          <Check />
                          {this.props.t('Copied')}
                        </SuccessCopy>
                      )}
                      <ButtonUnderlined onClick={this.hideIep}>
                        {this.props.t('Hide number')}
                      </ButtonUnderlined>
                    </Row>
                  ) : (
                    <div style={{ display: 'flex' }}>
                      <ButtonUnderlined
                        disabled={iepNumberIsLoading}
                        onClick={this.getIepNumber}
                      >
                        {this.props.t('Show number')}
                      </ButtonUnderlined>
                      {iepNumberIsLoading && <Loader size={18} />}
                    </div>
                  )}
                </Row>
                <Row>
                  <GreyText>{this.props.t('Expiry date')}</GreyText>
                  <StyledDivText>{formattedDate}</StyledDivText>
                  <StyledDivText>({iepNumberType})</StyledDivText>
                </Row>

                {isNumberExpired ? (
                  <RedText>
                    {this.props.t('Your IEP number has expired on ') +
                      formattedDate}
                  </RedText>
                ) : (
                  <div>
                    <GreyTextSmall>
                      {this.props.t(
                        'When using this IEP number in the GDS work screen, select CA as a payment network'
                      )}
                    </GreyTextSmall>
                    {!iepNumberIsLoading && (
                      <ShowForPermission contextName="IEPN" action="Delete">
                        <CancelIEP onClick={this.closeWallet}>
                          <CancelCard />
                          <CloseText>
                            {this.props.t('Cancel IEP number')}
                          </CloseText>
                        </CancelIEP>
                      </ShowForPermission>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </Root>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      generateIEPNumber
    },
    dispatch
  );
const mapStateToProps = state => ({
  modalData: state.modals.modalData,
  isModalOpen: state.modals.isModalOpen,
  currentIEPNumber: state.wallets.currentIEPNumber,
  errors: state.wallets.errors,
  iepNumberState:
    state.wallets.currentWallet && state.wallets.currentWallet.iepCode,
  isGeneratingNumber: state.wallets.isGeneratingNumber
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translation')
)(IEPNumber);
