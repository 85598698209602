import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme';

const Loader = withStyles({
  root: {
    color: theme.color.primary
  }
})(CircularProgress);

export default Loader;
