import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Alerts from './Alerts';
import { addAlert, removeAlert } from '../../../redux/actions/alerts';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addAlert,
      removeAlert
    },
    dispatch
  );

const mapStateToProps = state => ({
  alerts: state.alerts.alertList
});

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
