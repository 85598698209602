import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WalletsList from './WalletsList';
import { openModal, closeModal } from '../../redux/actions/modals';
import { enableCookie } from '../../redux/actions/appState';
import {
  getWallets,
  addNewWallet,
  exportWalletsList
} from '../../redux/actions/wallets';
import { addAlert, removeAlert } from '../../redux/actions/alerts';
import { getAccessibleAgencies } from '../../redux/actions/agencies';
import { setPage, setPageSize } from '../../redux/actions/commonActions';
import {
  isAgencySuspended,
  iepStatus,
  isAgencyClosed
} from '../../utils/iepStatus';

const mapStateToProps = state => {
  const { agencyStatus } = state.appState.account;

  return {
    isCookieEnabled: state.appState.isCookieEnabled,
    isModalOpen: state.modals.isModalOpen,
    modalTitle: state.modals.modalTitle,
    modalType: state.modals.modalType,
    wallets: state.wallets.wallets,
    isLoading: state.wallets.isLoading,
    pageInfo: state.wallets.pageInfo,
    pageSize: state.wallets.pageSize,
    page: state.wallets.page,
    accessibleAgencies: state.agencies.accessibleAgencies,
    agencyStatus: iepStatus(agencyStatus),
    isAgencyClosed: isAgencyClosed(agencyStatus),
    isAgencySuspended: isAgencySuspended(agencyStatus)
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      enableCookie,
      openModal,
      closeModal,
      getWallets,
      setPageSize,
      setPage,
      addNewWallet,
      addAlert,
      removeAlert,
      getAccessibleAgencies,
      exportWalletsList
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(WalletsList);
