import React from 'react';
import styled from 'styled-components';

const NoMatchContent = styled.div`
  font-size: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const NoMatch = () => <NoMatchContent>404</NoMatchContent>;
export default NoMatch;
