import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import appState, { initState as initStateAppState } from './appState';
import wallets, { initState as walletsState } from './wallets';
import alerts, { initState as alertsState } from './alerts';
import modals, { initState as modalsState } from './modals';
import transactions, { initState as transactionsState } from './transactions';
import usersApproved, {
  initState as usersApprovedState
} from './usersApproved';
import usersPending, { initState as usersPendingState } from './usersPending';
import agencies, { initState as agenciesState } from './agencies';
import loginInternal, {
  initState as loginInternalState
} from './loginInternal';
import bankAccounts, { initState as bankAccountsState } from './bankAccounts';
import reports, { initState as reportsState } from './reports';
import reportsDetails, {
  initState as reportsDetailsState
} from './reportsDetails';
import documents, { initState as documentsState } from './documents';

function createReducer(handlers, initialState = null) {
  return (state = initialState, action) => {
    if (handlers[action.type]) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}

const rootReducer = combineReducers({
  form: formReducer,
  appState: createReducer(appState, initStateAppState),
  wallets: createReducer(wallets, walletsState),
  modals: createReducer(modals, modalsState),
  alerts: createReducer(alerts, alertsState),
  transactions: createReducer(transactions, transactionsState),
  usersPending: createReducer(usersPending, usersPendingState),
  usersApproved: createReducer(usersApproved, usersApprovedState),
  agencies: createReducer(agencies, agenciesState),
  loginInternal: createReducer(loginInternal, loginInternalState),
  bankAccounts: createReducer(bankAccounts, bankAccountsState),
  reports: createReducer(reports, reportsState),
  reportsDetails: createReducer(reportsDetails, reportsDetailsState),
  documents: createReducer(documents, documentsState)
});

export default rootReducer;
