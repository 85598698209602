import * as moment from 'moment';
import isNil from 'lodash.isnil';

const buildQueryParam = (key, param) =>
  !isNil(param) && `${key}=${encodeURIComponent(param)}`;

export const getSearchTransactionsPath = val =>
  Object.keys(val)
    .map(key => {
      const param = val[key];
      if (param instanceof Date) {
        return buildQueryParam(key, moment(param).format('YYYY-MM-DD'));
      }
      if (param instanceof Object) {
        return buildQueryParam(key, param.value);
      }
      return buildQueryParam(key, param);
    })
    .filter(param => param)
    .join('&');
