import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BankAccounts from './BankAccounts';
import { openModal } from '../../../redux/actions/modals';
import { getBankAccounts, getBanks } from '../../../redux/actions/bankAccounts';

const mapStateToProps = state => ({
  bankAccounts: state.bankAccounts.bankAccounts,
  isLoading: state.bankAccounts.isLoading
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      getBankAccounts,
      getBanks
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(BankAccounts);
