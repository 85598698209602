/* eslint-disable no-unused-vars */
import {
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTION_DETAILS,
  SET_IS_ADVANCED_SEARCH,
  SET_TRANSACTION_TABLE_PAGE_SIZE,
  SET_TRANSACTION_TABLE_PAGE
} from '../../constants/actionTypes';

export const initState = {
  transactions: [],
  currentTransaction: null,
  isAdvancedSearch: false,
  isLoading: false,
  pageInfo: null,
  pageSize: 10,
  page: 0
};

export default {
  [FETCH_TRANSACTIONS.REQUEST]: state => {
    return {
      ...state,
      isLoading: true
    };
  },
  [FETCH_TRANSACTIONS.SUCCESS]: (state, { data }) => {
    return {
      ...state,
      transactions: data.data,
      isLoading: false,
      pageInfo: data.pageInfo
    };
  },
  [FETCH_TRANSACTIONS.FAILURE]: (state, { code }) => {
    return {
      ...state,
      isLoading: false
    };
  },
  [FETCH_TRANSACTION_DETAILS.REQUEST]: state => {
    return {
      ...state,
      isLoading: true
    };
  },
  [FETCH_TRANSACTION_DETAILS.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      currentTransaction: payload.data,
      isLoading: false
    };
  },
  [FETCH_TRANSACTION_DETAILS.FAILURE]: (state, { code }) => {
    return {
      ...state,
      isLoading: false,
      currentTransaction: null
    };
  },
  [SET_IS_ADVANCED_SEARCH]: (state, { isAdvancedSearch }) => {
    return {
      ...state,
      isAdvancedSearch
    };
  },
  [SET_TRANSACTION_TABLE_PAGE_SIZE]: (state, { pageSize }) => {
    return {
      ...state,
      pageSize
    };
  },
  [SET_TRANSACTION_TABLE_PAGE]: (state, { page }) => {
    return {
      ...state,
      page
    };
  }
};
