import React, { useState } from 'react';
import styled from 'styled-components';
import AsyncSelect from 'react-select/async';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { Button } from '../components';
import { getInternalAgencies as getInternalAgenciesAction } from '../../redux/actions/agencies';
import { loginInternalRequest as loginInternalRequestAction } from '../../redux/actions/loginInternal';

const DEBOUNCE_TIME = 300;
const POSITIVE_NUMBERS_REGEX = /[^\d]/g;

const Root = styled.div`
  display: flex;
  flex-direction: row;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;
`;
const ButtonBlock = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
`;
const Label = styled.div`
  margin-bottom: 10px;
`;

const InternalUsers = ({ getInternalAgencies, loginInternalRequest }) => {
  const [inputValue, setInputValue] = useState('');
  const [chosenValue, setChosenValue] = useState('');
  const [t] = useTranslation('translation');

  const [debouncedAgenciesLoad] = useDebouncedCallback(
    async (query, callback) => {
      if (query && !POSITIVE_NUMBERS_REGEX.test(query)) {
        const { data } = await getInternalAgencies(query);
        callback(
          data
            ? data.map(({ agentCode }) => ({
                value: agentCode,
                label: agentCode
              }))
            : []
        );
      }
    },
    DEBOUNCE_TIME
  );

  const onChange = (value, { action }) => {
    if (action !== 'input-blur' && action !== 'menu-close') {
      setChosenValue(value);
    }
  };

  const onInputChange = value => {
    const computedValue = value.replace(POSITIVE_NUMBERS_REGEX, '');
    setInputValue(computedValue);
  };

  const resetValue = () => {
    setChosenValue('');
  };

  const onSubmit = () => {
    const { value } = chosenValue;
    loginInternalRequest(value);
  };

  return (
    <Root>
      <Column>
        <Label>{t('Please enter an IATA code')}:</Label>
        <AsyncSelect
          cacheOptions
          inputValue={inputValue}
          onMenuOpen={resetValue}
          value={chosenValue}
          loadOptions={inputValue && debouncedAgenciesLoad}
          onInputChange={onInputChange}
          onChange={onChange}
          noOptionsMessage={() => t('No results found')}
        />
      </Column>
      <ButtonBlock>
        <Button
          disabled={!chosenValue.value}
          title={t('Ok')}
          primary
          onClick={onSubmit}
        />
      </ButtonBlock>
    </Root>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getInternalAgencies: getInternalAgenciesAction,
      loginInternalRequest: loginInternalRequestAction
    },
    dispatch
  );
export default connect(null, mapDispatchToProps)(InternalUsers);
