/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';

const Root = styled.button`
  box-shadow: none;
  padding: 0px 12px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.58px;
  text-align: center;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  min-width: 100px;
  color: ${props => (props.secondary || props.text ? '#004b87' : '#ffffff')};
  background: ${props =>
    props.primary
      ? '#004b87'
      : props.secondary
      ? '#ffffff'
      : props.destructive
      ? '#f15c5c'
      : 'transparent'};
  height: 40px;
  border-radius: 2px;
  outline: none;
  border: ${props => (props.secondary ? '1px solid #004b87' : 'none')};
  cursor: pointer;
  box-shadow: ${props =>
    props.primary ? '0 2px 4px 0 rgba(0, 75, 135, 0.37)' : 'none'};
  &:hover:enabled {
    opacity: ${props => (props.primary || props.destructive ? '0.7' : '1')};
    background: ${props => (props.text || props.secondary ? '#e5f2ff' : '')};
  }
  &:active {
    box-shadow: ${props =>
      props.text || props.secondary
        ? 'inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)'
        : ''};
  }
  &:focus {
    background: ${props => (props.text || props.secondary ? '#e5f2ff' : '')};
    border: ${props =>
      props.destructive
        ? '2px solid #d83636'
        : props.secondary
        ? '2px solid #0b65bf'
        : ''};
  }
  &:disabled {
    cursor: default;
    opacity: 0.56;
    background: ${props => (props.primary ? 'rgba(5, 34, 56)' : '')};
  }
`;
const Icon = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 8px;
`;
const CustomButton = props => (
  <Root
    primary={props.primary}
    secondary={props.secondary}
    destructive={props.destructive}
    secondaryRed={props.secondaryRed}
    text={props.text}
    className={props.className}
    onClick={props.onClick}
    disabled={props.disabled}
    type={props.type}
  >
    {props.icon && <Icon>{props.icon}</Icon>}
    {props.title}
  </Root>
);

export default CustomButton;
