import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TermsConditions from './TermsConditions';
import {
  termsConditionsDownloaded,
  setAccountCreatingStatus,
  getTermsFile,
  setReject,
  initApp,
  showMenu
} from '../../redux/actions/appState';
import downloadDocument from '../../redux/actions/document';

const mapStateToProps = state => ({
  isTermsDownloaded: state.appState.termsConditionsDownloaded,
  accountCreatingStatus: state.appState.accountCreatingStatus,
  isLoading: state.appState.isLoading,
  account: state.appState.account,
  isDocumentLoading: state.documents.isLoading,
  locale: state.appState.locale
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTermsConditionsLoaded: termsConditionsDownloaded,
      // eslint-disable-next-line no-shadow
      setAccountCreatingStatus: accepted => dispatch =>
        dispatch(setAccountCreatingStatus(accepted)),
      // eslint-disable-next-line no-shadow
      initApp: () => dispatch => dispatch(initApp()),
      showMenu,
      getTermsFile,
      setReject,
      downloadDocument
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(TermsConditions);
