/* eslint-disable react/no-unused-prop-types */
// @flow
import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import '../styles/App.css';
import history from './history';
import RouterContainer from './RouterContainer';
import { Header } from '../components/components/Header';
import Footer from '../components/Footer';
import Alerts from '../components/components/Alerts/AlertsContainer';
import '../styles/chart-styles.css';

const MainLayout = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;
const Content = styled.div`
  flex: 1;
  margin-top: 95px;
  background: #f9f9fb;
  padding: 16px 38px;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;
const PageBody = styled.div`
  flex: 1;
`;

const App = ({ isMenuShown, account, selectedLocale }) => {
  const [, i18n] = useTranslation('translation');

  useEffect(() => {
    i18n.changeLanguage(selectedLocale.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale]);

  return (
    <div className="App">
      <Router history={history}>
        <MainLayout>
          <Header isMenuShown={isMenuShown} account={account} />
          <Content>
            <PageBody>
              <Alerts />
              <RouterContainer />
            </PageBody>
          </Content>
          <Footer />
        </MainLayout>
      </Router>
    </div>
  );
};

const mapStateToProps = state => ({
  account: state.appState.account,
  isMenuShown: state.appState.isMenuShown,
  selectedLocale: state.appState.locale
});

export default connect(mapStateToProps)(App);
