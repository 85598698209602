// @flow
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '../components';

const ButtonBlock = styled.div`
  position: absolute;
  bottom: 25px;
  left: 140px;
`;
const Root = styled.div`
  margin-top: 5px;
`;
const Text = styled.p`
  margin: 5px 0;
`;
const DisableUser = props => {
  const [t] = useTranslation('translation');

  const disable = () => {
    props.disableUser(props.userId);
    props.closeModal();
  };

  return (
    <>
      <Root>
        <Text>{t('Are you sure you want to disable this user')}</Text>
        {/* <Text>Closed account will be visible under IEP portal but you cannot use it anymore</Text> */}
      </Root>
      <ButtonBlock>
        <Button destructive title={t('Yes, disable user')} onClick={disable} />
      </ButtonBlock>
    </>
  );
};

export default DisableUser;
