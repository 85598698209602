import React from 'react';

const Plus = props => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h18v18H0z" />
        <path
          fill={props.inverse ? '#fff' : '#004b87'}
          d="M10 2v6h6v2h-6v6H8v-6H2V8h6V2h2z"
        />
      </g>
    </svg>
  );
};
export default Plus;
