import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const NoMatchContent = styled.div`
  font-size: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const NoMatch = () => {
  const [t] = useTranslation('t');
  return (
    <NoMatchContent>
      {t('Sorry, currently SERVICE IS UNAVAILABLE')}
    </NoMatchContent>
  );
};
export default NoMatch;
