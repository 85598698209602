import React from 'react';
import { formatChartLabel } from '../ReportsConstants';

const CustomizedAxisTick = props => {
  const { x, y, payload, period } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={16} textAnchor="middle" fill="#666">
        {formatChartLabel(payload.value, period)}
      </text>
    </g>
  );
};

export default CustomizedAxisTick;
