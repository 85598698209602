/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import { useTranslation, Trans } from 'react-i18next';
import { Card, Button, PageHeader, Loader } from '../../components';
import { CancelCard, Back } from '../../icons';
import Modal from '../../components/Modal';
import TopUp from '../TopUp';
import IEPNumber from '../IEPNumber';
import TransactionTable from './TransactionTable';
import { walletStatus } from '../../../utils/walletStatus';
import WalletCard from './WalletCard';
import CloseWallet from './CloseWallet';
import Transfer from './Transfer';
import CancelIEPNumber from '../CancelIEPNumber';
import Withdraw from './Withdraw';
import {
  ShowForPermission,
  ShowForSomePermissions
} from '../../ShowForPermission';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;
const RowContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1;
`;
const RowCentered = styled(Row)`
  align-items: center;
  margin-right: 50px;
`;
const RowSpaceBetween = styled(Row)`
  justify-content: space-between;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const ColumnWithBorder = styled(Column)`
  border-left: 1px solid rgba(5, 34, 56, 0.07);
  border-right: 1px solid rgba(5, 34, 56, 0.07);
  padding-left: 20px;
  padding-right: 53px;
  margin-right: 24px;
`;
const CardNoPadding = styled(Card)`
  padding: 16px 0 0 0;
  flex: 3;
`;
const TextGrey = styled.div`
  color: rgba(5, 34, 56, 0.56);
  margin-left: 6px;
`;
const TextGreySmall = styled(TextGrey)`
  font-size: 12px;
  margin-top: 7px;
  line-height: 1.33;
`;
const UppercaseHeader = styled.h5`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.3px;
  height: 24px;
  text-transform: uppercase;
  margin: 0;
`;
const UppercaseHeaderPadding = styled(UppercaseHeader)`
  margin-left: 16px;
`;
const BackButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
`;
const WalletValue = styled.div`
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.69px;
  color: ${props => (props.isNull ? 'rgba(233,20,20,0.87)' : '')};
`;
const CloseWalletBlock = styled.div`
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.3px;
  color: rgba(5, 34, 56, 0.56);
  align-self: flex-start;
  cursor: pointer;
  position: relative;
`;
const CloseTooltip = styled.div`
  border: 1px solid #ccc;
  padding: 4px;
  border-radius: 2px;
  color: rgba(5, 34, 56, 0.56);
  position: absolute;
  font-size: 12px;
  line-height: 1.33;
  background: #fff;
  right: 0;
  display: none;
`;
const CloseWalletContent = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  outline: none;

  &:hover + ${CloseTooltip} {
    display: block;
  }

  > div {
    color: ${props =>
      props.isDisabled ? 'rgba(5, 34, 56, 0.56)' : 'rgba(233, 20, 20, 0.87)'};
  }

  > svg path:not(:nth-child(1)) {
    fill: ${props =>
      props.isDisabled ? 'rgba(5, 34, 56, 0.56)' : 'rgba(233, 20, 20, 0.87)'};
  }
`;
const CloseText = styled.div`
  margin-left: 4px;
  text-decoration: underline;
  cursor: pointer;
`;
const ButtonTopup = styled(Button)`
  margin: 0 24px 9px 0;
`;
const StatusLabel = styled.div`
  text-transform: capitalize;
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: 0.69px;
  color: ${props =>
    props.status === 1 ? '#00b326' : 'rgba(233, 20, 20, 0.87)'};
`;

const showMessageOnTopup = (addAlert, code, translation) => {
  if (code) {
    if (code === '000') {
      addAlert({
        alertType: 'success',
        text: translation('Payment is successfully done')
      });
    } else if (code === '001') {
      addAlert({
        alertType: 'warning',
        text: translation('Payment is Pending')
      });
    } else if (code === '002') {
      addAlert({
        alertType: 'error',
        text: translation('Payment is Rejected')
      });
    } else if (code === '003') {
      addAlert({
        alertType: 'error',
        text: translation('Payment was Canceled by user')
      });
    } else if (code === '999') {
      addAlert({ alertType: 'error', text: translation('Payment is Failed') });
    }
  }
};

const Wallet = props => {
  const [t] = useTranslation('translation');

  useEffect(() => {
    const id = props.match.params.walletId;
    props.getWallet(id);
    // props.getWalletTransactions(id);
    const queryParams = queryString.parse(props.location.search);
    if (queryParams)
      showMessageOnTopup(props.addAlert, queryParams.paymentStatus, t);
    const { location } = props.history;
    props.history.replace({
      location
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // TODO: move

  const initIep = (iepNumberType, walletId, code) => {
    if (!code)
      props.generateIEPNumber(walletId, {
        id: walletId,
        isMultiUseIepn: iepNumberType === 'multi-use'
      });
    props.openModal('iep', t('IEP number'), {
      IEPNumber: code,
      walletId,
      iepNumberType,
      source: 'wallet'
    });
  };

  const {
    currentWallet,
    openModal,
    closeModal,
    modalTitle,
    modalType,
    isModalOpen,
    isAgencySuspended,
    isWithdrawAllowed
  } = props;
  const walletBalanceNotNull =
    // eslint-disable-next-line radix
    parseFloat(currentWallet ? currentWallet.availableBalance : '0') > 0;
  const multiIEPNumberValue = currentWallet && currentWallet.iepCode;
  const singleIEPNumberValue = currentWallet && currentWallet.singleUseIEPCode;
  const bankAccount = currentWallet && currentWallet.bankAccount;
  const balance = currentWallet && currentWallet.availableBalance;
  const iepNumberValue = singleIEPNumberValue || multiIEPNumberValue;
  const iepNumberType = !iepNumberValue
    ? null
    : singleIEPNumberValue
    ? 'single-use'
    : 'multi-use';

  console.warn(currentWallet && currentWallet.walletStatus);
  return (
    <Root>
      {currentWallet === null ? (
        <Loader />
      ) : (
        <>
          <PageHeader>
            <BackButton onClick={() => props.history.push('/portal/wallets')}>
              <Back />
            </BackButton>
            {currentWallet ? currentWallet.walletName : ''}
          </PageHeader>
          <Card>
            <RowSpaceBetween>
              <Row>
                <Row>
                  <Column>
                    <UppercaseHeader>{t('Balance')}</UppercaseHeader>
                    <RowCentered>
                      <WalletValue isNull={!walletBalanceNotNull}>
                        {balance}
                      </WalletValue>
                      <TextGrey>
                        {currentWallet ? currentWallet.currencyCode : ''}
                      </TextGrey>
                    </RowCentered>
                  </Column>
                  <ShowForPermission contextName="Topup" action="Create">
                    {currentWallet && currentWallet.walletStatus !== 2 && (
                      <ButtonTopup
                        primary
                        title={t('Top up balance')}
                        disabled={isAgencySuspended}
                        onClick={() =>
                          openModal('topUp', t('Top up IATA EP Wallet'), {
                            walletName: currentWallet.walletName,
                            availableBalance: currentWallet.availableBalance,
                            currencyCode: currentWallet.currencyCode,
                            walletId: currentWallet.walletId
                          })
                        }
                      />
                    )}
                  </ShowForPermission>
                </Row>
                <ColumnWithBorder>
                  <UppercaseHeader>{t('IATA Code')}</UppercaseHeader>
                  <WalletValue>
                    {currentWallet ? currentWallet.agentCode : ''}
                  </WalletValue>
                </ColumnWithBorder>
                <Column>
                  <UppercaseHeader>{t('Status')}</UppercaseHeader>
                  <WalletValue>
                    <StatusLabel
                      status={currentWallet ? currentWallet.walletStatus : '1'}
                    >
                      {currentWallet
                        ? walletStatus(currentWallet.walletStatus)
                        : '-'}
                    </StatusLabel>
                  </WalletValue>
                </Column>
              </Row>
              <ShowForPermission contextName="Wallet" action="Delete">
                {currentWallet && currentWallet.walletStatus === 1 && (
                  <CloseWalletBlock>
                    <CloseWalletContent
                      isDisabled={walletBalanceNotNull}
                      onClick={
                        walletBalanceNotNull
                          ? null
                          : () =>
                              openModal(
                                'close',
                                `${t('Close')} ${currentWallet.walletName} ${t(
                                  'Wallet'
                                )}`,
                                {
                                  walletId: currentWallet.walletId
                                }
                              )
                      }
                    >
                      <CancelCard />{' '}
                      <CloseText>{t('Close wallet account')}</CloseText>
                    </CloseWalletContent>
                    {walletBalanceNotNull ? (
                      <CloseTooltip>
                        {t('Wallet balance is required')}
                      </CloseTooltip>
                    ) : (
                      <TextGreySmall>
                        <Trans i18nKey="Closed account will be visible under IEP portal but you cannot use it anymore Line Break" />
                      </TextGreySmall>
                    )}
                  </CloseWalletBlock>
                )}
              </ShowForPermission>
            </RowSpaceBetween>
          </Card>
          <RowContent>
            <CardNoPadding>
              <UppercaseHeaderPadding>
                {t('Transactions')}
              </UppercaseHeaderPadding>
              <TransactionTable
                transactionPageInfo={props.transactionPageInfo}
                currentWalletTransactions={props.currentWalletTransactions}
                id={props.match.params.walletId}
                getWalletTransactions={props.getWalletTransactions}
                history={props.history}
                isTransactionsLoading={props.isTransactionsLoading}
              />
            </CardNoPadding>

            <ShowForSomePermissions
              permissions={[
                { contextName: 'IEPN', action: 'Read' },
                { contextName: 'IEPN', action: 'Create' },
                { contextName: 'Withdrawal', action: 'Create' },
                { contextName: 'BankAccount', action: 'Create' },
                { contextName: 'Transfer', action: 'Create' }
              ]}
            >
              <Column>
                {currentWallet &&
                  currentWallet.walletStatus === 1 &&
                  (iepNumberValue ? (
                    <ShowForPermission contextName="IEPN" action="Read">
                      <WalletCard
                        title={t('IEP number')}
                        buttonTitle={t('View IEP number')}
                        buttonClick={() =>
                          initIep(
                            iepNumberType,
                            currentWallet.walletId,
                            iepNumberValue
                          )
                        }
                      />
                    </ShowForPermission>
                  ) : (
                    <ShowForPermission contextName="IEPN" action="Create">
                      <WalletCard
                        title={t('IEP number')}
                        text={t(
                          'You don’t have IEP number attached to your account'
                        )}
                        buttonTitle={t('Generate IEP number')}
                        disabled={
                          !balance ||
                          parseFloat(balance) === 0 ||
                          isAgencySuspended
                        }
                        buttonClick={type =>
                          initIep(type, currentWallet.walletId)
                        }
                        isMultiUseIepnAllowed={
                          currentWallet.isMultiUseIepnAllowed
                        }
                        questionIcon
                      />
                    </ShowForPermission>
                  ))}

                {currentWallet &&
                  (currentWallet.walletStatus === 1 ||
                    currentWallet.walletStatus === 2) && (
                    <>
                      <ShowForPermission
                        contextName="Withdrawal"
                        action="Create"
                      >
                        {bankAccount ? (
                          <WalletCard
                            title={t('Withdrawal')}
                            text={t('Withdrawal amount will be transferred')}
                            buttonTitle={t('Request withdraw')}
                            disabled={isAgencySuspended && !isWithdrawAllowed}
                            buttonClick={() =>
                              openModal(
                                'withdraw',
                                t('Request withdraw from IATA EP Wallet'),
                                {
                                  availableBalance:
                                    currentWallet.availableBalance,
                                  currencyCode: currentWallet.currencyCode,
                                  accountNumber: currentWallet.accountNumber,
                                  walletId: currentWallet.walletId,
                                  withdrawalFee: currentWallet.withdrawalFee,
                                  numericWithdrawalFee:
                                    currentWallet.numericWithdrawalFee,
                                  bankAccount
                                }
                              )
                            }
                          />
                        ) : (
                          <WalletCard
                            title={t('Withdrawal')}
                            text={t(
                              'You do not have registered bank account for withdrawal'
                            )}
                            buttonTitle={t('Register Bank Account')}
                            buttonClick={() =>
                              props.history.push(`/portal/settings`)
                            }
                          />
                        )}
                      </ShowForPermission>

                      <ShowForPermission contextName="Transfer" action="Create">
                        <WalletCard
                          title={t('TRANSFER')}
                          text={t('Transfer balance between other IEP wallets')}
                          buttonTitle={t('Make a transfer')}
                          buttonClick={() =>
                            openModal(
                              'transfer',
                              t('Transfer from IATA EP Wallet'),
                              {
                                availableBalance:
                                  currentWallet.availableBalance,
                                currencyCode: currentWallet.currencyCode,
                                walletId: currentWallet.walletId,
                                withdrawalFee: currentWallet.withdrawalFee,
                                numericWithdrawalFee:
                                  currentWallet.numericWithdrawalFee
                              }
                            )
                          }
                        />
                      </ShowForPermission>
                    </>
                  )}
              </Column>
            </ShowForSomePermissions>
          </RowContent>
        </>
      )}
      <Modal title={modalTitle} open={isModalOpen}>
        {modalType === 'topUp' ? (
          <TopUp closeModal={closeModal} />
        ) : modalType === 'iep' ? (
          <IEPNumber closeModal={closeModal} />
        ) : modalType === 'withdraw' ? (
          <Withdraw closeModal={closeModal} />
        ) : modalType === 'transfer' ? (
          <Transfer closeModal={closeModal} />
        ) : modalType === 'close' ? (
          <CloseWallet />
        ) : (
          <CancelIEPNumber closeModal={props.closeModal} />
        )}
      </Modal>
      ;
    </Root>
  );
};
export default Wallet;
