import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from './Modal';
import { closeModal } from '../../../redux/actions/modals';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeModal
    },
    dispatch
  );
export default connect(null, mapDispatchToProps)(Modal);
