import React, { useEffect } from 'react';
import queryString from 'query-string';
import isEmpty from 'lodash.isempty';
import { useTranslation } from 'react-i18next';
import ReportDetailsRouterContainer from './ReportDetailsRouterContainer';
import { Loader, Card } from '../components';
import { PageHeader, Row, BackButton } from './components/style';
import { Back } from '../icons';
import {
  formatWeeklyDailyPeriod,
  formatMonthlyPeriod
} from '../../utils/formatReportsPeriod';

const ReportDetails = ({
  reportsDetails,
  getReportsDetails,
  match,
  history
}) => {
  const [t] = useTranslation('translation');
  const { reportId } = match.params;
  const { period, statusKind } = queryString.parse(history.location.search);

  useEffect(() => {
    getReportsDetails(reportId, {
      Periodicity: period,
      StatusKind: statusKind
    });
  }, [getReportsDetails, reportId, period, statusKind]);

  const { endDate, startDate } = reportsDetails;

  return isEmpty(reportsDetails) ? (
    <Loader />
  ) : (
    <div>
      <Row>
        <PageHeader>
          <BackButton onClick={() => history.goBack()}>
            <Back />
          </BackButton>
          {t('Report details')}{' '}
          {endDate
            ? formatWeeklyDailyPeriod({ startDate, endDate })
            : formatMonthlyPeriod(startDate)}
        </PageHeader>
      </Row>
      <Card>
        <ReportDetailsRouterContainer
          reportId={reportId}
          period={reportsDetails.periodicity}
          range={{
            startDate: reportsDetails.startDate,
            endDate: reportsDetails.endDate
          }}
          statusKind={statusKind}
        />
      </Card>
    </div>
  );
};

export default ReportDetails;
