/* eslint-disable no-unused-vars */
import {
  FETCH_USERS_APPROVED,
  FETCH_USER_DETAIL_APPROVED,
  GET_ACCESSIBLE_ROLES,
  GET_USER_STATUSES,
  GET_USER_DATA_FOR_EDITING
} from '../../constants/actionTypes';

export const initState = {
  usersList: [],
  currentUser: null,
  accessibleRoles: [],
  isLoading: false,
  pageInfo: null,
  userData: null,
  userStatuses: []
};

export default {
  [FETCH_USERS_APPROVED.REQUEST]: state => {
    return {
      ...state,
      isLoading: true
    };
  },
  [FETCH_USERS_APPROVED.SUCCESS]: (state, data) => {
    const users = data.data.data;
    const { pageInfo } = data.data;
    return {
      ...state,
      usersList: users,
      pageInfo,
      currentUser: null,
      isLoading: false
    };
  },
  [FETCH_USERS_APPROVED.FAILURE]: (state, { code }) => {
    return { ...state, isLoading: false };
  },
  [FETCH_USER_DETAIL_APPROVED.REQUEST]: state => {
    return {
      ...state,
      isLoading: false
    };
  },
  [FETCH_USER_DETAIL_APPROVED.SUCCESS]: (state, data) => {
    const user = data.data.data;
    return {
      ...state,
      currentUser: user,
      isLoading: false
    };
  },
  [FETCH_USER_DETAIL_APPROVED.FAILURE]: (state, { code }) => {
    return { ...state, isLoading: false };
  },
  [GET_ACCESSIBLE_ROLES.REQUEST]: state => {
    return {
      ...state,
      isLoading: false
    };
  },
  [GET_ACCESSIBLE_ROLES.SUCCESS]: (state, payload) => {
    const roles = payload.payload.data;
    return {
      ...state,
      accessibleRoles: roles,
      isLoading: false
    };
  },
  [GET_ACCESSIBLE_ROLES.FAILURE]: (state, { code }) => {
    return { ...state, isLoading: false };
  },
  [GET_USER_DATA_FOR_EDITING.REQUEST]: state => {
    return {
      ...state,
      userData: {
        isLoading: true
      }
    };
  },
  [GET_USER_DATA_FOR_EDITING.SUCCESS]: (state, payload) => {
    const { data } = payload.payload;
    return {
      ...state,
      userData: {
        ...data,
        isLoading: false
      }
    };
  },
  [GET_USER_DATA_FOR_EDITING.FAILURE]: (state, { code }) => {
    return {
      ...state,
      userData: {
        isLoading: false
      }
    };
  },
  [GET_USER_STATUSES.REQUEST]: state => {
    return {
      ...state
    };
  },
  [GET_USER_STATUSES.SUCCESS]: (state, payload) => {
    const { data } = payload.payload;
    return {
      ...state,
      userStatuses: data
    };
  },
  [GET_USER_STATUSES.FAILURE]: (state, { code }) => {
    return { ...state };
  }
};
