// @flow
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '../components';

const ButtonBlock = styled.div`
  position: absolute;
  bottom: 25px;
  left: 140px;
`;
const Root = styled.div`
  margin-top: 5px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 7px 0;
`;
const DataText = styled.div`
  display: flex;
  flex: 2;
`;
const DataTitle = styled(DataText)`
  display: flex;
  flex: 1;
  color: rgba(5, 34, 56, 0.56);
`;
const Text = styled.p`
  margin: 6px 0 18px 0;
`;
const RejectUser = props => {
  const [t] = useTranslation('translation');

  const reject = () => {
    props.rejectUser(props.modalData.id);
    props.closeModal();
    props.back();
  };

  return (
    <>
      <Root>
        <Text>{t('Are you sure you want to reject this account')}</Text>
      </Root>
      <Row>
        <DataTitle>{t('Email')}</DataTitle>
        <DataText>{props.modalData.email}</DataText>
      </Row>
      <Row>
        <DataTitle>{t('IATA Code')}</DataTitle>
        <DataText>{props.modalData.code}</DataText>
      </Row>
      {/* <Row>
				<DataTitle>Registered wallets</DataTitle>
				<DataText>{props.modalData.wallets}</DataText>
			</Row> */}
      <ButtonBlock>
        <Button destructive title={t('Yes, reject')} onClick={reject} />
      </ButtonBlock>
    </>
  );
};

const mapStateToProps = state => ({
  modalData: state.modals.modalData
});

export default connect(mapStateToProps)(RejectUser);
